import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Fab,Button,Dialog,DialogActions,DialogContent,
  DialogContentText, DialogTitle
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import AddIcon from '@material-ui/icons/Add';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const ListUserTraining = () => {

  const [returnVideoId, setReturnVideoId] = useState(null);
  const [isDeleiting, setIsDeleiting] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const commentatorState = useSelector((state) => ({
    status: state.training.status,
    error: state.training.error,
    list: state.training.list
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
  }); 

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleReturnVideoClick = (id) => {
    setReturnVideoId(id);
  }

  const confirmReturnVideo = () => {
    setIsDeleiting(true);
    dispatch({ type: 'training/disabledUser', payload: {
        id: returnVideoId,
        enqueueSnackbar,
        handleClose: () => {
            setReturnVideoId(null);
            setIsDeleiting(false);
        },
        setPaginationParams
    }});
}

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'training/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


  return (
    <main>

        <Dialog open={Boolean(returnVideoId)} onClose={() => setReturnVideoId(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Inactivar
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Desea Inactivar el siguiente usuario?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setReturnVideoId(null)}>Cancelar</Button>
                <Button onClick={() => confirmReturnVideo()} variant="outlined" color="primary" autoFocus disabled={isDeleiting}>
                    {isDeleiting ? 'Actualizando...' : 'Actualizar'}
                </Button>
            </DialogActions>
        </Dialog>


      {commentatorState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
      <div className='relative max-w-screen-xl p-8 mx-auto'>
        <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
          Usuarios
        </Typography>

        <Fab
          className='absolute right-0 outline-none'
          color='primary'
          aria-label='add'
          onClick={() => navigate('/training/users/new')}
        >
          <AddIcon />
        </Fab>
      </div>
    </Paper>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Idioma</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commentatorState.list?.data?.map((row, i) => <Row key={i} row={row} handleReturnVideoClick={handleReturnVideoClick} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={commentatorState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={commentatorState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row,handleReturnVideoClick}) => {
  return (
    <React.Fragment>
      <StyledTableRow>
      <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.name} </StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.languages}</StyledTableCell>
        <StyledTableCell>
            <Button onClick={() => handleReturnVideoClick(row.id)} variant='contained' size="small" style={{ background:"red",color:'white' }}   >
                Inactivar Usuario
            </Button>
        </StyledTableCell>

        
      </StyledTableRow>
    </React.Fragment>
  );
}

export default ListUserTraining;
