import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';

const video = {
  state: {
    list: null,
    listAnimation: null,
    error: null,
    weeks: null,
    views: null,
    matches: null,
    status: '',
    excelUrl: null,
    qalist:null,
    qalistnotnarration:null,
    hiddenvideos:null,
  },
  reducers: {
    // Pending narrations
    listAnimationSuccess: (state, res) => ({
      ...state,
      status: 'received',
      listAnimation: res,
    }),
    listAnimationFailed: (state, error) => ({ ...state, status: 'failed', error }),
    pendingNarrations: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel pending narrations
    excel: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Count videos by user by week
    byWeeks: (state) => ({
      ...state,
      list: null,
      status: 'waiting',
      error: null,
    }),
    weeksSuccess: (state, res) => ({
      ...state,
      status: 'received',
      weeks: res,
    }),
    weeksFailed: (state, error) => ({ ...state, status: 'failed', error }),
    excelWeeks: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    // Views by videos
    views: (state) => ({ ...state, status: 'waiting', error: null }),
    viewsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      views: res,
    }),
    viewsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel total views
    excelViews: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    // Videos to assign
    videosToAssign: (state) => ({ ...state, status: 'waiting', error: null }),
    // Assign video
    assign: (state) => ({ ...state, status: 'waiting', error: null }),
    assignSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    assignAnimartionSuccess: (state, res) => ({
      ...state,
      status: 'finish',
      message: res.message,
    }),
    assignFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Videos by match
    matches: (state) => ({ ...state, status: 'waiting', error: null }),
    matchesSuccess: (state, res) => ({
      ...state,
      status: 'received',
      matches: res,
    }),
    matchesFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel total views
    excelMatches: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    byWeeksAprovedQA: (state) => ({
      ...state,
      list: null,
      status: 'waiting',
      error: null,
    }),

    excelWeeksAprovedQA: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    viewsFilmed: (state) => ({ ...state, status: 'waiting', error: null }),
    
    excelViewsFilmed: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),

    qalist: (state) => ({ ...state, status: 'waiting', error: null }),
    qalistSuccess: (state, res) => ({
      ...state,
      status: 'received',
      qalist: res,
    }),
    qalistFailed: (state, error) => ({ ...state, status: 'failed', error }),

    returnVideo: (state) => ({
      ...state,
      status: 'waiting',
      error: null,
    }),
    returnVideoSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,

    }),
    returnVideoFailed: (state, error) => ({ ...state, status: 'failed', error }),

    qalistnotnarration: (state) => ({ ...state, status: 'waiting', error: null }),
    qalistnotnarrationSuccess: (state, res) => ({
      ...state,
      status: 'received',
      qalistnotnarration: res,
    }),
    qalistnotnarrationFailed: (state, error) => ({ ...state, status: 'failed', error }),

    narrarVideo: (state) => ({
      ...state,
      status: 'waiting',
      error: null,
    }),
    narrarVideoSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,

    }),
    narrarVideoFailed: (state, error) => ({ ...state, status: 'failed', error }),

    hiddenvideos: (state) => ({ ...state, status: 'waiting', error: null }),
    hiddenvideosSuccess: (state, res) => ({
      ...state,
      status: 'received',
      hiddenvideos: res,
    }),
    hiddenvideosFailed: (state, error) => ({ ...state, status: 'failed', error }),

    InvalidVideos: (state) => ({ ...state, status: 'waiting', error: null }),
    matchesViewList: (state) => ({ ...state, status: 'waiting', error: null }),
    excelViewMatch: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),

    videosToAssignAnimation: (state) => ({ ...state, status: 'waiting', error: null }),
    videosToAssignWatermark: (state) => ({ ...state, status: 'waiting', error: null }),

    animationList: (state) => ({ ...state, status: 'waiting', error: null }),
    watermarkList: (state) => ({ ...state, status: 'waiting', error: null }),
    dashboardPending: (state) => ({ ...state, status: 'waiting', error: null }),


  },
  effects: (dispatch) => ({
    pendingNarrations(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/pending-narrations', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excel(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/pending-narrations', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'VideosPendientesNarracion.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    byWeeks(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/video-weeks', values).then((res) => {
        dispatch.video.weeksSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.weeksFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    byWeeksAprovedQA(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/video-weeks/aproved-narration', values).then((res) => {
        dispatch.video.weeksSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.weeksFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    
    excelWeeks(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/video-weeks', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'VideosPorSemana.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    excelWeeksAprovedQA(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/video-weeks/aproved-narration', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'videos_narrados_aprobados_qa.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    views(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/views-videos', values).then((res) => {
        dispatch.video.viewsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.viewsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    viewsFilmed(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/videos-filmed', values).then((res) => {
        dispatch.video.viewsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.viewsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelViews(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/views-videos', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'VistasPorVideo.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    excelViewsFilmed(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/videos-filmed', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'videos_filmados.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    videosToAssignAnimation(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/lists-videos-animations', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    videosToAssignWatermark(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/lists-videos-watermarks', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },


    
    
    videosToAssign(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/lists-videos', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    assign(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/reports/assign-video', values).then((res) => {
        dispatch.video.assignSuccess(res.data);
        enqueueSnackbar('Video asignado', { variant: 'success' });
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.assignFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    assignAnimation(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/reports/assign-video-animation', values).then((res) => {
        dispatch.video.assignAnimartionSuccess(res.data);
        enqueueSnackbar('Video asignado', { variant: 'success' });
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.assignFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    assignWatermark(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/reports/assign-video-watermark', values).then((res) => {
        dispatch.video.assignAnimartionSuccess(res.data);
        enqueueSnackbar('Video asignado', { variant: 'success' });
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.assignFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    matches(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/matches-views', values).then((res) => {
        dispatch.video.matchesSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.matchesFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelMatches(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/matches-views', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'VideosPorPartido.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    qalist(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/verifications', values).then((res) => {
        dispatch.video.qalistSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.qalistFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    qalistnotnarration(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/verifications/not-narrations', values).then((res) => {
        dispatch.video.qalistnotnarrationSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.qalistnotnarrationFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    hiddenvideos(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/verifications/hidden-matches', values).then((res) => {
        dispatch.video.hiddenvideosSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.hiddenvideosFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    returnVideo(payload) {
      const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      ax.post(`/v2/verifications/${id}`).then((res) => {
          dispatch.video.returnVideoSuccess(res.data);
          enqueueSnackbar('video retornado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.video.returnVideoFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
    narrarVideo(payload) {
      const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      ax.post(`/v2/verifications/send/not-narrations/${id}`).then((res) => {
          dispatch.video.returnVideoSuccess(res.data);
          enqueueSnackbar('video retornado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.video.returnVideoFailed(error.response);
          handleClose();
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    releaseVideos(payload) {
      const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      ax.post(`/v2/verifications/release-videos/${id}`).then((res) => {
          dispatch.video.returnVideoSuccess(res.data);
          enqueueSnackbar('videos liberados correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.video.returnVideoFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    InvalidVideos(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/verifications/report/video-delete-commentar', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },


    returnNarration(payload) {
      const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      ax.post(`/v2/verifications/return-video-queue/${id}`).then((res) => {
          dispatch.video.returnVideoSuccess(res.data);
          enqueueSnackbar('videos retornado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.video.returnVideoFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    matchesViewList(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/matches-views-by-ip', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    matchesBannerList(payload) {
      const { values, enqueueSnackbar } = payload;
      ax.get('/reports/matches-banner', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    excelViewMatch(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/matches-views-by-ip', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.video.excelSuccess(url);
        downloadExcel(url, 'views.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    animationList(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/catalogs/animations', values).then((res) => {
        dispatch.video.listAnimationSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listAnimationFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    watermarkList(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/catalogs/watermarks', values).then((res) => {
        dispatch.video.listAnimationSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listAnimationFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    dashboardPending(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/dashboard-pending', values).then((res) => {
        dispatch.video.viewsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.viewsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    newMatchesList(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/report-new-matches', values).then((res) => {
        dispatch.video.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.video.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    deleteMatch(payload) {
      const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      ax.delete(`/reports/delete/match/${id}`).then((res) => {
          dispatch.video.returnVideoSuccess(res.data);
          enqueueSnackbar('videos retornado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.video.returnVideoFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    createBanner(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/reports/assign-match-banner', values).then((res) => {
          dispatch.video.assignAnimartionSuccess(res);
          enqueueSnackbar('Se creo correctamente', { variant: 'success' });
          
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.video.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
  },

  }),
};

export default video;
