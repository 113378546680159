import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import {
  IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import PropTypes from 'prop-types';
import RichEditor from '../../RichEditor';
import FileUpload from '../../FileUpload/FileUpload';

const SponsorshipsContents = ({
  control, register, setFieldValue, nestIndex, lang, watch,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `sections[${nestIndex}].contents_${lang}`,
  });
  const sections = watch('sections');
  const contents = sections && sections[nestIndex] && sections[nestIndex][`contents_${lang}`];
  const add = () => append({
    type: '',
    text: '',
    media: null,
    lang,
  });

  return (
    <>
      {fields.map((item, k) => (
        <div key={item.id} className='mb-8'>
          <input
            type='hidden'
            name={`sections[${nestIndex}].contents_${lang}[${k}].lang`}
            ref={register()}
            defaultValue={item.lang}
          />
          <input
            type='hidden'
            name={`sections[${nestIndex}].contents_${lang}[${k}].text`}
            ref={register()}
            defaultValue={item.text}
          />

          <div className='flex items-center py-1 mb-4 border-b border-white'>
            <p className='m-0 font-bold text-white'>{`Contenido ${k + 1}`}</p>
            <IconButton aria-label='delete' className='ml-auto' onClick={() => remove(k)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          </div>

          <FormControl className='mb-4'>
            <FormLabel component='legend'>Tipo de contenido</FormLabel>
            <Controller
              className='flex-row'
              control={control}
              defaultValue={item.type}
              name={`sections[${nestIndex}].contents_${lang}[${k}].type`}
              as={<RadioGroup />}
            >
              <FormControlLabel value='text' control={<Radio />} label='Texto' />
              <FormControlLabel value='media' control={<Radio />} label='Multimedia' />
            </Controller>
          </FormControl>

          {contents && contents[k] && contents[k].type === 'text' && (
            <RichEditor
              data={item.text}
              onChange={(val) => setFieldValue(`sections[${nestIndex}].contents_${lang}[${k}].text`, val)}
            />
          )}

          {contents && contents[k] && contents[k].type === 'media' && (
            <FileUpload
              label='Selecciona un archivo'
              className='h-32'
              id={`sections[${nestIndex}].contents_${lang}[${k}].media`}
              icon={<ImageIcon className='w-12 h-12 mb-2' />}
              fileChange={setFieldValue}
              accept='image/*, .mp4'
              onInit={() => register({ name: `sections[${nestIndex}].contents_${lang}[${k}].media` })}
            />
          )}
        </div>
      ))}

      <Button
        className='mt-6'
        color='secondary'
        variant='contained'
        type='button'
        onClick={add}
        size='small'
      >
        Agregar contenido
      </Button>
    </>
  );
};

SponsorshipsContents.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  nestIndex: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default SponsorshipsContents;
