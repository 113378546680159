import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import {
  Typography, Divider, IconButton, Paper, TextField, Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SponsorshipsContents from './Contents';

const SponsorshipsSections = ({
  control, register, watch, setFieldValue,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sections',
  });

  const add = () => append({
    title_es: '',
    title_en: '',
  });

  return (
    <fieldset>
      <Typography className='mt-0 mb-2 text-white' variant='h6' component='h2'>
        Secciones
      </Typography>

      <Divider className='mb-8' />

      {fields.map((item, index) => (
        <div className='mb-4' key={item.id}>
          <div className='flex items-center py-2 pl-8 pr-4 rounded-t bg-primary'>
            <p className='font-bold text-white'>{`Sección ${index + 1}`}</p>

            <IconButton aria-label='delete' className='ml-auto' onClick={() => remove(index)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          </div>

          <Paper className='p-8'>
            <div className='flex'>
              <div className='w-full pr-8 border-r border-gray-600'>
                <Typography className='mt-0 mb-8 text-white' variant='body1' component='h3'>
                  Español
                </Typography>

                <TextField
                  size='small'
                  className='mb-8'
                  label='Título*'
                  name={`sections[${index}].title_es`}
                  variant='outlined'
                  fullWidth
                  defaultValue={item.title_es}
                  inputProps={{ ref: register() }}
                />

                <SponsorshipsContents
                  {...{
                    control, register, watch, setFieldValue, nestIndex: index, lang: 'es',
                  }}
                />
              </div>

              <div className='w-full pl-8'>
                <Typography className='mt-0 mb-8 text-white' variant='body1' component='h3'>
                  Inglés
                </Typography>

                <TextField
                  size='small'
                  className='mb-8'
                  label='Título*'
                  name={`sections[${index}].title_en`}
                  variant='outlined'
                  fullWidth
                  defaultValue={item.title_en}
                  inputProps={{ ref: register() }}
                />

                <SponsorshipsContents
                  {...{
                    control, register, watch, setFieldValue, nestIndex: index, lang: 'en',
                  }}
                />
              </div>
            </div>
          </Paper>
        </div>
      ))}

      <Button color='secondary' variant='contained' type='button' onClick={add}>
        Agregar sección
      </Button>
    </fieldset>
  );
};

SponsorshipsSections.propTypes = {
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default SponsorshipsSections;
