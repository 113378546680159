import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Button, TextField, LinearProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { navigate } from '@reach/router';
import { DialogsConfirm } from '..';


const FormUserTraining = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm({
    submitFocusError: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const formState = useSelector((state) => ({
    status: state.training.status,
    error: state.training.error,
    catalogs: state.commentator.catalogs,
  }));

  const { videoLanguages } = useSelector((state) => state.catalog);
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);


  // register custom fields

  const onSubmit = (data) => {
    const values = setValuesForRequest(data);
    dispatch({ type: 'training/createUser', payload: { values, enqueueSnackbar } });
  }

  const setValuesForRequest = (data) => {
    let values = Object.assign({}, data);
    values.videos = values.videos.split(',');
    return jsonToFormData(values);
  }

  const buildFormData = (formData, data, parentKey) => {
    if(data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      })
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  const jsonToFormData = (data) => {
    const formData = new FormData();

    for(let [key, value] of Object.entries(data)) {
      buildFormData(formData, value, key);
    }

    return formData;
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    setOpen(false);
    navigate('/training/users');
  }

  const handleClickCancel = () => {
    setOpen(true);
  }

  useEffect(() => {
    dispatch({ type: 'catalog/videoLanguages', payload: { enqueueSnackbar } })
    setShowForm(true)
    
    // eslint-disable-next-line
  }, []);


  return (
    <div className='max-w-screen-xl p-8 mx-auto'>
      <DevTool control={control} />
      {formState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
        Usuario nuevo para capacitacion
      </Typography>

      <DialogsConfirm
        open={open}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        title={'Cancelar'}
        message={'¿Deseas cancelar y volver a la lista?'}
        confirmText={'Aceptar'}
        cancelText={'Cancelar'} />

      <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
      {showForm &&
        <Paper className='p-8 my-8 grid grid-cols-1 md:grid-cols-2 gap-6'>

          <TextField
            label='Nombre Completo'
            name='name'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
          

        <TextField
            label='Email'
            name='email'
            type="email"
            variant='outlined'
            autoComplete="nope"
            fullWidth
            inputProps={{
            ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
        />

        <TextField
            label='Password'
            name='password'
            type='password'
            variant='outlined'
            autoComplete="off"
            fullWidth
            inputProps={{
            ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
        />


         


      <input
          className="hidden"
          type="text"
          name="videos"
          ref={register({ required: 'El campo es requerido.' })} />

        <Autocomplete
            options={videoLanguages}
            fullWidth
            multiple
            //ref={tournamentsRef}
            getOptionLabel={(option) => option.name}
            onChange={(_, selectedTournaments) => setValue('videos', selectedTournaments.map((language) => language.id))}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Seleccione idioma en los que narras"
                    variant="outlined"
                    error={!!errors.videos}
                    helperText={errors.videos && errors.videos.message} />
            }
      />

          
        </Paper> }
      </fieldset>
        <footer className='p-8 mt-8 text-right'>
          <Button 
            type='button' 
            className='mr-8'
            onClick={handleClickCancel} >
            Cancelar
          </Button>

          <Button type='submit' variant='contained' color='primary'>
            Guardar
          </Button>
        </footer>
      </form>
    </div>
  );
};

export default FormUserTraining;
