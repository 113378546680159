import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  withStyles,
  TableFooter,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const NewMatchesList = () => {
  const { errors } = useForm({ submitFocusError: true });

  const [returnVideoId, setReturnVideoId] = useState(null);
  const [isDeleiting, setIsDeleiting] = useState(null);

  const { enqueueSnackbar } = useSnackbar();
  const auditionState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    list: state.video.list,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    tournament_id: '',
    v2_account_id: ''
  });

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({ ...st, page: newPage + 1 }));
  };

  const confirmReturnVideo = () => {
    setIsDeleiting(true);
    dispatch({
      type: 'video/deleteMatch',
      payload: {
        id: returnVideoId,
        enqueueSnackbar,
        handleClose: () => {
          setReturnVideoId(null);
          setIsDeleiting(false);
        },
        setPaginationParams
      }
    });
  };

  const handleSportPlaceChange = (event) => {
    event.persist();
    setPaginationParams((st) => ({ ...st, v2_account_id: event.target.value }));

    const sportPlaceId = event.target.value;
    dispatch({ type: 'sportPlace/activeTournaments2', payload: { sportPlaceId, enqueueSnackbar } });
  };

  const handleTournamentChange = (event) => {
    event.persist();

    setPaginationParams((st) => ({ ...st, tournament_id: event.target.value }));
  };

  useEffect(() => {
    const values = { params: paginationParams };
    dispatch({ type: 'video/newMatchesList', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  const handleReturnVideoClick = (id) => {
    setReturnVideoId(id);
  };

  const handleExcelClick = () => {
    const values = { params: paginationParams };
    dispatch({ type: 'video/excelViewMatch', payload: { values, enqueueSnackbar } });
  };

  return (
    <main>
      <Dialog
        open={Boolean(returnVideoId)}
        onClose={() => setReturnVideoId(null)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Eliminar Partido</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            ¿Desea eliminar el siguiente partido junto con sus videos?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReturnVideoId(null)}>No</Button>
          <Button
            onClick={() => confirmReturnVideo()}
            variant='outlined'
            color='primary'
            autoFocus
            disabled={isDeleiting}>
            {isDeleiting ? 'Eliminando...' : 'SI'}
          </Button>
        </DialogActions>
      </Dialog>

      {auditionState.status === 'waiting' && (
        <LinearProgress className='absolute top-0 left-0 w-full' />
      )}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Listados de partidos
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 '>
        <FormControl error={errors.sport_place_id} fullWidth={true}>
          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select
            label='Cuentas'
            id='sportPlace'
            native
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {auditionState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl error={errors.tournament_id} fullWidth={true}>
          <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
          <Select
            label='Torneo'
            id='tournament_id'
            native
            onChange={handleTournamentChange}
            name='tournament_id'>
            <option aria-label='Selecciona torneo' value='' />
            {auditionState.tournaments?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
          <FormHelperText>{errors.tournament_id?.message}</FormHelperText>
        </FormControl>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          disabled={auditionState.status === 'waiting'}
          onClick={handleExcelClick}>
          Exportar a Excel
        </Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id Partido</StyledTableCell>
                <StyledTableCell>Fecha Partido</StyledTableCell>
                <StyledTableCell>Cuenta</StyledTableCell>
                <StyledTableCell>Torneo</StyledTableCell>
                <StyledTableCell>Equipo A</StyledTableCell>
                <StyledTableCell>Equipo B</StyledTableCell>
                <StyledTableCell># de Videos</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditionState.list?.data?.map((row, i) => (
                <Row key={i} handleReturnVideoClick={handleReturnVideoClick} row={row} />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={auditionState.list?.meta?.total || 0}
                  page={paginationParams.page - 1}
                  onChangePage={handleChangePage}
                  rowsPerPage={auditionState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  );
};

const Row = ({ row,handleReturnVideoClick }) => {
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.date_mexico}</StyledTableCell>
        <StyledTableCell>{row.v2_tournament?.account?.name}</StyledTableCell>
        <StyledTableCell>{row.v2_tournament?.name}</StyledTableCell>
        <StyledTableCell>{row.team_a_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.team_b_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.videos_count}</StyledTableCell>
        <StyledTableCell>
            <Button onClick={() => handleReturnVideoClick(row.id)} variant='contained' size="small" style={{ background:"red",color:'white' }}   >
                    <DeleteIcon />
            </Button>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default NewMatchesList;
