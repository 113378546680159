import ax from '../../axios';
import { navigate } from '@reach/router';
//import downloadExcel from '../helpers/downloadExcel';

const training = {
  state: {
    list: null,
    error: null,
    status: '',
    getVideo: null
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    
    createUser: (state) => ({ ...state, status: 'waiting', error: null }),
    createUserSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    createUserFailed: (state, error) => ({ ...state, status: 'failed', error }),

    disabledUser: (state) => ({ ...state, status: 'waiting', error: null }),
    disabledUserSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    disabledUserFailed: (state, error) => ({ ...state, status: 'failed', error }),

    listVideos: (state) => ({ ...state, status: 'waiting', error: null }),
    listVideosSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listVideosFailed: (state, error) => ({ ...state, status: 'failed', error }),


    createVideo: (state) => ({ ...state, status: 'waiting', error: null }),
    createVideoSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    createVideoFailed: (state, error) => ({ ...state, status: 'failed', error }),
    get: (state) => ({
        ...state,
        status: 'waiting',
        error: null,
        getVideo: null,
    }),
    getSuccess: (state, res) => ({
        ...state,
        status: 'received',
        getVideo: res,
    }),
    getFailed: (state, error) => ({ ...state, status: 'failed', error }),

    updateVideo: (state) => ({ ...state, status: 'waiting', error: null }),
    updateVideoSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    updateVideoFailed: (state, error) => ({ ...state, status: 'failed', error }),

    listReport: (state) => ({ ...state, status: 'waiting', error: null }),
    listReportSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listReportFailed: (state, error) => ({ ...state, status: 'failed', error }),

  },
  effects: (dispatch) => ({
    
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/trainings/users', values).then((res) => {
        dispatch.training.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.training.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    listVideos(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/trainings/videos', values).then((res) => {
        dispatch.training.listVideosSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.training.listVideosFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    createUser(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/v2/trainings/users', values).then((res) => {
        enqueueSnackbar('Narrador Creado', { variant: 'success' });
        navigate('/training/users');
        dispatch.training.createUserSuccess(res);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.training.createUserFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    disabledUser(payload) {
      const {id, enqueueSnackbar, handleClose, setPaginationParams  } = payload;

      ax.post(`/v2/trainings/disabled/user/${id}`).then((res) => {
        dispatch.training.disabledUserSuccess(res);
        enqueueSnackbar('usuario actualizado correctamente', { variant: 'success' });
        handleClose();
        setPaginationParams((st) => ({...st, page: 1}));
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.training.disabledUserFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    createVideo(payload) {
      const { values, enqueueSnackbar,setIsSaving } = payload;

      ax.post(`/v2/trainings/videos`, values).then((res) => {
          enqueueSnackbar('Video guardado Correctamente', { variant: 'success' });
          dispatch.training.createVideoSuccess(res);
          navigate('/training/videos');

      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.training.createVideoFailed(error.response);
          setIsSaving(false);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    get({ id, enqueueSnackbar }) {
      ax.get(`/v2/trainings/videos/show/${id}`).then((res) => {
          dispatch.training.getSuccess(res.data.data);
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.training.getFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    updateVideo(payload) {
      const { values, enqueueSnackbar,setIsSaving,id } = payload;

      ax.post(`/v2/trainings/videos/update/${id}`, values).then((res) => {
          enqueueSnackbar('Video actualizado Correctamente', { variant: 'success' });
          dispatch.training.updateVideoSuccess(res);
          navigate('/training/videos');

      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.training.updateVideoFailed(error.response);
          setIsSaving(false);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },


    listReport(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/trainings/report', values).then((res) => {
        dispatch.training.listReportSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.training.listReportFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    

  }),
};

export default training;
