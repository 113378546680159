import React, { useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button, Select, InputLabel, FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

const VideosAssignCommentator = ({
  video, open, handleClose, setRefresh,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm({ submitFocusError: true });
  const dispatch = useDispatch();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    commentators: state.commentator.list,
  }));

  const onSubmitHandler = (values) => {
    dispatch({ type: 'video/assign', payload: { values, enqueueSnackbar } });
  }

  useEffect(() => {
    dispatch({ type: 'commentator/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (videoState.status === 'received') {
      handleClose();
      setRefresh(true);
    }
  // eslint-disable-next-line
  }, [videoState.status]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogTitle id='form-dialog-title'>Asignar video a comentarista</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Video Id: {video?.id}
          </DialogContentText>
          <input
            ref={register}
            name='video_id'
            type='hidden'
            defaultValue={video?.id}
          />
          <FormControl error={errors.commentator_id}>
            <InputLabel htmlFor='commentator_id'>Comentarista</InputLabel>
            <Select 
              label='Comentarista' 
              id='commentator_id' 
              native 
              name='commentator_id'
              defaultValue={video?.commentator?.id}
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                }),
              }}>
                <option aria-label='Selecciona comentarista' value='' />
              {videoState.commentators?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.profile?.name} {item.profile?.lastname} - {item.email}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.commentator_id?.message}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancelar
          </Button>
          <Button
            color='primary'
            disabled={videoState.status === 'waiting'}
            type='submit'
          >
            Asignar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

VideosAssignCommentator.propTypes = {
  open: PropTypes.bool.isRequired,
  video: PropTypes.objectOf(PropTypes.any),
  handleClose: PropTypes.func.isRequired,
};

export default VideosAssignCommentator;
