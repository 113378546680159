import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';

const RichEditor = (props) => {
  const {
    onInit, onChange, onBlur, data,
  } = props;
  const config = { toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'] };
  return (
    <div className='text-black'>
      <CKEditor
        editor={ClassicEditor}
        data={data || ''}
        onInit={(editor) => {
          onInit(editor);
        }}
        onChange={(event, editor) => {
          onChange(editor.getData(), event);
        }}
        onBlur={(e) => onBlur(e)}
        config={config}
      />
    </div>
  );
};

RichEditor.propTypes = {
  onInit: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.string,
};

RichEditor.defaultProps = {
  onInit: () => null,
  onChange: () => null,
  onBlur: () => null,
  data: '',
};

export default RichEditor;
