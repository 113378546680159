import React, { useEffect } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ZeroCoins = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userState = useSelector((state) => ({
    status: state.user.status,
    error: state.user.error,
    users: state.user.list,
    excel: state.user.excelUrl,
  }));
  const dispatch = useDispatch();

  const handleExcelClick = () => {
    dispatch({ type: 'user/zeroCoinsExcel', payload: { enqueueSnackbar } });
  }
  
  useEffect(() => {
    dispatch({ type: 'user/zeroCoins', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);


  return (
    <main>
      {userState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Usuarios Cero Monedas
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={userState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>Teléfono</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell>Fecha de creación</StyledTableCell>
                <StyledTableCell>Monedas gastadas</StyledTableCell>
                <StyledTableCell>Fecha última moneda</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userState.users?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.email}</StyledTableCell>
      <StyledTableCell>{row.profile?.name}</StyledTableCell>
      <StyledTableCell>{row.profile?.username}</StyledTableCell>
      <StyledTableCell>{row.phone}</StyledTableCell>
      <StyledTableCell>{row.place}</StyledTableCell>
      <StyledTableCell>{format(new Date(row.created_at), 'dd/MM/yyyy')}</StyledTableCell>
      <StyledTableCell align='right'>{row.used_videos}</StyledTableCell>
      <StyledTableCell>{row.date_used_video && format(new Date(row.date_used_video), 'dd/MM/yyyy')}</StyledTableCell>
    </StyledTableRow>
  );
}

export default ZeroCoins;
