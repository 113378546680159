import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Button, TextField, LinearProgress,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { navigate,useParams } from '@reach/router';
import { DialogsConfirm } from '..';

const defaultValues = {
  is_professional:0,
  name: '',
  lastname: '',
  email: '',
  city: '',
  code_country: '',
  cell_phone: '',
  year_old: null,
  password: '',
};

const CommentatorsSimpleForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    reset,
  } = useForm({
    submitFocusError: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const formState = useSelector((state) => ({
    status: state.commentator.status,
    error: state.commentator.error,
    catalogs: state.commentator.catalogs,
  }));

  const { videoLanguages } = useSelector((state) => state.catalog);
  const { id: commentatorId } = useParams();
  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { selectedCommentator } = useSelector((state) => state.commentator);


  // register custom fields

  const onSubmit = (data) => {
    const values = setValuesForRequest(data);

    commentatorId ? dispatch({ type: 'commentator/update', payload: { values,lists: 1,id:commentatorId, enqueueSnackbar } }) :
    dispatch({ type: 'commentator/create', payload: { values,lists: 1, enqueueSnackbar } });
     
  }

  const setValuesForRequest = (data) => {
    let values = Object.assign({}, data);
    values.videos = values.videos.split(',');
    if(commentatorId){
      values._method = "PUT";
    }
    return jsonToFormData(values);
  }

  const buildFormData = (formData, data, parentKey) => {
    if(data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      })
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  const jsonToFormData = (data) => {
    const formData = new FormData();

    for(let [key, value] of Object.entries(data)) {
      buildFormData(formData, value, key);
    }

    return formData;
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    setOpen(false);
    navigate('/commentators/no-professionals');
  }

  const handleClickCancel = () => {
    setOpen(true);
  }

  useEffect(() => {
    dispatch({ type: 'catalog/videoLanguages', payload: { enqueueSnackbar } })
    dispatch({ type: 'commentator/catalogs', payload: { enqueueSnackbar } });

    if(commentatorId){
      dispatch({ type: 'commentator/get', payload: { id: commentatorId, enqueueSnackbar } });
    }else{
      setShowForm(true)
    }
    return () => {
      reset();
      dispatch({ type: 'commentator/resetSelectedCommentator' });
    };
    // eslint-disable-next-line
  }, [commentatorId]);

  useEffect(() => {
    if (selectedCommentator) {
        setShowForm(true);
    }
    // eslint-disable-next-line
  }, [selectedCommentator])

  return (
    <div className='max-w-screen-xl p-8 mx-auto'>
      <DevTool control={control} />
      {formState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
        Nuevo Narrador/Relator
      </Typography>

      <DialogsConfirm
        open={open}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        title={'Cancelar'}
        message={'¿Deseas cancelar y volver a la lista?'}
        confirmText={'Aceptar'}
        cancelText={'Cancelar'} />

      <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
      {showForm &&
        <Paper className='p-8 my-8 grid grid-cols-1 md:grid-cols-2 gap-6'>
          <input
            type='hidden'
            name='is_professional'
            value={defaultValues.is_professional}
            ref={register()}
          />

          <TextField
            label='Nombre(s)'
            defaultValue={commentatorId ? selectedCommentator?.profile?.name : defaultValues.name}
            name='name'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
          
          <TextField
            label='Apellido(s)'
            defaultValue={commentatorId ? selectedCommentator?.profile?.lastname : defaultValues.lastname}

            name='lastname'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.lastname}
            helperText={errors.lastname && errors.lastname.message}
          />

          <TextField
            error={!!errors.year_old}
            defaultValue={commentatorId ? selectedCommentator?.profile?.year_old : defaultValues.year_old}

            label='Edad'
            name='year_old'
            type='number'
            variant='outlined'
            fullWidth
            helperText={errors.year_old && errors.year_old.message}
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
          />

          <TextField
            label='Ciudad'
            defaultValue={commentatorId ? selectedCommentator?.profile?.city : defaultValues.city}
            name='city'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.city}
            helperText={errors.city && errors.city.message}
          />

          {!commentatorId && 
          <>
            <TextField
              label='Email'
              name='email'
              type="email"
              variant='outlined'
              autoComplete="off"
              fullWidth
              inputProps={{
                ref: register({ required: 'El campo es requerido.' }),
              }}
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
            />

            <TextField
              label='Password'
              name='password'
              type='password'
              variant='outlined'
              autoComplete="off"
              fullWidth
              inputProps={{
                ref: register({ required: 'El campo es requerido.' }),
              }}
              error={!!errors.password}
              helperText={errors.password && errors.password.message}
            />


          <input
            type='hidden'
            name='code_country'
            ref={register()}
          />
          <Controller
            name="cell_phone"
            control={control}
            defaultValue={defaultValues.cell_phone}
            rules={{ required: true }}
            render={({ name, onBlur, onChange, value }) => (
              <MuiPhoneNumber
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                id="contactPhone"
                countryCodeEditable={false}
                disableAreaCodes={true}
                preferredCountries={['mx', 'ar', 'us']}
                defaultCountry='mx'
                autoFormat= {false}
                //style={{ width: "100%" }}
                label="Teléfono"
                variant="outlined"
                error={Boolean(errors.cell_phone)}
              />
              
            )}
          />
          </>

        }

      <input
          className="hidden"
          type="text"
          name="videos"
          ref={register({ required: 'El campo es requerido.' })} />

        <Autocomplete
            defaultValue={videoLanguages.filter((language) => selectedCommentator?.videoLanguages.map((item) => item.id).includes(language.id))}
            options={videoLanguages}
            fullWidth
            multiple
            //ref={tournamentsRef}
            getOptionLabel={(option) => option.name}
            onChange={(_, selectedTournaments) => setValue('videos', selectedTournaments.map((language) => language.id))}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Seleccione idioma en los que narras"
                    variant="outlined"
                    error={!!errors.videos}
                    helperText={errors.videos && errors.videos.message} />
            }
      />

          
        </Paper> }
      </fieldset>
        <footer className='p-8 mt-8 text-right'>
          <Button 
            type='button' 
            className='mr-8'
            onClick={handleClickCancel} >
            Cancelar
          </Button>

          <Button type='submit' variant='contained' color='primary'>
            Guardar
          </Button>
        </footer>
      </form>
    </div>
  );
};

export default CommentatorsSimpleForm;
