import React, { useState, useEffect, useRef } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress, IconButton
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useParams } from '@reach/router';
import { DialogsConfirm } from '..';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';
import Utils from './../../Utils';



function Form() {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const locationsList = useSelector((state) => state.catalog.locations)
    const { selectedAccount } = useSelector((state) => state.account);
    const { countries, states, cities, accountTypes,languages,currencies,videoLanguages } = useSelector((state) => state.catalog);
    const { id: accountId } = useParams();
    const [isSaving, setIsSaving] = useState(false);
    const stateRef = useRef();
    const cityRef = useRef();

    const questions = [
        {
            id: 1,
            name: 'SI'
        },

        {
            id: 0,
            name: 'NO'
        },
    ];

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }
    
    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate('/accounts');
    }


    const onSubmit = (values) => {
        setIsSaving(true);
        const formattedValues = {
            name: values.name,
            email: values.email,
            password: values.password,
            location_id: values.location_id,
            account_type_id: values.account_type_id,
            name_contact: values.name_contact,
            phone_contact: values.phone_contact,
            email_contact: values.email_contact,
            v2_account_language_id: values.v2_account_language_id,
            v2_account_currency_id: values.v2_account_currency_id,
            videos_languages : values.videos.split(','),
            has_narrated_videos: values.has_narrated_videos,
            hidden_videos: values.hidden_videos,
            invoice: {
                name: values.invoice_name,
                rfc: values.invoice_rfc,
                city: values.invoice_city,
                state: values.invoice_state,
                country: values.invoice_country,
                number: values.invoice_number,
                street: values.invoice_street,
                internal: values.invoice_internal,
                postal_code: values.invoice_postal_code,
                neigborhood: values.invoice_neigborhood,
            }
        }
        
        selectedAccount
            ? dispatch({ type: 'account/update', payload: { values: formattedValues, id: selectedAccount.id, enqueueSnackbar } })
            : dispatch({ type: 'account/create', payload: { values: formattedValues, enqueueSnackbar } });
    }

    const onCityChange = (_, city) => {
        setValue('invoice_city', city?.name);
    }


    const onStateChange = (_, state) => {
        cityRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        setValue('invoice_state', state?.name);
        setValue('invoice_city', '');
        if (state) {
            dispatch({ type: 'catalog/cities', payload: { stateId: state.id, enqueueSnackbar } });
        }
    }

    const onCountryChange = (_, country) => {
        stateRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        setValue('invoice_country', country?.name, { shouldValidate: true, shouldDirty: true })
        setValue('invoice_state', '');
        setValue('invoice_city', '');
        if (country) {
            dispatch({ type: 'catalog/states', payload: { countryId: country?.id, enqueueSnackbar } });
        }
    }

    useEffect(() => {
        if (selectedAccount && countries?.length && !firstLoadDone) {
            setValue('invoice_country', selectedAccount.account.invoice.country);
            const selectedCountry = countries.find((country) => country.name === selectedAccount.account.invoice.country);
            if (selectedCountry) {
                dispatch({ type: 'catalog/states', payload: { countryId: selectedCountry.id, enqueueSnackbar } });
            } else {
                setValue('invoice_state', '');
                setValue('invoice_city', '');
                setShowForm(true);
                setFirstLoadDone(true);
            }
        }
        // eslint-disable-next-line
    }, [selectedAccount, countries])
    
    useEffect(() => {
        if (selectedAccount && states?.length && !firstLoadDone ) {
            const selectedState = states.find((state) => state.name === selectedAccount.account.invoice.state);
            if (selectedState) {
                dispatch({ type: 'catalog/cities', payload: { stateId: selectedState.id, enqueueSnackbar } });
            } else {
                setValue('invoice_city', '');
                setShowForm(true);
                setFirstLoadDone(true);
            }
        }
        // eslint-disable-next-line
    }, [selectedAccount, states])
    
    useEffect(() => {
        if (selectedAccount && cities?.length && accountTypes?.length && !firstLoadDone && videoLanguages?.length) {
            setShowForm(true);
            setFirstLoadDone(true);
        }
        // eslint-disable-next-line
    }, [selectedAccount, cities, accountTypes,videoLanguages])
    
    useEffect(() => {
        dispatch({ type: 'catalog/videoLanguages', payload: { enqueueSnackbar } })
        dispatch({ type: 'catalog/locations', payload: { enqueueSnackbar } })
        dispatch({ type: 'catalog/countries', payload: { enqueueSnackbar } });
        dispatch({ type: 'catalog/accountTypes', payload: { enqueueSnackbar } });
        dispatch({ type: 'catalog/languages', payload: { enqueueSnackbar } });
        dispatch({ type: 'catalog/currencies', payload: { enqueueSnackbar } });
        if (accountId) {
            dispatch({ type: 'account/get', payload: { id: accountId, enqueueSnackbar } });
        } else {
            setShowForm(true);
            setFirstLoadDone(true);
        }
        
        // eslint-disable-next-line
    }, [accountId])

    useEffect(() => {
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(isSaving || formState.status === 'waiting' || !firstLoadDone) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <div className="flex items-center gap-2">
                <IconButton aria-label="delete" onClick={() => navigate('/accounts')}>
                    <ArrowBackIcon />
                </IconButton>

                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                    {accountId ? 'Editar' : 'Nueva'} Cuenta
                </Typography>
            </div>

            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={'¿Deseas cancelar y volver a la lista?'}
                confirmText={'Aceptar'}
                cancelText={'Cancelar'} />
            
            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                {showForm && <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <TextField
                            defaultValue={selectedAccount?.account?.name}
                            label='Nombre'
                            name='name'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                ref: register({ required: 'El campo es requerido' }),
                            }}
                            error={!!errors.name}
                            helperText={errors.name && errors.name.message}
                        />

                        {!selectedAccount &&
                            <>
                                <TextField
                                    label='Correo'
                                    name='email'
                                    variant='outlined'
                                    fullWidth
                                    type="email"
                                    inputProps={{
                                        ref: register({ required: 'El campo es requerido' }),
                                    }}
                                    error={!!errors.email}
                                    helperText={errors.email && errors.email.message}
                                />

                                <TextField
                                    label='Contraseña'
                                    name='password'
                                    variant='outlined'
                                    fullWidth
                                    autoComplete="off"
                                    type="password"
                                    inputProps={{
                                        ref: register({ required: 'El campo es requerido' }),
                                    }}
                                    error={!!errors.password}
                                    helperText={errors.password && errors.password.message}
                                />

                                <input
                                    type="text"
                                    name="location_id"
                                    className="hidden"
                                    ref={register({ required: 'El campo es requerido' })}  />

                                <Autocomplete
                                    options={locationsList}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, location) => setValue('location_id', location?.id, { shouldValidate: true, shouldDirty: true })}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Seleccione una locación de referencia"
                                            variant="outlined"
                                            error={!!errors.location_id}
                                            helperText={errors.location_id && errors.location_id.message} />
                                    }
                                />

                            </>
                        }

                        <input
                            type="text"
                            name="account_type_id"
                            className="hidden"
                            defaultValue={selectedAccount?.account?.account_type_id}
                            ref={register({ required: 'El campo es requerido' })}  />

                        {!accountId || accountTypes?.length ? 
                            <Autocomplete
                                defaultValue={accountTypes?.find((type) => type.id === selectedAccount?.account?.account_type_id)}
                                options={accountTypes}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={(_, type) => setValue('account_type_id', type?.id, { shouldValidate: true, shouldDirty: true })}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione un tipo de cuenta"
                                        variant="outlined"
                                        error={!!errors.account_type_id}
                                        helperText={errors.account_type_id && errors.account_type_id.message} />
                                }
                            /> : null}

                        <input
                            type="text"
                            name="v2_account_language_id"
                            className="hidden"
                            defaultValue={selectedAccount?.account?.v2_account_language_id}
                            ref={register({ required: 'El campo es requerido' })}  />

                        {!accountId || languages?.length ? 
                            <Autocomplete
                                defaultValue={languages?.find((type) => type.id === selectedAccount?.account?.v2_account_language_id)}
                                options={languages}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={(_, type) => setValue('v2_account_language_id', type?.id, { shouldValidate: true, shouldDirty: true })}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione idioma de la cuenta"
                                        variant="outlined"
                                        error={!!errors.v2_account_language_id}
                                        helperText={errors.v2_account_language_id && errors.v2_account_language_id.message} />
                                }
                            /> : null}

                                <input
                                    className="hidden"
                                    type="text"
                                    name="videos"
                                    defaultValue={selectedAccount?.account?.video_languages.map(u => u.id).join(',')}

                                    ref={register({ required: 'El campo es requerido.' })} />

                                

                                <Autocomplete
                                    defaultValue={videoLanguages.filter((language) => selectedAccount?.account?.video_languages.map((item) => item.id).includes(language.id))}
                                    options={videoLanguages}
                                    fullWidth
                                    multiple
                                    //ref={tournamentsRef}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, selectedTournaments) => setValue('videos', selectedTournaments.map((language) => language.id))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Seleccione los idiomas de narracion"
                                            variant="outlined"
                                            error={!!errors.videos}
                                            helperText={errors.videos && errors.videos.message} />
                                    }
                                />

                                <input
                                    className="hidden"
                                    type="text"
                                    name="has_narrated_videos"
                                    defaultValue={selectedAccount?.account?.has_narrated_videos}
                                    ref={register({ required: 'El campo es requerido.' })} />

                                <Autocomplete
                                    defaultValue={questions?.find((type) => type.id === selectedAccount?.account?.has_narrated_videos)}
                                    fullWidth
                                    options={questions}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, type) => setValue('has_narrated_videos', type?.id, { shouldValidate: true, shouldDirty: true })}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="¿Se deben narrar los videos de esta cuenta?"
                                            variant="outlined"
                                            error={!!errors.has_narrated_videos}
                                            helperText={errors.has_narrated_videos && errors.has_narrated_videos.message} />
                                    }
                                />

                                <input
                                    className="hidden"
                                    type="text"
                                    name="hidden_videos"
                                    defaultValue={selectedAccount?.account?.hidden_videos}
                                    ref={register({ required: 'El campo es requerido.' })} />

                                <Autocomplete
                                    defaultValue={questions?.find((type) => type.id === selectedAccount?.account?.hidden_videos)}
                                    fullWidth
                                    options={questions}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, type) => setValue('hidden_videos', type?.id, { shouldValidate: true, shouldDirty: true })}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="¿Desea ocultar los videos?"
                                            variant="outlined"
                                            error={!!errors.hidden_videos}
                                            helperText={errors.hidden_videos && errors.hidden_videos.message} />
                                    }
                                />
                        
                        
                    </div>

                    <fieldset className='py-2'>
                        <legend>Datos para órdenes de compra</legend>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <TextField
                                defaultValue={selectedAccount?.account?.name_contact}
                                label='Nombre de contacto que autorizará las órdenes'
                                name='name_contact'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register(),
                                }}
                                error={!!errors.name_contact}
                                helperText={errors.name_contact && errors.name_contact.message}
                            />


                            <TextField
                                defaultValue={selectedAccount?.account?.email_contact}
                                label='Correo electrónico para envío de órdenes'
                                name='email_contact'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register(),
                                }}
                                error={!!errors.email_contact}
                                helperText={errors.email_contact && errors.email_contact.message}
                            />

                            <TextField
                                defaultValue={selectedAccount?.account?.phone_contact}
                                label='Número telefónico para órdenes de compra'
                                name='phone_contact'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register(),
                                }}
                                error={!!errors.phone_contact}
                                helperText={errors.phone_contact && errors.phone_contact.message}
                            />

                            <input
                                type="text"
                                name="v2_account_currency_id"
                                className="hidden"
                                defaultValue={selectedAccount?.account?.v2_account_currency_id}
                                ref={register()}  />
                            
                            {!accountId || currencies?.length ? 
                                <Autocomplete
                                    defaultValue={currencies?.find((type) => type.id === selectedAccount?.account?.v2_account_currency_id)}
                                    options={currencies}
                                    fullWidth
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, type) => setValue('v2_account_currency_id', type?.id, { shouldValidate: true, shouldDirty: true })}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Seleccione moneda de cobro"
                                            variant="outlined"
                                            error={!!errors.v2_account_currency_id}
                                            helperText={errors.v2_account_currency_id && errors.v2_account_currency_id.message} />
                                    }
                                /> : null}

                        </div>
                        
                    </fieldset>

                    <fieldset className="py-2">
                        <legend>Facturación</legend>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.name}
                                label='Razon social'
                                name='invoice_name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido' }),
                                }}
                                error={!!errors.invoice_name}
                                helperText={errors.invoice_name && errors.invoice_name.message}
                            />

                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.rfc}
                                label='RFC'
                                name='invoice_rfc'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido' }),
                                }}
                                error={!!errors.invoice_rfc}
                                helperText={errors.invoice_rfc && errors.invoice_rfc.message}
                            />

                            <input
                                defaultValue={selectedAccount?.account?.invoice?.country && countries.find((country) => country.name === selectedAccount.account.invoice.country)?.name}
                                type="text"
                                name="invoice_country"
                                className="hidden"
                                ref={register({ required: 'El campo es requerido' })}  />
                            
                            <input
                                defaultValue={selectedAccount?.account?.invoice?.state && states.find((state) => state.name === selectedAccount.account.invoice.state)?.name}
                                type="text"
                                name="invoice_state"
                                className="hidden"
                                ref={register({ required: 'El campo es requerido' })}  />
                            
                            <input
                                defaultValue={selectedAccount?.account?.invoice?.city && cities.find((city) => city.name === selectedAccount.account.invoice.city)?.name}
                                type="text"
                                name="invoice_city"
                                className="hidden"
                                ref={register({ required: 'El campo es requerido' })}  />

                            <Autocomplete
                                defaultValue={selectedAccount?.account?.invoice?.country && countries.find((country) => country.name === selectedAccount.account.invoice.country)}
                                options={countries}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onCountryChange}
                                clearOnBlur
                                autoComplete={false}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione un país"
                                        variant="outlined"
                                        error={!!errors.invoice_country}
                                        helperText={errors.invoice_country && errors.invoice_country.message} />
                                }
                            />

                            <Autocomplete
                                defaultValue={selectedAccount?.account?.invoice?.state && states.find((state) => state.name === selectedAccount?.account?.invoice?.state)}
                                options={states}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onStateChange}
                                autoComplete={false}
                                clearOnBlur
                                ref={stateRef}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione un estado"
                                        variant="outlined"
                                        error={!!errors.invoice_state}
                                        helperText={errors.invoice_state && errors.invoice_state.message} />
                                }
                            />

                            <Autocomplete
                                defaultValue={selectedAccount?.account?.invoice?.city && cities.find((city) => city.name === selectedAccount?.account?.invoice?.city)}
                                options={cities}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onCityChange}
                                clearOnBlur
                                ref={cityRef}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione una ciudad"
                                        variant="outlined"
                                        error={!!errors.invoice_city}
                                        helperText={errors.invoice_city && errors.invoice_city.message} />
                                }
                            />

                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.neigborhood}
                                label='Colonia'
                                name='invoice_neigborhood'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido' }),
                                }}
                                error={!!errors.invoice_neigborhood}
                                helperText={errors.invoice_neigborhood && errors.invoice_neigborhood.message}
                            />

                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.street}
                                label='Calle'
                                name='invoice_street'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido' }),
                                }}
                                error={!!errors.invoice_street}
                                helperText={errors.invoice_street && errors.invoice_street.message}
                            />

                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.number}
                                label='Número exterior'
                                name='invoice_number'
                                variant='outlined'
                                fullWidth
                                autoComplete="off"
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido' }),
                                }}
                                error={!!errors.invoice_number}
                                helperText={errors.invoice_number && errors.invoice_number.message}
                            />

                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.internal}
                                label='Número interior'
                                name='invoice_internal'
                                variant='outlined'
                                fullWidth
                                autoComplete="off"
                                inputProps={{
                                    ref: register(),
                                }}
                                error={!!errors.invoice_internal}
                                helperText={errors.invoice_internal && errors.invoice_internal.message}
                            />
                            
                            <TextField
                                defaultValue={selectedAccount?.account?.invoice?.postal_code}
                                label='Código postal'
                                name='invoice_postal_code'
                                variant='outlined'
                                fullWidth
                                onKeyDown={Utils.onlyNumbers}
                                autoComplete="off"
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido' }),
                                }}
                                error={!!errors.invoice_postal_code}
                                helperText={errors.invoice_postal_code && errors.invoice_postal_code.message}
                            />
                        </div>
                    </fieldset>

                    

                </Paper>}
                <footer className='p-8 mt-8 text-right'>
                    <Button 
                        type='button' 
                        className='mr-8'
                        onClick={() => setCancelDialogIsOpen(true)} >
                        Cancelar
                    </Button>

                    <Button type='submit' variant='contained' color='primary' disabled={isSaving || formState.status === 'waiting'}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;