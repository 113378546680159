import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Button, TextField,
  MenuItem, ListItemText, Checkbox,
  FormControlLabel, LinearProgress,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import MuiPhoneNumber from 'material-ui-phone-number';
import FileUpload from '../FileUpload/FileUpload';
import ImageIcon from '@material-ui/icons/Image';
import { SelectMultiple } from '..';
import { navigate } from '@reach/router';
import { DialogsConfirm } from '..';

const defaultValues = {
  is_professional: 1,
  name: '',
  lastname: '',
  email: '',
  city: '',
  code_country: '-',
  cell_phone: '',
  year_old: null,
  password: '',
  review: '',
  ios_token: '1234',
  android_token: '1234',
  price: null,
  image: null,
  video: null,
  nacionalities: [],
  languages: [],
  narration_times: '',
  televisions: [],
  tones: [],
  accents: [],
  cause_name: '',
  cause_link: '',
  cause_description: '',
  specials: [{
    // TODO: Change this part so we do not use id from database for this catalog
    commentator_special_id: 1,
    text_bold: '',
    link: '',
    description: ''
  }]
};

const CommentatorsForm = () => {
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm({
    submitFocusError: true,
    defaultValues,
  });
  const { enqueueSnackbar } = useSnackbar();
  const formState = useSelector((state) => ({
    status: state.commentator.status,
    error: state.commentator.error,
    catalogs: state.commentator.catalogs
  }));
  const dispatch = useDispatch();
  const [cause, setCause] = useState(false);
  const [open, setOpen] = useState(false);

  const setFieldValue = (name, value) => {
    setValue(name, value);
  };

  // register custom fields
  useEffect(() => {
    register({ name: 'image' });
    register({ name: 'video' });
    return () => {
      unregister({ name: 'image' });
      unregister({ name: 'video' });
    };
  }, [register, unregister]);

  const onSubmit = (data) => {
    const values = setValuesForRequest(data);

    dispatch({ type: 'commentator/create', payload: { values,lists: 2, enqueueSnackbar } });
  }

  const setValuesForRequest = (data) => {
    let values = Object.assign({}, data);

    values.narration_times = [{ id: values.narration_times.id }]
    values.nacionalities = values.nacionalities.map((item) => ({ id: item.id }));
    values.languages = values.languages.map((item) => ({ id: item.id }));
    values.televisions = values.televisions.map((item) => ({ id: item.id }));
    values.tones = values.tones.map((item) => ({ id: item.id }));
    values.accents = values.accents.map((item) => ({ id: item.id }));

    return jsonToFormData(values);
  }

  const buildFormData = (formData, data, parentKey) => {
    if(data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      })
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  const jsonToFormData = (data) => {
    const formData = new FormData();

    for(let [key, value] of Object.entries(data)) {
      buildFormData(formData, value, key);
    }

    return formData;
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    setOpen(false);
    navigate('/commentators/professionals');
  }

  const handleClickCancel = () => {
    setOpen(true);
  }

  useEffect(() => {
    dispatch({ type: 'commentator/catalogs', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  return (
    <div className='max-w-screen-xl p-8 mx-auto'>
      <DevTool control={control} />
      {formState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
        Nuevo Narrador/Relator Profesional
      </Typography>

      <DialogsConfirm
        open={open}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        title={'Cancelar'}
        message={'¿Deseas cancelar y volver a la lista?'}
        confirmText={'Aceptar'}
        cancelText={'Cancelar'} />

      <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <Paper className='p-8 my-8 grid grid-cols-1 md:grid-cols-2 gap-6'>
          <input
            type='hidden'
            name='is_professional'
            ref={register()}
          />

          <TextField
            label='Nombre(s)'
            name='name'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
          
          <TextField
            label='Apellido(s)'
            name='lastname'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.lastname}
            helperText={errors.lastname && errors.lastname.message}
          />

          <TextField
            error={!!errors.year_old}
            label='Edad'
            name='year_old'
            type='number'
            variant='outlined'
            fullWidth
            helperText={errors.year_old && errors.year_old.message}
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
          />

          <TextField
            label='Ciudad'
            name='city'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.city}
            helperText={errors.city && errors.city.message}
          />

          <TextField
            label='Email'
            name='email'
            type="email"
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.email}
            helperText={errors.email && errors.email.message}
          />

          <TextField
            label='Password'
            name='password'
            type='password'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.password}
            helperText={errors.password && errors.password.message}
          />

          <TextField
            name="review"
            label="Descripción"
            multiline
            rows={3}
            variant="outlined"
            className="md:col-span-2"
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.review}
            helperText={errors.review && errors.review.message}
          />

          <input
            type='hidden'
            name='code_country'
            ref={register()}
          />
          <Controller
            name="cell_phone"
            control={control}
            rules={{ required: true }}
            render={({ name, onBlur, onChange, value }) => (
              <MuiPhoneNumber
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                id="contactPhone"
                countryCodeEditable={false}
                disableAreaCodes={true}
                preferredCountries={['mx', 'ar', 'us']}
                defaultCountry='mx'
                style={{ width: "100%" }}
                label="Teléfono"
                variant="outlined"
                error={Boolean(errors.cell_phone)}
              />
            )}
          />

          <TextField
            error={!!errors.price}
            label='Precio'
            type='number'
            name='price'
            variant='outlined'
            fullWidth
            helperText={errors.price && errors.price.message}
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
              inputMode: 'numeric', pattern: '[0-9]+(?.[0-9]+)?',
              step: 'any'
            }}
          />

          <TextField
            label='iOS Token'
            name='ios_token'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.ios_token}
            helperText={errors.ios_token && errors.ios_token.message}
          />

          <TextField
            label='Android Token'
            name='android_token'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.android_token}
            helperText={errors.android_token && errors.android_token.message}
          />

          { formState.catalogs?.data?.nacionalities && 
            <SelectMultiple 
              control={control}
              errors={errors}
              items={formState.catalogs?.data?.nacionalities}
              name='nacionalities'
              label='Nacionalidades'
            />
          }

          { formState.catalogs?.data?.languages &&
            <SelectMultiple 
              control={control}
              errors={errors}
              items={formState.catalogs?.data?.languages}
              name='languages'
              label='Idiomas'
            />
          }

          { formState.catalogs?.data?.time_narration && 
            <Controller
              render={({ onChange, value }) => {
                return (
                  <TextField
                    select
                    variant="outlined"
                    label="Tiempo de narración"
                    error={!!errors.narration_times}
                    helperText={errors.narration_times && errors.narration_times.message}
                    SelectProps={{
                      value: value,
                      onChange: onChange
                    }}
                  >
                    {formState.catalogs?.data?.time_narration.map((n) => (
                      <MenuItem key={n.id} value={n}>
                        <ListItemText primary={n.name} />
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
              control={control}
              name="narration_times"
              rules={{
                required: 'El campo es requerido.'
               }}
            />
          }

          { formState.catalogs?.data?.televisions && 
            <SelectMultiple 
              control={control}
              errors={errors}
              items={formState.catalogs?.data?.televisions}
              name='televisions'
              label='Televisoras'
            />
          }

          { formState.catalogs?.data?.tones && 
            <SelectMultiple 
              control={control}
              errors={errors}
              items={formState.catalogs?.data?.tones}
              name='tones'
              label='Tonos'
            />
          }

          { formState.catalogs?.data?.accents && 
            <SelectMultiple 
              control={control}
              errors={errors}
              items={formState.catalogs?.data?.accents}
              name='accents'
              label='Acentos'
            />
          }

          <div>
            <FileUpload
              label='Imagen'
              id="image"
              icon={<ImageIcon className='w-12 h-12 mb-2' />}
              fileChange={setFieldValue}
              accept='image/*'
            />
          </div>

          <div>
            <FileUpload
              label='Video'
              id="video"
              icon={<ImageIcon className='w-12 h-12 mb-2' />}
              fileChange={setFieldValue}
              accept='.mp4'
            />
          </div>

          <FormControlLabel
            control={
              <Checkbox checked={cause} onChange={() => setCause(!cause)} name="cause" />
            }
            label="Causa"
          />

          { cause &&
            <>
              <input
                type='hidden'
                name='specials.0.commentator_special_id'
                ref={register()}
              />
              <TextField
                label='Nombre de la causa'
                name='specials.0.text_bold'
                variant='outlined'
                fullWidth
                inputProps={{
                  ref: register({ required: 'El campo es requerido.' }),
                }}
                error={errors.specials && !!errors.specials[0]?.text_bold}
                helperText={errors.specials && errors.specials[0]?.text_bold && errors.specials[0]?.text_bold.message}
              />
              <TextField
                label='Link'
                name='specials.0.link'
                variant='outlined'
                fullWidth
                inputProps={{
                  ref: register({ required: 'El campo es requerido.' }),
                }}
                error={errors.specials && !!errors.specials[0]?.link}
                helperText={errors.specials && errors.specials[0]?.link && errors.specials[0]?.link.message}
              />
              <TextField
                name="specials.0.description"
                label="Descripción"
                multiline
                rows={2}
                variant="outlined"
                inputProps={{
                  ref: register({ required: 'El campo es requerido.' }),
                }}
                error={errors.specials && !!errors.specials[0]?.description}
                helperText={errors.specials && errors.specials[0]?.description && errors.specials[0]?.description.message}
              />
            </>
          }
        </Paper>
      </fieldset>
        <footer className='p-8 mt-8 text-right'>
          <Button 
            type='button' 
            className='mr-8'
            onClick={handleClickCancel} >
            Cancelar
          </Button>

          <Button type='submit' variant='contained' color='primary'>
            Guardar
          </Button>
        </footer>
      </form>
    </div>
  );
};

export default CommentatorsForm;
