import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button,
  Select, InputLabel, RadioGroup, FormControlLabel,
  Radio
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const VideosByWeek = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    videos: state.video.weeks,
    sportPlaces: state.sportPlace.list,
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
    sport_place_id: '',
    status: '',
  });
  const [videos, setVideos] = useState([]);
  const [columns, setColumns] = useState(null);
  const [status, setStatus] = useState('');

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }

  const setWeekRange = (first, last, years) => {
    if (years[first.p_year]) {
      if (years[first.p_year].min > first.p_week) {
        years[first.p_year].min = first.p_week
      }
    } else {
      years[first.p_year] = {
        min: first.p_week,
        max: first.p_week
      }
    }

    if (years[last.p_year]) {
      if (years[last.p_year].max < last.p_week) {
        years[last.p_year].max = last.p_week
      }
    } else {
      years[last.p_year] = {
        min: last.p_week,
        max: last.p_week
      }
    }
  }

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/excelWeeks', payload: { values, enqueueSnackbar } });
  }

  const handleSportPlaceChange = (event) => {
      event.persist();

      setPaginationParams((st) => ({...st, sport_place_id: event.target.value}));

  }

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/byWeeks', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() =>  {
    if (videoState.videos) {
      if (videoState.videos.data.length) {
        let years = {};
        let first, last;
        let all = [];
        let item;
        let statuses = {
          pending: 0,
          commentator: 0,
          'pending-validation': 0,
          'rejected-validation': 0,
          release: 0,
          'report-narrator': 0,
          'report-user': 0,
          'not-narration': 0,
          'report-user-accepted': 0,
          'invalid-video': 0,
          preselection: 0,
          'rejected-admin': 0,
          'match-no-valid': 0,
          limbo: 0,
          total: 0
        }

        videoState.videos.data.forEach((user) => {
          first = user.video_highlight[0];
          last = user.video_highlight[user.video_highlight.length-1];
          let lastTmp;

          if (first.p_year !== last.p_year) {
            user.video_highlight.forEach((week) => {
              if (week.p_year === first.p_year) {
                lastTmp = week;
              } else {
                setWeekRange(first, lastTmp, years);
                first = week;
                lastTmp = last;
              }
            });
          }

          setWeekRange(first, last, years);
        });

        let i;
        let totalWeeks = 0;
        for(let year in years) {
          totalWeeks = 0;
          for(i=years[year].min; i <= years[year].max; i++){
            totalWeeks += 1;
            years[year][i] = statuses;
          }
          years[year].totalWeeks = totalWeeks;
          delete years[year].min;
          delete years[year].max;
        }

        videoState.videos.data.forEach((user) => {
          item = {
            id: user.id,
            email: user.email,
            phone: user.cell_phone,
            i_can_create_match: user.i_can_create_match,
            weeks: JSON.parse(JSON.stringify(years))
          }
    
          user.video_highlight.forEach((week) => {
            item.weeks[week.p_year][week.p_week][week.status] = week.videos
            item.weeks[week.p_year][week.p_week].total = item.weeks[week.p_year][week.p_week].total + week.videos
          });

          all.push(item);
        });
        setColumns(years);
        setVideos(all);
      } else {
        setColumns([]);
        setVideos([]);
      }
    }
  // eslint-disable-next-line
  }, [videoState.videos])

  const handleChangeStatus = (event) => {
    const val = event.target.value;
    setStatus(val);
    setPaginationParams((st) => ({...st, status: val}));
  };

  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Usuarios que suben video por semana
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {videoState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </Paper>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 text-white'>
        <RadioGroup aria-label="status" row name="status" value={status} onChange={handleChangeStatus}>
          <FormControlLabel value="" control={<Radio />} label="Todos" />
          <FormControlLabel value="release" control={<Radio />} label="Narrados" />
          <FormControlLabel value="not-narration" control={<Radio />} label="Sin narración" />
        </RadioGroup>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={videoState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell rowSpan={2}>Id</StyledTableCell>
                <StyledTableCell rowSpan={2}>Email</StyledTableCell>
                <StyledTableCell rowSpan={2}>Teléfono</StyledTableCell>
                <StyledTableCell rowSpan={2}>¿Es Camarografo?</StyledTableCell>
                {columns && Object.keys(columns).map(year => (
                  Object.keys(columns[year]).map(week => (
                    week === 'totalWeeks' && 
                      <StyledTableCell align='center' key={year} colSpan={columns[year][week]}>{year}</StyledTableCell>
                  ))
                ))}
              </TableRow>
              <TableRow>
              {columns && Object.keys(columns).map(year => (
                  Object.keys(columns[year]).map((week, index) => (
                    week !== 'totalWeeks' && <StyledTableCell align='right' key={index}>{week}</StyledTableCell>
                  ))
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {videos.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={videoState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.email}</StyledTableCell>
      <StyledTableCell>{row.phone}</StyledTableCell>
      <StyledTableCell>{row.i_can_create_match ? 'SI' : 'NO'}</StyledTableCell>
      {row.weeks && Object.keys(row.weeks).map(year => (
        Object.keys(row.weeks[year]).map(week => (
          Object.keys(row.weeks[year][week]).map((status, index) => (
            (week !== 'totalWeeks' && status === 'total') && 
              <StyledTableCell align='right' key={index}>{row.weeks[year][week][status]}</StyledTableCell>
          ))
        ))
      ))}
    </StyledTableRow>
  );
}

export default VideosByWeek;
