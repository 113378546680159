import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import UploadThumbnail from '../../UploadThumbnail/UploadThumbnail';

const SponsorshipsTitle = (props) => {
  const {
    register, errors, setVal, lang, title,
  } = props;

  return (
    <>
      <Typography className='mt-0 mb-8 text-white' variant='body1' component='h3'>
        {title}
      </Typography>

      <UploadThumbnail
        id={`image_${lang}`}
        editable
        accept='image/*'
        fileChange={setVal}
      />

      <TextField
        size='small'
        className='my-8'
        label='Título*'
        name={`title_${lang}`}
        variant='outlined'
        fullWidth
        inputProps={{
          ref: register({ required: 'El campo es requerido.' }),
        }}
        error={!!errors[`title_${lang}`]}
        helperText={errors[`title_${lang}`] && errors[`title_${lang}`].message}
      />

      <TextField
        size='small'
        className='mb-8'
        label='Nombre*'
        name={`name_${lang}`}
        variant='outlined'
        fullWidth
        inputProps={{
          ref: register({ required: 'El campo es requerido.' }),
        }}
        error={!!errors[`name_${lang}`]}
        helperText={errors[`name_${lang}`] && errors[`name_${lang}`].message}
      />

      <TextField
        size='small'
        className='mb-8'
        label='Descripción*'
        name={`description_${lang}`}
        variant='outlined'
        fullWidth
        multiline
        inputProps={{
          ref: register({ required: 'El campo es requerido.' }),
        }}
        rows='4'
        error={!!errors[`description_${lang}`]}
        helperText={errors[`description_${lang}`] && errors[`description_${lang}`].message}
      />
    </>
  );
};

SponsorshipsTitle.propTypes = {
  register: PropTypes.func.isRequired,
  setVal: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  lang: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SponsorshipsTitle;
