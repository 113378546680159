import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';

const userBank = {
  state: {
    list: null,
    error: null,
    status: '',
    excelUrl:''
  },
  reducers: {
    // Pending auditions
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    excel: (state) => ({
        ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
        ...state,
        status: 'received',
        excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    updateStatusAccount: (state) => ({ ...state, status: 'waiting', error: null }),
    updateStatusAccountSuccess: (state, res) => ({
      ...state,
      status: 'assigned',
      message: res.message,
    }),
    updateStatusAccountFailed: (state, error) => ({ ...state, status: 'failed', error }),
  },
  effects: (dispatch) => ({
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/users-bank', values).then((res) => {
        dispatch.userBank.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.userBank.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excel(payload) {
        const { values, enqueueSnackbar } = payload;
        values.responseType = 'blob';
  
        ax.get('/reports/excel/users-bank', values).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          dispatch.userBank.excelSuccess(url);
          downloadExcel(url, 'users_banks.xlsx');
        })
          .catch((error) => {
            let msg = error.message;
            if (error.response) {
              msg = error.response.data.message;
            }
            dispatch.userBank.excelFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    updateStatusAccount(payload) {
        const { values, enqueueSnackbar, handleClose,setPaginationParams } = payload;
  
        ax.post('/reports/save/status/bank/'+values.user, values).then((res) => {
          dispatch.userBank.updateStatusAccountSuccess(res.data);
          enqueueSnackbar('Estatus actualizado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));

        })
          .catch((error) => {
            let msg = error.message;
            if (error.response) {
              msg = error.response.data.message;
            }
            dispatch.userBank.updateStatusAccountFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
          });
      },
  }),
};

export default userBank;
