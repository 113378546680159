import ax from '../../axios';
import { navigate } from '@reach/router';
import downloadExcel from '../helpers/downloadExcel';

const cameraman = {
  state: {
    list: null,
    listOrders: null,
    error: null,
    status: '',
    show:null
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccessOrders: (state, res) => ({
      ...state,
      status: 'received',
      listOrders: res,
    }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    create: (state) => ({ ...state, status: 'waiting', error: null }),
    createSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    createFailed: (state, error) => ({ ...state, status: 'failed', error }),
    deleteCameraman: (state) => ({ ...state, status: 'waiting', error: null }),
    showCameraman: (state) => ({ ...state, status: 'waiting', error: null }),
    showCameramanSuccess: (state, res) => ({
      ...state,
      status: 'received',
      show: res,
    }),

    showCameramanFailed: (state, error) => ({ ...state, status: 'failed', error }),
    update: (state) => ({ ...state, status: 'waiting', error: null }),
    orders: (state) => ({ ...state, status: 'waiting', error: null }),
    acceptedOrRejected: (state) => ({ ...state, status: 'waiting', error: null }),
    allCameramen: (state) => ({ ...state, status: 'waiting', error: null }),
    excelReport: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),


  },
  effects: (dispatch) => ({
    
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/cameraman/cameramen', values).then((res) => {
        dispatch.cameraman.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    orders(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/cameraman/orders', values).then((res) => {
        dispatch.cameraman.listSuccessOrders(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    allCameramen(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/cameraman/cameramen-all', values).then((res) => {
        dispatch.cameraman.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    showCameraman(payload) {
      const { values, enqueueSnackbar,setFieldValue } = payload;
      ax.get('/cameraman/cameramen/'+values.id).then((res) => {
        let dataCamereman = res.data.data;
        setFieldValue('name',dataCamereman.name)
        setFieldValue('lastname',dataCamereman.lastname)
        setFieldValue('id', dataCamereman.id)
        setFieldValue('_method', 'PUT')
        dispatch.cameraman.showCameramanSuccess(res.data.data);
        
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.showCameramanFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    
    create(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/cameraman/cameramen', values).then((res) => {
        enqueueSnackbar('Camarografo creado', { variant: 'success' });
        dispatch.cameraman.createSuccess(res);
        navigate('/cameramen/lists');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    update(payload) {
      const { values, enqueueSnackbar } = payload;
      ax.post('/cameraman/cameramen/'+values.get('id'), values).then((res) => {
        enqueueSnackbar('Camarografo creado', { variant: 'success' });
        dispatch.cameraman.createSuccess(res);
        navigate('/cameramen/lists');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    deleteCameraman(payload) {
      const { values, enqueueSnackbar, handleClose,setPaginationParams } = payload;

      ax.delete('/cameraman/cameramen/'+values.id).then((res) => {
        dispatch.cameraman.createSuccess(res.data);
        enqueueSnackbar('Camarografo eliminado correctamente', { variant: 'success' });
        handleClose();
        setPaginationParams((st) => ({...st, page: 1}));

      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    acceptedOrRejected(payload) {
      const { values, enqueueSnackbar, handleClose,setPaginationParams } = payload;

      ax.post('/cameraman/orders/store/'+values.id,values).then((res) => {
        dispatch.cameraman.createSuccess(res.data);
        enqueueSnackbar('Orden actualiza correctamente', { variant: 'success' });
        handleClose();
        setPaginationParams((st) => ({...st, page: 1}));

      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelReport(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/cameraman/download-excel', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.cameraman.excelSuccess(url);
        downloadExcel(url, 'orders_cameraman.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.cameraman.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
  }),
};

export default cameraman;
