import React, { useState, useEffect } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress, IconButton
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { DialogsConfirm } from '..';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';

function Form() {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { accounts, cameraman } = useSelector((state) => state.catalog);

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }
    
    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate('/assign-cameraman');
    }

    const onSubmit = (values) => {
        setIsSaving(true);
        const formattedValues = {
            ...values,
            accounts: values.accounts.split(','),
        }
        
        dispatch({ type: 'assignCameraman/create', payload: { values: formattedValues, enqueueSnackbar,type:1 } });
    }

    useEffect(() => {
        if (accounts?.length && cameraman?.length) {
            setFirstLoadDone(true);
        }
    }, [accounts, cameraman])

    useEffect(() => {
        dispatch({ type: 'catalog/cameraman', payload: { enqueueSnackbar } })
        dispatch({ type: 'catalog/accounts', payload: { enqueueSnackbar } })
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(formState.status === 'waiting' || !firstLoadDone || isSaving) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <div className="flex items-center gap-2">
                <IconButton aria-label="delete" onClick={() => navigate('/assign-cameraman')}>
                    <ArrowBackIcon />
                </IconButton>

                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                    Nueva Asignacion de Camarografo
                </Typography>
            </div>

            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={'¿Deseas cancelar y volver a la lista?'}
                confirmText={'Aceptar'}
                cancelText={'Cancelar'} />
            
            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        
                        <input
                            type="text"
                            name="id"
                            className="hidden"
                            ref={register({ required: 'El campo es requerido' })}  />

                        <Autocomplete
                            options={cameraman}
                            fullWidth
                            getOptionLabel={(option) => option.email}
                            onChange={(_, cameraman) => setValue('id', cameraman?.id, { shouldValidate: true, shouldDirty: true })}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Seleccione un camarografo"
                                    variant="outlined"
                                    error={!!errors.id}
                                    helperText={errors.id && errors.id.message} />
                            }
                        />

                        <input
                            type="text"
                            name="accounts"
                            className="hidden"
                            ref={register({ required: 'El campo es requerido' })}  />

                        <Autocomplete
                            options={accounts}
                            fullWidth
                            multiple
                            getOptionLabel={(option) => option.name}
                            onChange={(_, selectedAccounts) => setValue('accounts', selectedAccounts?.map((account) => account.id), { shouldValidate: true, shouldDirty: true })}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Seleccione una o varias cuentas"
                                    variant="outlined"
                                    error={!!errors.accounts}
                                    helperText={errors.accounts && errors.accounts.message} />
                            }
                        />
                        
                    </div>
                </Paper>
                <footer className='p-8 mt-8 text-right'>
                    <Button 
                        type='button' 
                        className='mr-8'
                        onClick={() => setCancelDialogIsOpen(true)} >
                        Cancelar
                    </Button>

                    <Button type='submit' variant='contained' color='primary' disabled={isSaving || formState.status === 'waiting'}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;