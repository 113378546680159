import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Fab,Button,Dialog,DialogActions,DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CameramanList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const CameramanState = useSelector((state) => ({
    status: state.cameraman.status,
    error: state.cameraman.error,
    list: state.cameraman.list
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
  }); 

  const [sendCameraman, setSendCameraman] = useState(0);
  const [open, setOpen] = useState(false);


  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'cameraman/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  const handleOpen = (id) => {
    setSendCameraman(id);

    setOpen(true)
  };

  const handleDeleteClick = () =>{
    let values = {
      id: sendCameraman,
    }
    dispatch({ type: 'cameraman/deleteCameraman', payload: { values, enqueueSnackbar,handleClose,setPaginationParams } });

  }

  const handleClose = () => setOpen(false);


  const Row = ({row}) => {
    return (
      <React.Fragment>
        <StyledTableRow>
        <StyledTableCell>{row.id}</StyledTableCell>
          <StyledTableCell>{row.name}</StyledTableCell>
          <StyledTableCell>{row.lastname}</StyledTableCell>
          <StyledTableCell>
            <Button variant='contained'  type='button' className='mr-1' onClick={() => navigate('/cameramam/edit/'+row.id)}  size="small" style={{ background:"#F9CA2D" }}>
              <CreateIcon className='text-white' />
            </Button>
            {buttonDelete(row.id)}
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  const buttonDelete = (id) =>{

    return (
      <Button onClick={() => handleOpen(id)} variant='contained' size="small" style={{ background:"red" }}   >
            <DeleteIcon className="text-white"  />
      </Button>
    ) 
  }


  return (
    <main>
      {CameramanState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
      <div className='relative max-w-screen-xl p-8 mx-auto'>
        <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
          Camarografos
        </Typography>

        <Fab
          className='absolute right-0 outline-none'
          color='primary'
          aria-label='add'
          onClick={() => navigate('/cameramam/new')}
        >
          <AddIcon />
        </Fab>
      </div>
    </Paper>

    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Eliminar"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea eliminar el siguiente registro?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleDeleteClick()} variant="outlined" color="primary" autoFocus>
              Eliminar
            </Button>
          </DialogActions>
    </Dialog>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Apellidos</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CameramanState.list?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={CameramanState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={CameramanState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}



export default CameramanList;
