import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

export const RESOURCE_TYPES = {
    IMAGE: 'image',
    VIDEO: 'video',
}

const ExistentResSelector = ({
    resources,
    onChange,
    type,
}) => {
    const [selectedResource, setSelectedResource] = useState();    

    const onResourceClick = (id, url) => {
        if (selectedResource !== url) {
            setSelectedResource(url)
            onChange(id);
        } else {
            setSelectedResource(null)
            onChange(null);
        }
    }

    return (
        <div className="h-32 overflow-y-auto p-2 border-2 border-white flex flex-col gap-2">
            {resources.map(({id, url}) =>
                <Paper
                    elevation={1}
                    key={url}
                    onClick={() => onResourceClick(id, url)}
                    className={`p-2 border-4 border-transparent cursor-pointer ${selectedResource === url && 'border-primary'}`}>
                    {type === RESOURCE_TYPES.IMAGE
                        ? <img
                            className={`max-h-8 h-8`}
                            src={url}
                            alt="Resource" />
                        : <video
                            className={`max-h-16 h-16`}
                            src={url} />}
                    
                </Paper>
            )}
        </div>
    )
}

ExistentResSelector.propTypes = {
    resources: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
    })).isRequired,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(RESOURCE_TYPES),
}

ExistentResSelector.defaultProps = {
    onChange: () => {},
    type: RESOURCE_TYPES.IMAGE,
}

export default ExistentResSelector;