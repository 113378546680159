import React, { useState, useEffect, useRef } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress, IconButton, Select, FormControl, InputLabel
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useParams } from '@reach/router';
import { DialogsConfirm } from '..';
import DateFnsUtils from '@date-io/date-fns';
import { RESOURCE_TYPES } from './ExistentResSelector';
import {
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';
import NewOrExistentResSwitch from './NewOrExistentResSwitch';
import { format } from 'date-fns';

// Format from api: 'dd-MM-yyyy'
const formDateFromApi = (dateString = '') => {
    if (dateString === null) return new Date();

    const dateSlices = dateString.split('-');

    return new Date(`${dateSlices[1]}-${dateSlices[2]}-${dateSlices[0]}`);
}

const SPONSOR_TYPES = [
    { id: 'account', value: 'Cuenta' },
    { id: 'tournament', value: 'Torneo' },
    { id: 'team', value: 'Equipo' },
    { id: 'court', value: 'Cancha' },
];

function Form() {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        reset,
        formState,
        watch
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { selectedSponsor, status: sponsorRequestStatus } = useSelector((state) => state.sponsor);
    const { sponsorId } = useParams();
    const { status } = useSelector((state) => state.account);
    const { accounts, tournaments, teams, sponsorAssets, courts } = useSelector((state) => state.catalog);
    const [formFiles, setFormFiles] = useState({
        banner: null,
        watermark: null,
        advertising: null,
        videoAdvertising: null,
    });
    const tournamentsRef = useRef();
    const teamsRef = useRef();
    const courtsRef = useRef();

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }

    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate(`/sponsors`);
    }

    const onSubmit = (values) => {
        setIsSaving(true);
        const formatedValues = {
            ...values,
            accounts: [values.account],
            tournaments: values.tournaments?.split(','),
            teams: values.teams?.split(','),
            courts: values.courts?.split(','),
            date_start: format(new Date(values.date_start), "yyyy-MM-dd"),
            date_end: format(new Date(values.date_end), "yyyy-MM-dd")
        }
        const formData = getFormData(formatedValues);
        if (selectedSponsor) formData.append('_method', 'PUT');
        if (formFiles.banner) formData.append(typeof formFiles.banner === 'number' ? 'banner_id' : 'banner', formFiles.banner);
        if (formFiles.watermark) formData.append(typeof formFiles.watermark === 'number' ? 'watermark_id' : 'watermark', formFiles.watermark);
        if (formFiles.advertising) formData.append(typeof formFiles.advertising === 'number' ? 'advertising_id' : 'advertising', formFiles.advertising);
        if (formFiles.videoAdvertising) formData.append(typeof formFiles.videoAdvertising === 'number' ? 'video_advertising_id' : 'video_advertising', formFiles.videoAdvertising);

        sponsorId
            ? dispatch({ type: 'sponsor/update', payload: { id: sponsorId, values: formData, enqueueSnackbar } })
            : dispatch({ type: 'sponsor/create', payload: { values: formData, enqueueSnackbar } });
    }

    const getFormData = (data) => {
        const formData = new FormData();

        for(let [key, value] of Object.entries(data)) {
            if (Array.isArray(value)) {
                value.forEach((item, i) => {
                    formData.append(`${key}[${i}]`, item);
                })
            } else {
                formData.append(key, value);
            }
        }

        return formData;
    }

    const onAccountChange = (_, account) => {
        if (tournamentsRef.current) tournamentsRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        if (teamsRef.current) teamsRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        if (courtsRef.current) courtsRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        if (account?.id) {
            setValue('account', account.id)
            dispatch({ type: 'catalog/tournaments', payload: { accountId: account.id, enqueueSnackbar } });
            dispatch({ type: 'catalog/teams', payload: { accountId: account.id, enqueueSnackbar } });
            dispatch({ type: 'catalog/courts', payload: { accountId: account.id, enqueueSnackbar } });
            return;
        } else {
            setShowForm(true);
            setFirstLoadDone(true);
        }

        setValue('account', null)
    }

    useEffect(() => {
        if (!sponsorId || (tournaments?.length && teams?.length && selectedSponsor)) {
            setShowForm(true);
            setTimeout(() => setFirstLoadDone(true), 1000)
            if (selectedSponsor) {
                setTimeout(() => {
                    setValue('account', selectedSponsor.account_default);
                    setValue('type', selectedSponsor.type);
                    setValue('courts', selectedSponsor.courts_v2?.map((court) => court.id));
                    setValue('teams', selectedSponsor.teams?.map((team) => team.id));
                    setValue('tournaments', selectedSponsor.tournaments?.map((tournament) => tournament.id));
                }, 500);
            }
        } else if (sponsorId && !selectedSponsor) {
            dispatch({ type: 'sponsor/get', payload: { id: sponsorId, enqueueSnackbar } });
        }
        // eslint-disable-next-line
    }, [sponsorId, tournaments, teams, courts])

    useEffect(() => {
       if (accounts?.length && selectedSponsor) {
            onAccountChange(null, { id: selectedSponsor.account_default });
        }
        // eslint-disable-next-line
    }, [accounts, selectedSponsor])

    useEffect(() => {
        dispatch({ type: 'catalog/sponsorAssets', payload: { enqueueSnackbar } });
        dispatch({ type: 'catalog/accounts', payload: { enqueueSnackbar } });
        
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(status === 'waiting' || !firstLoadDone || isSaving) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <div className="flex items-center gap-2">
                <IconButton aria-label="delete" onClick={() => navigate(`/sponsors`)}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                    {sponsorId ? 'Editar' : 'Nuevo'} Patrocinador
                </Typography>
            </div>


            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={' ¿Estas seguro que quieres cancelar y volver al listado? Ningún cambio se guardará.'}
                confirmText={'Si'}
                cancelText={'No'} />

            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    {showForm && <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <TextField
                                defaultValue={selectedSponsor?.name}
                                label='Nombre del patrocinio *'
                                name='name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.name}
                                helperText={errors.name && errors.name.message}
                            />

                            <TextField
                                defaultValue={selectedSponsor?.link}
                                label='Link *'
                                name='link'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({
                                        required: 'El campo es requerido.',
                                        pattern: {
                                            // eslint-disable-next-line
                                            value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                                            message: 'Ingresa una url válida'
                                        }
                                    }),
                                }}
                                error={!!errors.link}
                                helperText={errors.link && errors.link.message}
                            />

                            <input
                                defaultValue={selectedSponsor ? formDateFromApi(selectedSponsor?.date_start) : new Date()}
                                className="hidden"
                                type="text"
                                name="date_start"
                                ref={register({ required: 'El campo es requerido.' })} />
                            
                            <input
                                defaultValue={selectedSponsor ? formDateFromApi(selectedSponsor?.date_end) : new Date()}
                                className="hidden"
                                type="text"
                                name="date_end"
                                ref={register({ required: 'El campo es requerido.' })} />

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="Fecha de inicio"
                                    minDate={control.getValues().date_start || new Date()}
                                    value={control.getValues().date_start}
                                    onChange={(date) => setValue('date_start', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    label="Fecha de vencimiento"
                                    minDate={control.getValues().date_start || new Date()}
                                    value={control.getValues().date_end}
                                    onChange={(date) => setValue('date_end', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            <FormControl variant="outlined" error={errors.type} fullWidth={true}>
                                <InputLabel htmlFor='type'>Tipo de patrocinio</InputLabel>
                                <Select
                                    defaultValue={selectedSponsor?.type}
                                    label='Tipo de patrocinio *'
                                    native
                                    labelId="type"
                                    id="type"
                                    name='type'
                                    inputProps={{
                                        ref: register({
                                            required: 'El campo es requerido.',
                                        }),
                                    }}>
                                    <option value=""></option>
                                    {SPONSOR_TYPES.map((type) => <option key={type.id} value={type.id}>{type.value}</option>)}
                                </Select>
                            </FormControl>

                            <TextField
                                defaultValue={selectedSponsor?.text_narration}
                                label='Texto narración'
                                name='text_narration'
                                variant='outlined'
                                fullWidth
                                multiline
                                inputProps={{
                                    ref: register({}),
                                }}
                                error={!!errors.text_narration}
                                helperText={errors.text_narration && errors.text_narration.message}
                            />

                            <input
                                className="hidden"
                                type="text"
                                name="account"
                                ref={register({ required: 'El campo es requerido.' })} />

                            <Autocomplete
                                defaultValue={accounts.find((account) => selectedSponsor?.account_default === account.id)}
                                options={accounts}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onAccountChange}
                                clearOnBlur
                                autoComplete={false}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccionar cuenta *"
                                        variant="outlined"
                                        error={!!errors.account} 
                                        helperText={errors.account && errors.account.message} />
                                }
                            />

                            {watch('type') === 'tournament' && <>
                                <input
                                    className="hidden"
                                    type="text"
                                    name="tournaments"
                                    ref={register({ required: 'El campo es requerido.' })} />

                                <Autocomplete
                                    defaultValue={firstLoadDone ? [] : tournaments.filter((tournament) => selectedSponsor?.tournaments?.map((item) => item.id).includes(tournament.id))}
                                    options={tournaments}
                                    fullWidth
                                    multiple
                                    ref={tournamentsRef}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, selectedTournaments) => setValue('tournaments', selectedTournaments.map((tournament) => tournament.id))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Seleccionar torneo(s) *"
                                            variant="outlined"
                                            error={!!errors.tournaments}
                                            helperText={errors.tournaments && errors.tournaments.message} />
                                    }
                                />
                            </>}

                            {watch('type') === 'team' && <>
                                <input
                                    className="hidden"
                                    type="text"
                                    name="teams"
                                    ref={register({ required: 'El campo es requerido.' })} />

                                <Autocomplete
                                    defaultValue={firstLoadDone ? [] : teams.filter((team) => selectedSponsor?.teams?.map((item) => item.id).includes(team.id))}
                                    options={teams}
                                    fullWidth
                                    multiple
                                    ref={teamsRef}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, selectedTeams) => setValue('teams', selectedTeams.map((team) => team.id))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Seleccionar equipo(s) *"
                                            variant="outlined"
                                            error={!!errors.teams}
                                            helperText={errors.teams && errors.teams.message} />
                                    }
                                />
                            </>}

                            {watch('type') === 'court' && <>
                                <input
                                    className="hidden"
                                    type="text"
                                    name="courts"
                                    ref={register({ required: 'El campo es requerido.' })} />

                                <Autocomplete
                                    defaultValue={firstLoadDone ? [] : courts.filter((court) => selectedSponsor?.courts_v2?.map((item) => item.id).includes(court.id))}
                                    options={courts}
                                    fullWidth
                                    multiple
                                    ref={courtsRef}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, selectedCourts) => setValue('courts', selectedCourts.map((court) => court.id))}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Seleccionar cancha(s) *"
                                            variant="outlined"
                                            error={!!errors.courts}
                                            helperText={errors.courts && errors.courts.message} />
                                    }
                                />
                            </>}

                            
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <NewOrExistentResSwitch
                                defaultValue={selectedSponsor?.logo_url}
                                id="image-banner"
                                label="Banner para listado de videos [302px x 54px - 5Mb]"
                                existentItems={sponsorAssets.banner}
                                fileChange={(_, file) => setFormFiles((current) => ({...current, banner: file})) }
                            />

                            <NewOrExistentResSwitch
                                defaultValue={selectedSponsor?.watermark_url}
                                id="image-watermark"
                                label="Logo para marca de agua [314px x 157px - 5Mb] *"
                                existentItems={sponsorAssets.watermark}
                                error={!selectedSponsor && formState.isSubmitted && !formFiles.watermark ? 'El campo es requerido.' : ''}
                                fileChange={(_, file) => setFormFiles((current) => ({...current, watermark: file})) }
                            />

                            <NewOrExistentResSwitch
                                defaultValue={selectedSponsor?.url_advertising}
                                id="image-advertising"
                                label="Banner (GIF) para marca de agua [100'px x 200px - 100Mb]"
                                existentItems={sponsorAssets.advertisingImage}
                                fileChange={(_, file) => setFormFiles((current) => ({...current, advertising: file})) }
                            />

                            <NewOrExistentResSwitch
                                defaultValue={selectedSponsor?.url_video_advertising}
                                id="video-advertising"
                                label="Video Ad de 5 segundos [1080px x 1920px - 100Mb]"
                                accept="video/*"
                                existentItems={sponsorAssets.advertisingVideo}
                                fileChange={(_, file) => setFormFiles((current) => ({...current, videoAdvertising: file})) }
                                type={RESOURCE_TYPES.VIDEO}
                            />
                        </div>
                    </Paper>}
                </fieldset>
                <footer className='p-8 mt-8 text-right'>
                    <Button
                        type='button'
                        className='mr-8'
                        onClick={() => setCancelDialogIsOpen(true)} >
                        Cancelar
                    </Button>

                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={isSaving || sponsorRequestStatus === 'waiting'}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;