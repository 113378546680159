import React, { useState, useEffect } from 'react';
import {
  Paper, InputBase, IconButton,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import css from './SearchBar.module.scss';

const SearchBar = ({ onSearch, placeholder, classes }) => {
  const [search, setSearch] = useState('');
  const [searchDone, setSearchDone] = useState(false);

  const cleanSearch = () => setSearch('');
  const handleChange = (e) => {
    if (!searchDone) {
      setSearchDone(true);
    }
    setSearch(e.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchDone) {
        onSearch(search);
      }
    }, 500);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line
  }, [searchDone, search]);

  return (
    <Paper className={`${css.SearchBar} mb-3 ${classes}`}>
      <SearchIcon className={css['SearchBar-icon']} aria-label='search' />
      <div className={`${css['SearchBar-divider']}`} />

      <InputBase
        className='flex-1'
        placeholder={placeholder}
        value={search}
        onChange={handleChange}
      />

      {search && (
        <IconButton onClick={cleanSearch} size='small'>
          <CloseIcon fontSize='small'>close</CloseIcon>
        </IconButton>
      )}
    </Paper>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  classes: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: 'Buscar',
  classes: '',
};

export default SearchBar;
