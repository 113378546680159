import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Dialog,DialogActions,DialogContent,
  DialogContentText, DialogTitle, Button, Fab
} from '@material-ui/core';
import {
    Create as CreateIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
} from '@material-ui/icons';

import SearchBar from '../SearchBar/SearchBar';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row, onDeleteClick }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.id}</StyledTableCell>
            <StyledTableCell>{row.account_name}</StyledTableCell>
            <StyledTableCell>{row.name}</StyledTableCell>
            <StyledTableCell>{row.type}</StyledTableCell>
            <StyledTableCell>{row.date_start}</StyledTableCell>
            <StyledTableCell>{row.date_end}</StyledTableCell>
            <StyledTableCell>
            <Button variant='contained'  type='button' className='mr-1' onClick={() => navigate(`/sponsors/edit/${row.id}`)}  size="small" style={{ background:"#F9CA2D" }}>
              <CreateIcon className='text-white' />
            </Button>
            <Button onClick={() => onDeleteClick(row.id)} variant='contained' size="small" style={{ background:"red" }}   >
                <DeleteIcon className="text-white"  />
            </Button>
          </StyledTableCell>
        </StyledTableRow>
    );
}

function Sponsors() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
    });
    const [sponsorIdToDelete, setSponsorIdToDelete] = useState(null);
    const [isDeleiting, setIsDeleiting] = useState(false);
    const sponsorState = useSelector((state) => ({
        status: state.sponsor.status,
        error: state.sponsor.error,
        sponsors: state.sponsor.list,
    }));

    const handleChangePage = (_, newPage) => {
        setPaginationParams((st) => ({...st, page: newPage + 1}));
    };

    const confirmDelete = () => {
        setIsDeleiting(true);
        dispatch({ type: 'sponsor/delete', payload: {
            id: sponsorIdToDelete,
            enqueueSnackbar,
            handleClose: () => {
                setSponsorIdToDelete(null);
                setIsDeleiting(false);
            },
            setPaginationParams
        }});
    }

    useEffect(() => {
        const values = {params: paginationParams}
        dispatch({ type: 'sponsor/list', payload: { values, enqueueSnackbar } });
        // eslint-disable-next-line
    }, [paginationParams]);

    useEffect(() => {
            dispatch({ type: 'sponsor/list', payload: {}});
        // eslint-disable-next-line
    }, []);

    return (
        <main>
            <Paper elevation={0}>
                {sponsorState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
                <Dialog open={Boolean(sponsorIdToDelete)} onClose={() => setSponsorIdToDelete(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        Eliminar
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ¿Estas seguro que quieres eliminar este registro?.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSponsorIdToDelete(null)}>No</Button>
                        <Button onClick={() => confirmDelete()} variant="outlined" color="primary" autoFocus disabled={isDeleiting}>
                            {isDeleiting ? 'Eliminando...' : 'Eliminar'}
                        </Button>
                    </DialogActions>
                </Dialog>

                <div className='relative max-w-screen-xl p-8 mx-auto'>
                    <div className="flex items-center gap-2">
                        <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                            Patrocinadores
                        </Typography>
                    </div>
                
                    <Fab
                        style={{transform: 'translateY(50%)'}}
                        className='absolute right-0 bottom-0 outline-none'
                        color='primary'
                        aria-label='add'
                        onClick={() => navigate(`/sponsors/new`)}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto flex space-x-1'>
        
                <div className='max-w-screen-xl flex space-x-4'>
                <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
                </div>
            </div>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Cuenta</StyledTableCell>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Tipo</StyledTableCell>
                                <StyledTableCell>Fecha de inicio</StyledTableCell>
                                <StyledTableCell>Fecha de vencimiento</StyledTableCell>
                                <StyledTableCell>Acciones</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sponsorState.sponsors?.data?.map((row, i) => <Row key={i} row={row} onDeleteClick={() => setSponsorIdToDelete(row.id)} />)}
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[50]}
                                count={sponsorState.sponsors?.meta?.total || 0}
                                page={paginationParams.page-1}
                                onChangePage={handleChangePage}
                                rowsPerPage={sponsorState.sponsors?.meta?.per_page || 50}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </main>
    );
}

export default Sponsors;