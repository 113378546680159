import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination,
  Button,IconButton,Collapse,Box
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ReportExtractor = () => {


  const [ setReturnVideoId] = useState(null);


  const { enqueueSnackbar } = useSnackbar();
  const auditionState = useSelector((state) => ({
    status: state.extractor.status,
    error: state.extractor.error,
    list: state.extractor.list,
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
  }); 

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };




  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'extractor/report', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


    const handleReturnVideoClick = (id) => {
     setReturnVideoId(id);
    }

   

    const handleExcelClick = () => {
        const values = {params: paginationParams}
        dispatch({ type: 'extractor/excel', payload: { values, enqueueSnackbar } });
    }


  return (
    <main>


      {auditionState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Reporte extractores 
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 '>
      <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>
        
       
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        

        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={auditionState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Id Usuario</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Correo</StyledTableCell>
                <StyledTableCell># Partidos</StyledTableCell>
                <StyledTableCell># Videos</StyledTableCell>
                
                
              </TableRow>
            </TableHead>
            <TableBody>
              {auditionState.list?.data?.map((row, i) => <Row key={i} handleReturnVideoClick={handleReturnVideoClick} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={auditionState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={auditionState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow>

      <TableCell>
          {
            row.matches &&
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          </TableCell>
        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.profile?.name}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.total_matches}</StyledTableCell>
        <StyledTableCell>{row.total_videos}</StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" component="div">
                Videos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Match id</TableCell>
                    <TableCell># videos</TableCell>
                    <TableCell>Camara Veo</TableCell>
                    <TableCell>Camara Pixellot</TableCell>
                    <TableCell>Camara Trace</TableCell>
                    <TableCell>Camara Desconocido</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.matches.map((map) => (
                    <TableRow key={map}>
                      <TableCell component="th" scope="row">
                         {map.match_id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {map.count}
                         
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {map.veo}
                         
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {map.pixellot}
                         
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {map.trace}
                         
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {map.unknown}
                         
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </Box>
             
          </Collapse>
        </TableCell>
      </StyledTableRow>
      
    </React.Fragment>
  );
}

export default ReportExtractor;
