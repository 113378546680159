import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const MAPS_LIBRARIES = [
    'places'
]

// Set to México as default
const center = {
    lat: 23.8716119,
    lng: -102.9383935
};

function Map({
    initialPosition,
    label,
    error,
    width,
    height,
    onMapMarkerChange,
    defaultMarker,
}) {
    const [markerPosition, setMarkerPosition] = useState(null);
    const autocompleteRef = useRef(null);
    const [mapZoom, setMapZoom] = useState(defaultMarker ? 16 : 5);
    const [mapPosition, setMapPosition] = useState(defaultMarker || initialPosition);

    const onMapDoubleClick = ({ latLng }) => {
        const { lat, lng } = latLng;
        setMarkerPosition({
            lat: parseFloat(lat()),
            lng: parseFloat(lng())
        });
        
        setTimeout(() => {
            setMapPosition({
                lat: parseFloat(lat()),
                lng: parseFloat(lng())
            });
            setMapZoom(16);
        }, 100);
    }

    const onSearchChange = () => {
        const { lat, lng } = autocompleteRef.current.getPlace().geometry.location;
        setMarkerPosition({
            lat: parseFloat(lat()),
            lng: parseFloat(lng())
        });

        setTimeout(() => {
            setMapPosition({
                lat: parseFloat(lat()),
                lng: parseFloat(lng())
            });
            setMapZoom(16);
        }, 100);
    }
    
    useEffect(() => {
        markerPosition
            ? onMapMarkerChange({
                    lat: markerPosition.lat,
                    lng: markerPosition.lng,
            })
            : onMapMarkerChange({
                lat: null,
                lng: null,
            });
        // eslint-disable-next-line
    }, [markerPosition])

    useEffect(() => {
        if (defaultMarker && !markerPosition) {
            setMarkerPosition({
                lat: parseFloat(defaultMarker.lat),
                lng: parseFloat(defaultMarker.lng)
            });
        }
        // eslint-disable-next-line
    }, [defaultMarker]);

    return (
        <div>
            {label && <p className={`my-2 ${error && 'text-red-100'}`}>{label}</p>}
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY} libraries={MAPS_LIBRARIES}>
                <GoogleMap
                    mapContainerStyle={{
                        width,
                        height,
                    }}
                    center={mapPosition}
                    zoom={mapZoom}
                    onDblClick={onMapDoubleClick}
                >
                    {/* <Autocomplete onLoad={() => {}} onPlaceChanged={() => {}} /> */}
                    <Autocomplete
                        onLoad={(autocomplete) => autocompleteRef.current = autocomplete}
                        onPlaceChanged={onSearchChange}
                    >
                        <input
                            type="text"
                            placeholder="Busca una ubicación"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                width: `240px`,
                                height: `32px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "50%",
                                marginLeft: "-120px",
                                color: '#000',
                                marginTop: '10px',
                                padding: '20px 10px'
                            }}
                        />
                    </Autocomplete>
                    {markerPosition && <Marker position={markerPosition} draggable onDragEnd={onMapDoubleClick} />}
                <></>
                </GoogleMap>
            </LoadScript>
            {error && <span className="text-red-100 text-sm pl-2">{error}</span>}
        </div>
    );
}

const LatLngType = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
}

Map.propTypes = {
    initialPosition: PropTypes.shape(LatLngType),
    defaultMarker: PropTypes.shape(LatLngType),
    label: PropTypes.string,
    error: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onMarkerChange: PropTypes.func,
}

Map.defaultProps = {
    initialPosition: center,
    defaultMarker: null,
    label: '',
    width: '100%',
    height: '400px',
    error: null,
    onMarkerChange: () => {},
}

export default Map;