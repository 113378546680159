import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination
} from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.user?.id}</StyledTableCell>
            <StyledTableCell>{row.user?.profile?.name}</StyledTableCell>
            <StyledTableCell>{row.user?.email}</StyledTableCell>
            <StyledTableCell>{row.user?.cell_phone}</StyledTableCell>
            <StyledTableCell>{row.click}</StyledTableCell>
            <StyledTableCell>{row.subscription}</StyledTableCell>
            
        </StyledTableRow>
    );
}



function SurveyList() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
    });
    const orderState = useSelector((state) => ({
        status: state.survey.status,
        error: state.survey.error,
        list: state.survey.list,
    }));

    const handleChangePage = (_, newPage) => {
        setPaginationParams((st) => ({...st, page: newPage+1}));
    };

    

    useEffect(() => {
        const values = {params: paginationParams}
        dispatch({ type: 'survey/list', payload: { values, enqueueSnackbar } });
        // eslint-disable-next-line
    }, [paginationParams]);

    return (
        <main>
            {orderState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

           

            <Paper elevation={0}>
                <div className='relative max-w-screen-xl p-8 mx-auto'>
                    <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                        Encuesta de suscripcion
                    </Typography>
                    
                </div>
                
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Telefono</StyledTableCell>
                                <StyledTableCell>Click</StyledTableCell>
                                <StyledTableCell>Solicito Suscripcion</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderState.list?.data?.map((row, i) => <Row key={i} row={row} />)}
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[50]}
                                count={orderState.list?.meta?.total || 0}
                                page={paginationParams.page-1}
                                onChangePage={handleChangePage}
                                rowsPerPage={orderState.list?.meta?.per_page || 50}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </main>
    );
}

export default SurveyList;