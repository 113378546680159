import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Fab,Badge
} from '@material-ui/core';
import {
    Add as AddIcon
} from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.id}</StyledTableCell>
            <StyledTableCell>{row.account?.name}</StyledTableCell>
            <StyledTableCell>{row.name_contact}</StyledTableCell>
            <StyledTableCell>{row.email_contact}</StyledTableCell>
            <StyledTableCell>{row.phone_contact}</StyledTableCell>
            <StyledTableCell>{row.quantity}</StyledTableCell>
            <StyledTableCell>{row.price}</StyledTableCell>
            <StyledTableCell>{row.additional}</StyledTableCell>
            <StyledTableCell>{row.description_additional}</StyledTableCell>
            <StyledTableCell>{(parseFloat(row.price) * parseInt(row.quantity)) + parseFloat(row.additional)}</StyledTableCell>
            <StyledTableCell>{status(row.status?.name)}</StyledTableCell>
            
        </StyledTableRow>
    );
}

function status(statu){

    if(statu === "paid"){
        return (<Badge badgeContent={'Pagado'} style={{ color:'rgb(102, 187, 106)' }}></Badge>)
    }else{
        return (<Badge badgeContent={'Pendiente'} style={{ color:'#ffc107' }}></Badge>)

    }
}

function OrderList() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
    });
    const orderState = useSelector((state) => ({
        status: state.order.status,
        error: state.order.error,
        list: state.order.list,
    }));

    const handleChangePage = (_, newPage) => {
        setPaginationParams((st) => ({...st, page: newPage+1}));
    };

    

    useEffect(() => {
        const values = {params: paginationParams}
        dispatch({ type: 'order/list', payload: { values, enqueueSnackbar } });
        // eslint-disable-next-line
    }, [paginationParams]);

    return (
        <main>
            {orderState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

           

            <Paper elevation={0}>
                <div className='relative max-w-screen-xl p-8 mx-auto'>
                    <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                        Ordenes de compra
                    </Typography>
                    <Fab
                        style={{transform: 'translateY(50%)'}}
                        className='absolute right-0 bottom-0 outline-none'
                        color='primary'
                        aria-label='add'
                        onClick={() => navigate('/orders/new')}
                    >
                        <AddIcon />
                    </Fab>
                </div>
                
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Cuenta</StyledTableCell>
                                <StyledTableCell>Nombre Contacto</StyledTableCell>
                                <StyledTableCell>Email Contacto</StyledTableCell>
                                <StyledTableCell>Telefono Contacto</StyledTableCell>
                                <StyledTableCell># Partidos</StyledTableCell>
                                <StyledTableCell>$ por Partido</StyledTableCell>
                                <StyledTableCell>$ Monto Adicional</StyledTableCell>
                                <StyledTableCell>Descripcion Adicional</StyledTableCell>
                                <StyledTableCell>Total</StyledTableCell>
                                <StyledTableCell>Estatus</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderState.list?.data?.map((row, i) => <Row key={i} row={row} />)}
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[50]}
                                count={orderState.list?.meta?.total || 0}
                                page={paginationParams.page-1}
                                onChangePage={handleChangePage}
                                rowsPerPage={orderState.list?.meta?.per_page || 50}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </main>
    );
}

export default OrderList;