import axios from '../../axios';
import { navigate } from '@reach/router';

const court = {
    state: {
        list: null,
        error: null,
        status: '',
        message: '',
        selectedSponsor: null,
    },
    reducers: {
        list: (state) => ({
            ...state,
            list: null,
            status: 'waiting',
            error: null,
            selectedSponsor: null,
        }),
        listSuccess: (state, res) => ({
            ...state,
            status: 'received',
            list: res,
        }),
        listFailed: (state, error) => ({
            ...state,
            status: 'failed',
            error,
            selectedSponsor: null,
        }),
        create: (state) => ({ ...state, status: 'waiting', error: null }),
        createSuccess: (state, res) => ({
            ...state,
            status: 'received',
            message: res.message,
        }),
        createFailed: (state, error) => ({ ...state, status: 'failed', error }),
        update: (state) => ({ ...state, status: 'waiting', error: null }),
        updateSuccess: (state, res) => ({
            ...state,
            status: 'received',
            message: res.message,
        }),
        updateFailed: (state, error) => ({ ...state, status: 'failed', error }),
        get: (state) => ({
            ...state,
            status: 'waiting',
            error: null,
            selectedSponsor: null,
        }),
        getSuccess: (state, res) => ({
            ...state,
            status: 'received',
            selectedSponsor: res,
        }),
        getFailed: (state, error) => ({ ...state, status: 'failed', error }),
        delete: (state) => ({
            ...state,
            status: 'waiting',
            error: null,
            selectedSponsor: null,
        }),
        deleteSuccess: (state, res) => ({
            ...state,
            status: 'received',
            selectedSponsor: res,
        }),
        deleteFailed: (state, error) => ({ ...state, status: 'failed', error }),
    },
    effects: (dispatch) => ({
        list(payload) {
            const { values, enqueueSnackbar } = payload;
            axios.get('/v2/sponsors', values).then((res) => {
                dispatch.sponsor.listSuccess(res.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.sponsor.listFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        create(payload) {
            const { values, enqueueSnackbar } = payload;
      
            axios.post('/v2/sponsors', values).then((res) => {
                enqueueSnackbar('Patrocinador Creado', { variant: 'success' });
                dispatch.sponsor.createSuccess(res);
                navigate('/sponsors');
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.sponsor.createFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        update(payload) {
            const { id, values, enqueueSnackbar } = payload;
      
            axios.post(`/v2/sponsors/${id}`, values).then((res) => {
                enqueueSnackbar('Patrocinador Editado', { variant: 'success' });
                dispatch.sponsor.updateSuccess(res);
                navigate('../');
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.sponsor.updateFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        get({ id, enqueueSnackbar }) {
            axios.get(`/v2/sponsors/${id}`).then((res) => {
                dispatch.sponsor.getSuccess(res.data.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.sponsor.getFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        delete(payload) {
            const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      
            axios.delete(`/v2/sponsors/${id}`).then((res) => {
                dispatch.sponsor.deleteSuccess(res.data);
                enqueueSnackbar('Patrocinador eliminado correctamente', { variant: 'success' });
                handleClose();
                setPaginationParams((st) => ({...st, page: 1}));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.sponsor.deleteFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
    })
}

export default court;