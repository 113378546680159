import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button,
  Select, InputLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import SearchBar from '../SearchBar/SearchBar';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Activity = () => {
  const { enqueueSnackbar } = useSnackbar();
  const teamState = useSelector((state) => ({
    error: state.team.error,
    videos: state.team.list,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments,

  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
    sport_place_id: '',
    tournament_id: '',
    search: '',
  });
  const [videos, setVideos] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }


  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'team/excelActivity', payload: { values, enqueueSnackbar } });
  }

  const handleSportPlaceChange =  (event) => {

    if(event?.target?.value){
      setPaginationParams((st) => ({...st, sport_place_id: event.target.value}));

      const sportPlaceId =event.target.value;
      dispatch({ type: 'sportPlace/activeTournaments2', payload: { sportPlaceId, enqueueSnackbar } });
    }
    
  }

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'team/activity', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() =>  {
    if (teamState.videos) {
      if (teamState.videos.data.length) {
        let all = [];
        let item;
       

       

        teamState.videos.data.forEach((user) => {
          item = user

          all.push(item);
        });
        setVideos(all);
      } else {
        setVideos([]);
      }
    }
  // eslint-disable-next-line
  }, [teamState.videos])

 

  return (
    <main>
      {teamState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Actividad de equipo
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {teamState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </Paper>

        
      </div>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <Paper  className="mb-3 px-4 pt-2">
            <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
            <Select
              style={{ minWidth: "400px"}} 
              label='Torneo' 
              id='tournament_id' 
              native 
              onChange={(event) => { setPaginationParams((st) => ({...st, tournament_id: event.target.value})) }}
              name='tournament_id'>
                <option aria-label='Selecciona torneo' value='' />
              {teamState.tournaments?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
        </Paper>
        
        <div className='max-w-screen-xl  mx-auto flex space-x-4'>
          <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
        </div>
      </div>



      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={teamState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell >Id equipo</StyledTableCell>
                <StyledTableCell >Nombre equipo</StyledTableCell>
                <StyledTableCell >Torneo Activo</StyledTableCell>
                <StyledTableCell >Likes</StyledTableCell>
                <StyledTableCell >Share</StyledTableCell>
                <StyledTableCell >Download</StyledTableCell>
                <StyledTableCell >Me Divierte</StyledTableCell>
                <StyledTableCell >Total Vistas</StyledTableCell>
                <StyledTableCell >Videos Cargados</StyledTableCell>
                <StyledTableCell >Videos Aprobados por QA</StyledTableCell>
              </TableRow>
              
            </TableHead>
            <TableBody>
              {videos.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={teamState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={teamState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.tournament?.name}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_like_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_shared_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_download_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_enjoy_count}</StyledTableCell>
      <StyledTableCell>{row.total_views}</StyledTableCell>
      <StyledTableCell>{row.videos_upload_count}</StyledTableCell>
      <StyledTableCell>{row.videos_aproved_narration_count}</StyledTableCell>
    </StyledTableRow>
  );
}

export default Activity;
