import { init } from '@rematch/core';
import auth from './models/auth';
import user from './models/user';
import video from './models/video';
import team from './models/team';
import audition from './models/audition';
import sportPlace from './models/sportPlace';
import commentator from './models/commentator';
import userBank from './models/userBank';
import userTransfer from './models/UserTransfer';
import cameraman from './models/cameraman';
import location from './models/location';
import court from './models/court';
import catalog from './models/catalog';
import tournament from './models/tournament';
import account from './models/account';
import sponsor from './models/sponsor';
import assignCameraman from './models/assignCameraman';
import newcameraman from './models/newcameraman';
import order from './models/order';
import survey from './models/survey';
import updateVideo from './models/updateVideo';
import training from './models/training';
import animation from './models/animation';
import extractor from './models/extractor';

const store = init({
  redux: {
    rootReducers: {
      RESET_APP: () => undefined,
    },
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production',
    },
  },
  models: {
    auth,
    user,
    video,
    team,
    audition,
    sportPlace,
    commentator,
    userBank,
    userTransfer,
    cameraman,
    location,
    court,
    catalog,
    tournament,
    account,
    sponsor,
    assignCameraman,
    newcameraman,
    order,
    survey,
    updateVideo,
    training,
    animation,
    extractor
  },
});

export default store;
