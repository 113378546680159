import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination,
  Link, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { PlayTypesEnum } from '../../enums';

const StyledTableCell = withStyles((theme) => ({
  /* root: {
    borderRightWidth: 1,
    borderRightColor: theme.palette.grey[300],
    borderRightStyle: "solid",
  }, */
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const VideosPendingNarrations = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    videos: state.video.list,
    excel: state.video.excelUrl,
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
  });

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/excel', payload: { values, enqueueSnackbar } });
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/pendingNarrations', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Videos pendientes de narración
          </Typography>
        </div>
      </Paper>

      <div className='relative max-w-screen-xl p-8 mx-auto'>
        <Typography className='m-0 text-white opacity-75' variant='h4' component='h3'>
        Total: {videoState.videos?.meta?.total}
        </Typography>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={videoState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={4}></StyledTableCell>
                <StyledTableCell colSpan={2}>Usuario que subió</StyledTableCell>
                <StyledTableCell colSpan={2}>Equipo que subió</StyledTableCell>
                <StyledTableCell colSpan={2}>Equipo de la jugada</StyledTableCell>
                <StyledTableCell colSpan={4}>Partido</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Video</StyledTableCell>
                <StyledTableCell>Tipo de Jugada</StyledTableCell>
                <StyledTableCell>Fecha de creación</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Fecha</StyledTableCell>
                <StyledTableCell>Id Cuenta</StyledTableCell>
                <StyledTableCell>Nombre Torneo</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videoState.videos?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={videoState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>
        <Link href={row.video?.url} target="_blank" rel="noopener">
          Video
        </Link>
      </StyledTableCell>
      <StyledTableCell>{PlayTypesEnum[row.type]}</StyledTableCell>
      <StyledTableCell>{format(new Date(row.created_at), 'dd/MM/yyyy')}</StyledTableCell>
      <StyledTableCell>{row.video?.upload_user?.id}</StyledTableCell>
      <StyledTableCell>{row.video?.upload_user?.email}</StyledTableCell>
      <StyledTableCell>{row.video?.v2_team.id  }</StyledTableCell>
      <StyledTableCell>{row.video?.v2_team.name}</StyledTableCell>
      <StyledTableCell>{row.video?.v2_team.id }</StyledTableCell>
      <StyledTableCell>{row.video?.v2_team.name }</StyledTableCell>
      <StyledTableCell>{row.video?.match_data?.id}</StyledTableCell>
      <StyledTableCell>{row.video?.match_data?.date ? format(new Date(row.video?.match_data?.date), 'dd/MM/yyyy H:mm') : ''}</StyledTableCell>
      <StyledTableCell>{row.video?.match_data?.v2_tournament?.v2_account_id}</StyledTableCell>
      <StyledTableCell>{row.video?.match_data?.v2_tournament?.name}</StyledTableCell>
    </StyledTableRow>
  );
}

export default VideosPendingNarrations;
