import axios from '../../axios';

const catalog = {
    state: {
        currency: null,
        accounts: [],
        locations: [],
        countries: [],
        states: [],
        cities: [],
        tournaments: [],
        teams: [],
        courts: [],
        users: [],
        cameraman: [],
        accountCameramen: [],
        languages: [],
        videoLanguages: [],
        currencies: [],
        accountTypes: [],
        sponsorAssets: {
            banner: [],
            watermark: [],
            advertisingImage: [],
            advertisingVideo: [],
        },
        error: null,
        status: '',
        message: '',
    },
    reducers: {
        accounts: (state) => ({
            ...state,
            accounts: [],
            status: 'waiting',
            error: null,
        }),
        locations: (state) => ({
            ...state,
            locations: [],
            status: 'waiting',
            error: null,
        }),
        countries: (state) => ({
            ...state,
            countries: [],
            states: [],
            cities: [],
            status: 'waiting',
            error: null,
        }),
        states: (state) => ({
            ...state,
            states: [],
            cities: [],
            status: 'waiting',
            error: null,
        }),
        cities: (state) => ({
            ...state,
            cities: [],
            status: 'waiting',
            error: null,
        }),
        tournaments: (state) => ({
            ...state,
            tournaments: [],
            status: 'waiting',
            error: null,
        }),
        teams: (state) => ({
            ...state,
            teams: [],
            status: 'waiting',
            error: null,
        }),
        sponsorAssets: (state) => ({
            ...state,
            sponsorAssets: [],
            status: 'waiting',
            error: null,
        }),
        courts: (state) => ({
            ...state,
            courts: [],
            status: 'waiting',
            error: null,
        }),
        accountTypes: (state) => ({
            ...state,
            accountTypes: [],
            status: 'waiting',
            error: null,
        }),
        users: (state) => ({
            ...state,
            users: [],
            status: 'waiting',
            error: null,
        }),
        cameraman: (state) => ({
            ...state,
            cameraman: [],
            status: 'waiting',
            error: null,
        }),
        languages: (state) => ({
            ...state,
            languages: [],
            status: 'waiting',
            error: null,
        }),

        videoLanguages: (state) => ({
            ...state,
            videoLanguages: [],
            status: 'waiting',
            error: null,
        }),

        currencies: (state) => ({
            ...state,
            currencies: [],
            status: 'waiting',
            error: null,
        }),

        currency: (state) => ({
            ...state,
            currency: {},
            status: 'waiting',
            error: null,
        }),

        accountCameramen: (state) => ({
            ...state,
            accountCameramen: [],
            status: 'waiting',
            error: null,
        }),
        accountsSuccess: (state, res) => ({
            ...state,
            status: 'received',
            accounts: res,
        }),
        locationsSuccess: (state, res) => ({
            ...state,
            status: 'received',
            locations: res,
        }),
        countriesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            countries: res,
        }),
        statesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            states: res,
        }),
        citiesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            cities: res,
        }),
        tournamentsSuccess: (state, res) => ({
            ...state,
            status: 'received',
            tournaments: res,
        }),
        teamsSuccess: (state, res) => ({
            ...state,
            status: 'received',
            teams: res,
        }),
        sponsorAssetsSuccess: (state, res) => ({
            ...state,
            status: 'received',
            sponsorAssets: res,
        }),
        courtsSuccess: (state, res) => ({
            ...state,
            status: 'received',
            courts: res,
        }),
        accountTypesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            accountTypes: res,
        }),
        usersSuccess: (state, res) => ({
            ...state,
            status: 'received',
            users: res,
        }),
        cameramanSuccess: (state, res) => ({
            ...state,
            status: 'received',
            cameraman: res,
        }),

        languagesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            languages: res,
        }),

        videoLanguagesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            videoLanguages: res,
        }),
        currenciesSuccess: (state, res) => ({
            ...state,
            status: 'received',
            currencies: res,
        }),
        currencySuccess: (state, res) => ({
            ...state,
            status: 'received',
            currency: res,
        }),

        accountCameramenSuccess: (state, res) => ({
            ...state,
            status: 'received',
            accountCameramen: res,
        }),
        
        failed: (state, error) => ({
            ...state,
            status: 'failed',
            error,
        }),
    },
    effects: (dispatch) => ({
        accounts(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/accounts').then((res) => {
                dispatch.catalog.accountsSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        locations(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/locations').then((res) => {
                dispatch.catalog.locationsSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        countries(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/countries').then((res) => {
                dispatch.catalog.countriesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        states(payload) {
            const { countryId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/states/' + countryId).then((res) => {
                dispatch.catalog.statesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        cities(payload) {
            const { stateId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/cities/' + stateId).then((res) => {
                dispatch.catalog.citiesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        tournaments(payload) {
            const { accountId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/tournaments/account/' + accountId).then((res) => {
                dispatch.catalog.tournamentsSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        teams(payload) {
            const { accountId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/teams/account/' + accountId).then((res) => {
                dispatch.catalog.teamsSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        courts(payload) {
            const { accountId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/courts/account/' + accountId).then((res) => {
                dispatch.catalog.courtsSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        accountTypes(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/account/types').then((res) => {
                dispatch.catalog.accountTypesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        users(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/users').then((res) => {
                dispatch.catalog.usersSuccess(res.data?.data?.map(({ id, email }) => ({ id, email })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        cameraman(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/cameramen').then((res) => {
                dispatch.catalog.cameramanSuccess(res.data?.data?.map(({ id, email }) => ({ id, email })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },

        extractors(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/extractors').then((res) => {
                dispatch.catalog.cameramanSuccess(res.data?.data?.map(({ id, email }) => ({ id, email })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        sponsorAssets(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/sponsors').then((res) => {
                dispatch.catalog.sponsorAssetsSuccess({
                    banner: res.data?.data.map((item) => ({ id: item.id, url: item.banner })),
                    watermark: res.data?.data.map((item) => ({ id: item.id, url: item.watermark })),
                    advertisingImage: res.data?.data.map((item) => ({ id: item.id, url: item.advertising })),
                    advertisingVideo: res.data?.data.map((item) => ({ id: item.id, url: item.video_advertising })),
                });
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },

        languages(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/languages').then((res) => {
                dispatch.catalog.languagesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },

        videoLanguages(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/video-languages').then((res) => {
                dispatch.catalog.videoLanguagesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        currencies(payload) {
            const { enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/currencies').then((res) => {
                dispatch.catalog.currenciesSuccess(res.data?.data?.map(({ id, name }) => ({ id, name })));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        currency(payload) {
            const { accountId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/currency/account/' + accountId).then((res) => {
                dispatch.catalog.currencySuccess(res.data?.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },

        accountCameramen(payload) {
            const { accountId, enqueueSnackbar } = payload;
            axios.get('/v2/catalogs/accounts/cameramen/' + accountId).then((res) => {
                dispatch.catalog.accountCameramenSuccess(res.data?.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.catalog.failed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
    })
}

export default catalog;