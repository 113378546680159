import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button,
  Select, InputLabel,FormControl,FormHelperText
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import SearchBar from '../SearchBar/SearchBar';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Wallet = () => {
  const { errors } = useForm({ submitFocusError: true });

  const teamWalletState = useSelector((state) => ({
    status: state.team.status,
    error: state.team.error,
    videos: state.team.list,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments,

  }));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    sport_place_id: '',
    search: '',
    tournament_id:''

  });
  const [videos, setVideos] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };


  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'team/excelWallet', payload: { values, enqueueSnackbar } });
  }

  const handleSportPlaceChange = (event) => {
    console.log(event);
    setPaginationParams((st) => ({...st, sport_place_id: event.target.value}));

    const sportPlaceId =event.target.value;
    dispatch({ type: 'sportPlace/activeTournaments2', payload: { sportPlaceId, enqueueSnackbar } });

  }

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'team/wallet', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() =>  {
    if (teamWalletState.videos) {

      if (teamWalletState.videos.data.length) {
        let all = [];
        let item;
        
       

        teamWalletState.videos.data.forEach((user) => {
          item = user;
          all.push(item);
        });
        setVideos(all);
      } else {
        setVideos([]);
      }
    }
  // eslint-disable-next-line
  }, [teamWalletState.videos])



  return (
    <main>
      {teamWalletState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Billetera de equipos
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 '>
       
        <FormControl error={errors.sport_place_id} fullWidth={true}>


          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {teamWalletState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl error={errors.tournament_id} fullWidth={true}>
            <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
            <Select 
              label='Torneo' 
              id='tournament_id' 
              native 
              onChange={(event) => { setPaginationParams((st) => ({...st, tournament_id: event.target.value})) }}
              name='tournament_id'>
                <option aria-label='Selecciona torneo' value='' />
              {teamWalletState.tournaments?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.tournament_id?.message}</FormHelperText>
        </FormControl>

        <div className='max-w-screen-xl  mx-auto flex space-x-4'>
          <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
        </div>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={teamWalletState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell >Id Equipo</StyledTableCell>
                <StyledTableCell >Nombre Equipo</StyledTableCell>
                <StyledTableCell >Cuenta</StyledTableCell>
                <StyledTableCell >Torneo</StyledTableCell>
                <StyledTableCell ># Videos</StyledTableCell>
                <StyledTableCell >Total</StyledTableCell>

              </TableRow>
            
            </TableHead>
            <TableBody>
              {videos.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  rowsPerPageOptions={[50]}
                  count={teamWalletState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={teamWalletState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.account?.name}</StyledTableCell>
      <StyledTableCell>{row.tournament?.name}</StyledTableCell>
      <StyledTableCell>{row.videos}</StyledTableCell>
      <StyledTableCell>{row.money}</StyledTableCell>
      
    </StyledTableRow>
  );
}

export default Wallet;
