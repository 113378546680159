import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Button, TextField, LinearProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import FileUpload from '../FileUpload/FileUpload';
import ImageIcon from '@material-ui/icons/Image';
import { navigate } from '@reach/router';
import { DialogsConfirm } from '..';


const defaultValues = {
  name: '-',
  lastname: '-',
  image: null,
  id:0,
  _method: 'PUT'


};

const CameramanFormEdit = () => {
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm({
    submitFocusError: true,
    defaultValues,
  });
  const { enqueueSnackbar } = useSnackbar();
  const formState = useSelector((state) => ({
    status: state.cameraman.status,
    error: state.cameraman.error,
  }));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const setFieldValue = (name, value) => {
    setValue(name, value);
  };

  // register custom fields
  useEffect(() => {
    register({ name: 'image' });
    return () => {
      unregister({ name: 'image' });
    };
  }, [register, unregister]);

  const onSubmit = (data) => {
    const values = setValuesForRequest(data);

    dispatch({ type: 'cameraman/update', payload: { values, enqueueSnackbar } });
  }

  const setValuesForRequest = (data) => {
    let values = Object.assign({}, data);

    return jsonToFormData(values);
  }

  const buildFormData = (formData, data, parentKey) => {
    if(data && typeof data === 'object' && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      })
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  const jsonToFormData = (data) => {
    const formData = new FormData();

    for(let [key, value] of Object.entries(data)) {
      buildFormData(formData, value, key);
    }

    return formData;
  }

  const handleCancel = () => {
    setOpen(false);
  }

  const handleConfirm = () => {
    setOpen(false);
    navigate('/cameramen/lists');
  }

  const handleClickCancel = () => {
    setOpen(true);
  }

  useEffect(() => {

    let url = window.location.href.split('/');


    let values = {
      id: url[url.length-1]
    }

    dispatch({ type: 'cameraman/showCameraman', payload: {values ,enqueueSnackbar,setFieldValue } });

   // eslint-disable-next-line
  }, []);
 

  return (
    <div className='max-w-screen-xl p-8 mx-auto'>
      <DevTool control={control} />
      {formState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
        Editar Camarografo
      </Typography>

      <DialogsConfirm
        open={open}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        title={'Cancelar'}
        message={'¿Deseas cancelar y volver a la lista?'}
        confirmText={'Aceptar'}
        cancelText={'Cancelar'} />

      <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        <Paper className='p-8 my-8 grid grid-cols-1 md:grid-cols-2 gap-6'>


        <input
            type='hidden'
            name='id'
            ref={register()}
          />

        <input
            type='hidden'
            name='_method'
            ref={register()}
          />

          <TextField
            label='Nombre(s)'
            name='name'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.name}
            helperText={errors.name && errors.name.message}
          />
          
          <TextField
            label='Apellido(s)'
            name='lastname'
            variant='outlined'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors.lastname}
            helperText={errors.lastname && errors.lastname.message}
          />

         
          <div>
            <FileUpload
              label='Imagen'
              id="image"
              icon={<ImageIcon className='w-12 h-12 mb-2' />}
              fileChange={setFieldValue}
              accept='image/*'
            />
          </div>

         
        </Paper>
      </fieldset>
        <footer className='p-8 mt-8 text-right'>
          <Button 
            type='button' 
            className='mr-8'
            onClick={handleClickCancel} >
            Cancelar
          </Button>

          <Button type='submit' variant='contained' color='primary'>
            Actualizar
          </Button>
        </footer>
      </form>
    </div>
  );
};

export default CameramanFormEdit;
