import React from 'react';
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

const sponsorshipTypes = [
  { value: 'single', name: 'Individual' },
  { value: 'team', name: 'Equipo' },
];
const sexTypes = [
  { value: 'female', name: 'Femenino' },
  { value: 'male', name: 'Masculino' },
];

const SponsorshipsGeneral = (props) => {
  const { register, errors, control } = props;

  return (
    <fieldset>
      <Paper className='p-8 my-8'>
        <div className='flex mb-8'>
          <TextField
            size='small'
            className='w-full mr-8'
            error={!!errors.usage_limit}
            label='Límite de usuarios*'
            name='usage_limit'
            type='number'
            variant='outlined'
            fullWidth
            helperText={errors.usage_limit && errors.usage_limit.message}
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
          />

          <FormControl variant='outlined' size='small' className='w-full mr-8'>
            <InputLabel htmlFor='sex'>Sexo</InputLabel>
            <Select label='Sexo' id='sex' native name='sex' inputProps={{ ref: register() }}>
              <option aria-label='None' value='' />
              {sexTypes.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl variant='outlined' size='small' className='w-full mr-8'>
            <InputLabel htmlFor='type'>Tipo de patrocionio</InputLabel>
            <Select
              id='type'
              label='Tipo de patrocionio'
              native
              name='type'
              inputProps={{ ref: register() }}
            >
              <option aria-label='None' value='' />
              {sponsorshipTypes.map((item) => (
                <option value={item.value} key={item.value}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className='flex'>
          <Controller
            as={<DatePicker />}
            control={control}
            name='date_from'
            className='mr-8'
            margin='normal'
            id='date_from'
            label='Desde'
            format='yyyy/MM/dd'
            inputVariant='outlined'
            size='small'
          />

          <Controller
            as={<DatePicker />}
            control={control}
            name='date_to'
            className='mr-8'
            margin='normal'
            id='date_to'
            label='Desde'
            format='yyyy/MM/dd'
            inputVariant='outlined'
            size='small'
          />
        </div>

        <div className='inline-flex flex-col'>
          <FormControlLabel
            control={<Checkbox inputRef={register} name='automatic_habilitation' />}
            label='Activar automáticamente'
          />

          <FormControlLabel
            control={<Checkbox inputRef={register} name='enabled' />}
            label='Activo'
          />
        </div>
      </Paper>
    </fieldset>
  );
};

SponsorshipsGeneral.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  control: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SponsorshipsGeneral;
