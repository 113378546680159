import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, Link, IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { PlayTypesEnum } from '../../enums';
import VideosAssignAnimation from './AssignWatermark';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ReporteAsignationWatermark = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    videos: state.video.list,
  }));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [videoToAssign, setVideoToAssign] = useState(null);
  const [refresh, setRefresh] = useState(true);
  
  const handleClose = () => {
    setOpen(false);
    setVideoToAssign(null);
  }

  useEffect(() => {
    if (refresh) {
      dispatch({ type: 'video/videosToAssignWatermark', payload: { enqueueSnackbar } });
    }
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

      <VideosAssignAnimation
        open={open}
        handleClose={handleClose}
        video={videoToAssign}
        setRefresh={setRefresh} />

      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Asignacion de marca de agua
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={6}></StyledTableCell>
                <StyledTableCell colSpan={2}>Comentarista</StyledTableCell>
                <StyledTableCell colSpan={2}>Usuario que subió</StyledTableCell>
                <StyledTableCell colSpan={5}>Partido</StyledTableCell>
              </TableRow>
              <TableRow>
              <StyledTableCell></StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Video</StyledTableCell>
                <StyledTableCell>Id de Rastreo</StyledTableCell>
                <StyledTableCell>Tipo de Jugada</StyledTableCell>
                <StyledTableCell>Fecha de creación</StyledTableCell>
                <StyledTableCell>Marca de agua</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Fecha</StyledTableCell>
                <StyledTableCell>Equipo A</StyledTableCell>
                <StyledTableCell>Equipo B</StyledTableCell>
                <StyledTableCell>Nombre Torneo</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videoState.videos?.data?.map((row, i) => <Row key={i} row={row} 
                setOpen={setOpen} setVideoToAssign={setVideoToAssign} setRefresh={setRefresh} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({ row, setOpen, setVideoToAssign, setRefresh }) => {
  const handleClickOpen = () => {
    setOpen(true);
    setVideoToAssign(row);
    setRefresh(false);
  }

  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>
        <IconButton 
          color='primary'
          aria-label='add coins to user'
          onClick={handleClickOpen}>
          <AssignmentIndIcon />
        </IconButton>
      </StyledTableCell>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>
        <Link href={row.url} target="_blank" rel="noopener">
          Video
        </Link>
      </StyledTableCell>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{PlayTypesEnum[row.type]}</StyledTableCell>
      <StyledTableCell>{format(new Date(row.created_at), 'dd/MM/yyyy')}</StyledTableCell>
      <StyledTableCell>{row.watermark?.name}</StyledTableCell>
      <StyledTableCell>{row.upload_user?.profile?.name}</StyledTableCell>
      <StyledTableCell>{row.upload_user?.email}</StyledTableCell>
      <StyledTableCell>{row.match_data?.date ? format(new Date(row.match_data?.date), 'dd/MM/yyyy H:mm') : ''}</StyledTableCell>
      <StyledTableCell>{row.match_data?.team_a_v2?.name}</StyledTableCell>
      <StyledTableCell>{row.match_data?.team_b_v2?.name}</StyledTableCell>
      <StyledTableCell>{row.match_data?.v2_tournament?.name}</StyledTableCell>
    </StyledTableRow>
  );
}

export default ReporteAsignationWatermark;
