import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';
import { navigate } from '@reach/router';

const team = {
  state: {
    list: null,
    error: null,
    status: '',
    excelUrl: null,
    teamsList: [],
    selectedTeam: null,
  },
  reducers: {
    teamsList: (state) => ({
      ...state,
      status: 'waiting',
      teamsList: [],
      selectedTeam: null,
    }),
    teamsListSuccess: (state, res) => ({
      ...state,
      status: 'received',
      teamsList: res,
    }),
    getTeam: (state) => ({
      ...state,
      status: 'waiting',
      selectedTeam: null,
    }),
    getTeamSuccess: (state, res) => ({
      ...state,
      status: 'received',
      selectedTeam: res,
    }),
    failed: (state, error) => ({
      ...state,
      status: 'failed',
      teamsList: [],
      error,
    }),
    createTeam: (state) => ({ ...state, status: 'waiting', error: null }),
    createTeamSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,
    }),
    updateTeam: (state) => ({ ...state, status: 'waiting', error: null }),
    updateTeamSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,
        selectedTeam: null,
    }),
    deleteTeam: (state) => ({ ...state, status: 'waiting', error: null }),
    deleteTeamSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,
    }),
    // Count users by team with sport place info
    withSportPlace: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Download excel with teams info
    excel: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    //julio
    wallet: (state) => ({ ...state, status: 'waiting', error: null }),
    
  },
  effects: (dispatch) => ({
    withSportPlace(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/teams-sport-place', values).then((res) => {
        dispatch.team.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    wallet(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/teams-wallet', values).then((res) => {
        dispatch.team.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    activity(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/teams-activity', values).then((res) => {
        dispatch.team.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excel(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/teams-sport-place', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.team.excelSuccess(url);
        downloadExcel(url, 'Equipos.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    excelWallet(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/teams-wallet', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.team.excelSuccess(url);
        downloadExcel(url, 'wallet_teams.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelActivity(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/teams-activity', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.team.excelSuccess(url);
        downloadExcel(url, 'teams_activity.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.team.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    teamsList(payload) {
      const { values, enqueueSnackbar } = payload;
      ax.get('/v2/teams', values).then((res) => {
          dispatch.team.teamsListSuccess(res.data);
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.team.failed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
    createTeam(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/v2/teams', values).then((res) => {
          enqueueSnackbar('Equipo Creado', { variant: 'success' });
          dispatch.team.createTeamSuccess(res);
          navigate('./');
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.team.failed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
    getTeam({ id, enqueueSnackbar }) {
      ax.get(`/v2/teams/${id}`).then((res) => {
          dispatch.team.getTeamSuccess(res.data.data);
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.tournament.failed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
    updateTeam(payload) {
      const { values, id, enqueueSnackbar } = payload;

      ax.put(`/v2/teams/${id}`, values).then((res) => {
          enqueueSnackbar('Equipo Editado', { variant: 'success' });
          dispatch.team.updateTeamSuccess(res);
          navigate('../');
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.team.failed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
    deleteTeam(payload) {
      const { id, enqueueSnackbar, handleClose,setPaginationParams } = payload;

      ax.delete(`/v2/teams/${id}`).then((res) => {
          dispatch.team.deleteTeamSuccess(res.data);
          enqueueSnackbar('Equipo eliminado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.team.deleteTeamFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
  }),
};

export default team;
