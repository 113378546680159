import React, { useEffect } from 'react';
import {
  Typography, Paper, LinearProgress, Select, InputLabel,
  FormControl, FormHelperText, Button, TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    '& .MuiFormControl-root': {
      width: '25ch',
    },
  },
}));

const SportPlacesAssignCameraMan = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, reset } = useForm({ submitFocusError: true });
  const assignState = useSelector((state) => ({
    status: state.sportPlace.status,
    error: state.sportPlace.error,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments,
  }));
  const dispatch = useDispatch();

  const onSubmitHandler = (values) => {
    dispatch({ type: 'sportPlace/assignCameraman', payload: { values, enqueueSnackbar } });
  };

  const handleChangeSportPlace = (event) => {
    const sportPlaceId = event.target.value;
    dispatch({ type: 'sportPlace/activeTournaments', payload: { sportPlaceId, enqueueSnackbar } });
  };

  useEffect(() => {
    if (assignState.status === 'assigned')
      reset({});
    // eslint-disable-next-line
  }, [assignState.status]);

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      {assignState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Asignar camarógrafo
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <form className={classes.root} onSubmit={handleSubmit(onSubmitHandler)}>
          <FormControl error={errors.sport_place_id} fullWidth={true}>
            <InputLabel htmlFor='sport_place_id'>Complejo</InputLabel>
            <Select 
              label='Complejo' 
              id='sport_place_id' 
              native 
              name='sport_place_id'
              onChange={handleChangeSportPlace}
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                }),
              }}>
                <option aria-label='Selecciona complejo' value='' />
              {assignState.sportPlaces?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.sport_place_id?.message}</FormHelperText>
          </FormControl>

          <FormControl error={errors.tournament_id} fullWidth={true}>
            <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
            <Select 
              label='Torneo' 
              id='tournament_id' 
              native 
              name='tournament_id'>
                <option aria-label='Selecciona torneo' value='' />
              {assignState.tournaments?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.tournament_id?.message}</FormHelperText>
          </FormControl>

          <TextField
            size='small'
            label='Email'
            name='email'
            fullWidth
            inputProps={{
              ref: register({ required: 'El campo es requerido.' }),
            }}
            error={!!errors['email']}
            helperText={errors['email'] && errors['email'].message}
          />

          <div className={classes.root}>
            <Button onClick={reset} color='secondary' variant="outlined">
              Cancelar
            </Button>
            <Button
              color='primary'
              variant="contained"
              disabled={assignState.status === 'waiting'}
              type='submit'
            >
              Asignar
            </Button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default SportPlacesAssignCameraMan;
