import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button,Dialog,DialogActions,DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BankAccounts = () => {
  const [open, setOpen] = useState(false);

  const userBankState = useSelector((state) => ({
    status: state.userBank.status,
    error: state.userBank.error,
    users: state.userBank.list,

  }));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,

  });
  const [videos, setVideos] = useState([]);
  const [sendUser, setSendUser] = useState(0);


  const handleAprovedOrRejectedClick = (status) =>{
    let values = {
      user: sendUser,
      status : status
    }
    dispatch({ type: 'userBank/updateStatusAccount', payload: { values, enqueueSnackbar,handleClose,setPaginationParams } });

  }

  

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };


  const handleExcelClick = () => {
    const values = {params: paginationParams}
    setOpen(false)

    dispatch({ type: 'userBank/excel', payload: { values, enqueueSnackbar } }).then(() => {
      setPaginationParams((st) => ({...st, page: 1}));
    });

    setPaginationParams((st) => ({...st, page: 1}));

  }

  const handleOpen = (user) => {
    setSendUser(user);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const buttonStatus = (status,user) => {


    if(status === 'pending'){
       
      return (<Button variant='contained' color='primary' onClick={() => handleOpen(user)} type='button'  >Validar Tarjeta</Button>)
    }

    return '';


  }

  const Row = ({row}) => {
    return (
      <StyledTableRow key={row.id}>
        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.profile?.name}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.bank?.bank}</StyledTableCell>
        <StyledTableCell>{row.bank?.name+' '+ row.bank?.first_lastname+ ' '+ row.bank?.second_lastname}</StyledTableCell>
        <StyledTableCell>{row.bank?.interbank_key}</StyledTableCell>
        <StyledTableCell>{TableStatus(row.bank?.status)}</StyledTableCell>
        <StyledTableCell>{buttonStatus(row.bank?.status,row.id) }</StyledTableCell>
        
      </StyledTableRow>
    );
  }


  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'userBank/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() =>  {
    if (userBankState.users) {

      if (userBankState.users.data.length) {
        let all = [];
        let item;
        
       

        userBankState.users.data.forEach((user) => {
          item = user;
          all.push(item);
        });
        setVideos(all);
      } else {
        setVideos([]);
      }
    }
  // eslint-disable-next-line
  }, [userBankState.users])



  return (
    <main>
      {userBankState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Cuentas bancarias de usuarios
          </Typography>
        </div>
      </Paper>


      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={userBankState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell >Id</StyledTableCell>
                <StyledTableCell >Nombre</StyledTableCell>
                <StyledTableCell >Correo</StyledTableCell>
                <StyledTableCell >Banco</StyledTableCell>
                <StyledTableCell >Titular cuenta bancaria</StyledTableCell>
                <StyledTableCell >Clabe</StyledTableCell>
                <StyledTableCell >Estatus</StyledTableCell>
                <StyledTableCell >Acciones</StyledTableCell>

              </TableRow>
            
            </TableHead>
            <TableBody>
              {videos.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  rowsPerPageOptions={[50]}
                  count={userBankState.users?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={userBankState.users?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Estatus de cuenta bancaria"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea Aprovar la cuenta bancaria?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleAprovedOrRejectedClick('rejected')} variant="outlined" color="secondary">Rechazar</Button>
            <Button onClick={() => handleAprovedOrRejectedClick('aproved')} variant="outlined" color="primary" autoFocus>
              Aprobar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  )
}

const TableStatus = (status) => {
    let statusSpanish = 'Pendiente';
    switch (status) {
      case 'aproved':
        statusSpanish = 'Aprobada';
        
      break;
    
      case 'rejected':
        statusSpanish = 'Rechazada';
        
      break;

      default:
      break;
    }

    return statusSpanish;

}





export default BankAccounts;
