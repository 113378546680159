import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, Link, IconButton,FormControl,Select,FormHelperText,InputLabel,TableFooter,TablePagination
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import VideosAssignAnimation from './Banner';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { useForm } from 'react-hook-form';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ReporteAsignationBanner = () => {
  const { errors } = useForm({ submitFocusError: true });

  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    videos: state.video.list,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments,
  }));
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [videoToAssign, setVideoToAssign] = useState(null);
  const [refresh, setRefresh] = useState(true);

  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    tournament_id: '',
    v2_account_id: '',
  }); 
  
  const handleClose = () => {
    setOpen(false);
    setVideoToAssign(null);
  }

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };




  const handleSportPlaceChange = (event) => {
    event.persist();
    setPaginationParams((st) => ({...st, v2_account_id: event.target.value}));

    const sportPlaceId =event.target.value;
    dispatch({ type: 'sportPlace/activeTournaments2', payload: { sportPlaceId, enqueueSnackbar } });

  }

  const handleTournamentChange  = (event) => {
    event.persist();

    setPaginationParams((st) => ({...st, tournament_id: event.target.value}))
  }

  useEffect(() => {
    if (refresh) {
      const values = {params: paginationParams}

      dispatch({ type: 'video/matchesBannerList', payload: { values,enqueueSnackbar } });
    }
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

      <VideosAssignAnimation
        open={open}
        handleClose={handleClose}
        video={videoToAssign}
        setRefresh={setRefresh} />

      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Asignacion de banner por partido
          </Typography>
        </div>
      </Paper>
      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 '>
       
        <FormControl error={errors.sport_place_id} fullWidth={true}>


          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {videoState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl error={errors.tournament_id} fullWidth={true}>
            <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
            <Select 
              label='Torneo' 
              id='tournament_id' 
              native 
              onChange={handleTournamentChange}
              name='tournament_id'>
                <option aria-label='Selecciona torneo' value='' />
              {videoState.tournaments?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.tournament_id?.message}</FormHelperText>
        </FormControl>
       
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              
              <TableRow>
              <StyledTableCell></StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Fecha Partido</StyledTableCell>
                <StyledTableCell>Cuenta</StyledTableCell>
                <StyledTableCell>Torneo</StyledTableCell>
                <StyledTableCell>Equipo A</StyledTableCell>
                <StyledTableCell>Equipo B</StyledTableCell>
                <StyledTableCell>Imagen</StyledTableCell>
                <StyledTableCell>Link</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videoState.videos?.data?.map((row, i) => <Row key={i} row={row} 
                setOpen={setOpen} setVideoToAssign={setVideoToAssign} setRefresh={setRefresh} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  rowsPerPageOptions={[50]}
                  count={videoState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({ row, setOpen, setVideoToAssign, setRefresh }) => {
  const handleClickOpen = () => {
    setOpen(true);
    setVideoToAssign(row);
    setRefresh(false);
  }

  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>
        <IconButton 
          color='primary'
          aria-label='add coins to user'
          onClick={handleClickOpen}>
          <AssignmentIndIcon />
        </IconButton>
      </StyledTableCell>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{format(new Date(row.date_mexico), 'dd/MM/yyyy')}</StyledTableCell>
      <StyledTableCell>{row.v2_tournament?.account?.name}</StyledTableCell>
      <StyledTableCell>{row.v2_tournament?.name}</StyledTableCell>
      <StyledTableCell>{row.team_a_v2?.name}</StyledTableCell>
      <StyledTableCell>{row.team_b_v2?.name}</StyledTableCell>

      <StyledTableCell>
        {row.banner && (<Link href={row.banner?.url} target="_blank" rel="noopener">
          Imagen
        </Link>)}
      </StyledTableCell>
      <StyledTableCell>{row.banner && (<Link href={row.banner?.link} target="_blank" rel="noopener">
          Link
        </Link>)}</StyledTableCell>

    </StyledTableRow>
  );
}

export default ReporteAsignationBanner;
