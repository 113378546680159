import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination,
  IconButton, Collapse, Box, Link, RadioGroup,
  FormControlLabel, Radio,Button,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import SearchBar from '../SearchBar/SearchBar';
import { CountriesEnum, VideoStatusesEnum } from '../../enums';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const AuditionsList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const auditionState = useSelector((state) => ({
    status: state.audition.status,
    error: state.audition.error,
    list: state.audition.list
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    search: '',
    register: '',
    country: '',
  }); 
  const [regType, setRegType] = useState('');
  const [country, setCountry] = useState('');

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleChangeType = (event) => {
    const val = event.target.value;
    setRegType(val);
    setPaginationParams((st) => ({...st, register: val}));
  };

  const handleChangeCountry = (event) => {
    const val = event.target.value;
    setCountry(val);
    setPaginationParams((st) => ({...st, country: val}));
  };

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'audition/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'audition/excelReport', payload: { values, enqueueSnackbar } });
  }


  return (
    <main>
      {auditionState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Audiciones
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white'>
        <RadioGroup aria-label="types" row name="register" value={regType} onChange={handleChangeType}>
          <FormControlLabel value="" control={<Radio />} label="Todas" />
          <FormControlLabel value="complete" control={<Radio />} label="Completadas" />
          <FormControlLabel value="incomplete" control={<Radio />} label="Incompletas" />
        </RadioGroup>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white'>
        <RadioGroup aria-label="types" row name="register" value={country} onChange={handleChangeCountry}>
          <FormControlLabel value="" control={<Radio />} label="Todos" />
          <FormControlLabel value="mexico" control={<Radio />} label="México" />
          <FormControlLabel value="argentina" control={<Radio />} label="Argentina" />
          <FormControlLabel value="ingles" control={<Radio />} label="Ingles" />
        </RadioGroup>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={auditionState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Teléfono</StyledTableCell>
                <StyledTableCell>Edad</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell>País</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditionState.list?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={auditionState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={auditionState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  const [open, setOpen] = React.useState(false);
  let survey = row.register_activity ? JSON.parse(row.register_activity.time_dedicated) : null;

  return (
    <React.Fragment>
      <StyledTableRow>
        <TableCell>
          {
            row.register_activity &&
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          </TableCell>
        <StyledTableCell>{row.name} {row.lastname}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.cell_phone}</StyledTableCell>
        <StyledTableCell>{row.year_old}</StyledTableCell>
        <StyledTableCell>{row.city}</StyledTableCell>
        <StyledTableCell>{row.country && CountriesEnum[row.country]}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" component="div">
                Videos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Video</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.auditions.map((video) => (
                    <TableRow key={video.id}>
                      <TableCell component="th" scope="row">
                        {video.status === 'finalized' ?
                          <Link href={video.url} target="_blank" rel="noopener">
                          Video {video.video_audition_id}
                        </Link>
                          : '-'
                        }
                      </TableCell>
                      <TableCell>{video.status && VideoStatusesEnum[video.status]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </Box>
              <Box margin={2}>
              <Typography variant="h6" component="div" className='mb-2'>
                Encuesta
              </Typography>
              {
                row.register_activity &&
                <>
                  <Typography variant="body1" component="div">
                    ¿Qué días de la semana podrías dedicarle tiempo a narrar partidos y cuántas horas por día?
                  </Typography>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align='center'>Lunes</StyledTableCell>
                        <StyledTableCell align='center'>Martes</StyledTableCell>
                        <StyledTableCell align='center'>Miércoles</StyledTableCell>
                        <StyledTableCell align='center'>Jueves</StyledTableCell>
                        <StyledTableCell align='center'>Viernes</StyledTableCell>
                        <StyledTableCell align='center'>Sábado</StyledTableCell>
                        <StyledTableCell align='center'>Domingo</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align='center'>{survey.lunes}</StyledTableCell>
                        <StyledTableCell align='center'>{survey.martes}</StyledTableCell>
                        <StyledTableCell align='center'>{survey.miercoles}</StyledTableCell>
                        <StyledTableCell align='center'>{survey.jueves}</StyledTableCell>
                        <StyledTableCell align='center'>{survey.viernes}</StyledTableCell>
                        <StyledTableCell align='center'>{survey.sabado}</StyledTableCell>
                        <StyledTableCell align='center'>{survey.domingo}</StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                  <Typography variant="body1" component="div" className='mt-2 mb-2'>
                    Si este fuese un trabajo de tiempo completo (8 horas diarías de Lunes a Viernes), Cuánto consideras que debería ser el salario mensual?
                    <Box fontWeight="fontWeightBold" className='pl-2'>{row.register_activity.salary} Pesos</Box>
                  </Typography>

                  <Typography variant="body1" component="div" className='mt-2 mb-2'>
                    Después de haber vivido la experiencia. ¿Cuánto crees que te tomaría narrar cada video? En tu cálculo incluye el tiempo que te toma ver el video para saber de qué se trata, analizar los datos de la jugada y grabar (no siempre te va a salir bien a la primera).
                    <Box fontWeight="fontWeightBold" className='pl-2'>{row.register_activity.time_narration} Minutos</Box>
                  </Typography>

                  <Typography variant="body1" component="div" className='mt-2 mb-2'>
                    ¿Te gustaria dejar un comentario o sugerencia?
                    <Box fontWeight="fontWeightBold" className='pl-2'>{row.register_activity.comments}</Box>
                  </Typography>
                </>
              }
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
}

export default AuditionsList;
