import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Fab,Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import AddIcon from '@material-ui/icons/Add';

import {
  Create as CreateIcon
} from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const CommentatorsSimpleList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const commentatorState = useSelector((state) => ({
    status: state.commentator.status,
    error: state.commentator.error,
    list: state.commentator.list
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
  }); 

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'commentator/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


  return (
    <main>
      {commentatorState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
      <div className='relative max-w-screen-xl p-8 mx-auto'>
        <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
          Narradores / Relatores
        </Typography>

        <Fab
          className='absolute right-0 outline-none'
          color='primary'
          aria-label='add'
          onClick={() => navigate('/commentators/no-professional/new')}
        >
          <AddIcon />
        </Fab>
      </div>
    </Paper>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Teléfono</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commentatorState.list?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={commentatorState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={commentatorState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <React.Fragment>
      <StyledTableRow>
      <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.profile?.name} {row.profile?.lastname}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.profile?.code_country} {row.profile?.cell_phone}</StyledTableCell>
        <StyledTableCell>{row.profile?.city}</StyledTableCell>
        <StyledTableCell>
        <Button variant='contained'  type='button' className='mr-1' onClick={() => navigate('/commentators/no-professional/edit/'+row.id)}  size="small" style={{ background:"#F9CA2D" }}>
              <CreateIcon className='text-white' />
        </Button>
        </StyledTableCell>

        
      </StyledTableRow>
    </React.Fragment>
  );
}

export default CommentatorsSimpleList;
