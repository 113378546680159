import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UploadThumbnail from '../UploadThumbnail/UploadThumbnail';
import ExistentResSelector, { RESOURCE_TYPES } from '../Sponsors/ExistentResSelector';

const NewOrExistentResSwitch = ({
    id,
    label,
    fileChange,
    accept,
    error,
    existentItems,
    type,
    defaultValue,
}) => {
    const [isChecked] = useState(false);
    
    return (
        <div className="relative w-full">
            <div className="w-full flex justify-between items-center">
                <p className={`${error && 'text-red-600'}`}>{label}</p>
            </div>
            {!isChecked ?
                <UploadThumbnail
                    id={id}
                    editable
                    accept={accept}
                    fileChange={fileChange}
                    defaultValue={defaultValue}
                    type={type}
                /> :
                <ExistentResSelector resources={existentItems} type={type} onChange={(fileUrl) => fileChange(null, fileUrl)} />
            }

            {error && <span className="text-red-600 ml-3 text-xs">{error}</span>}
        </div>
    );
}

NewOrExistentResSwitch.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    fileChange: PropTypes.func,
    accept: PropTypes.string,
    error: PropTypes.string,
    existentItems: PropTypes.array,
    type: PropTypes.oneOf(RESOURCE_TYPES),
    defaultValue: PropTypes.string,
}

NewOrExistentResSwitch.defaultProps = {
    id: '',
    label: '',
    accept: 'image/*',
    fileChange: () => {},
    error: '',
    existentItems: [],
    type: RESOURCE_TYPES.IMAGE,
    defaultValue: null,
}

export default NewOrExistentResSwitch;