import axios from '../../axios';

const survey = {
  state: {
    list: null,
    error: null,
    status: '',
    message: ''
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    

  },
  effects: (dispatch) => ({
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      axios.get('/reports/survey-suscriptions', values)
        .then((res) => {
          dispatch.survey.listSuccess(res.data);
        })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.survey.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
   
   
  })
};

export default survey;
