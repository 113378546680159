import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate, useParams, Link } from '@reach/router';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Dialog,DialogActions,DialogContent,
  DialogContentText, DialogTitle, Button, Fab, Breadcrumbs, IconButton, Tooltip
} from '@material-ui/core';
import {
    Create as CreateIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    ArrowBack as ArrowBackIcon,
    Storage as StorageIcon
} from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row, onDeleteClick, accountId }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.id}</StyledTableCell>
            <StyledTableCell>{row.name}</StyledTableCell>
            <StyledTableCell>
                <Tooltip title="Equipos">
                    <Button variant='contained'  type='button' className='mr-1' onClick={() => navigate(`/accounts/${accountId}/tournaments/${row.id}/teams`)}  size="small" style={{ background:"#4caf50" }}>
                        <StorageIcon className='text-white' />
                    </Button>
                </Tooltip>
                <Button variant='contained'  type='button' className='mr-1' onClick={() => navigate(`/accounts/${accountId}/tournaments/edit/${row.id}/`)}  size="small" style={{ background:"#F9CA2D" }}>
                <CreateIcon className='text-white' />
                </Button>
                <Button onClick={() => onDeleteClick(row.id)} variant='contained' size="small" style={{ background:"red" }}   >
                    <DeleteIcon className="text-white"  />
                </Button>
          </StyledTableCell>
        </StyledTableRow>
    );
}

function Tournaments() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
        account_id: null,
    });
    const [tournamentIdToDelete, setTournamentIdToDelete] = useState(null);
    const [isDeleiting, setIsDeleiting] = useState(false);
    const tournamentState = useSelector((state) => ({
        status: state.tournament.status,
        error: state.tournament.error,
        tournaments: state.tournament.list,
    }));
    const { accountId } = useParams();
    const account = useSelector((state) => state.account.selectedAccount);

    const handleChangePage = (event, newPage) => {
        setPaginationParams((st) => ({...st, page: newPage+1}));
    };

    const handleDeleteClick = (id) => {
        setTournamentIdToDelete(id);
    }
    
    const confirmDelete = () => {
        setIsDeleiting(true);
        dispatch({ type: 'tournament/delete', payload: {
            id: tournamentIdToDelete,
            enqueueSnackbar,
            handleClose: () => {
                setTournamentIdToDelete(null);
                setIsDeleiting(false);
            },
            setPaginationParams
        }});
    }

    useEffect(() => {
        if (paginationParams.account_id) {
            const values = {params: paginationParams}
            dispatch({ type: 'tournament/list', payload: { values, enqueueSnackbar } });
        }
        // eslint-disable-next-line
    }, [paginationParams]);

    useEffect(() => {
        setPaginationParams((currentState) => ({
            ...currentState,
            account_id: accountId
        }))
        dispatch({ type: 'account/get', payload: { id: accountId }});
        // eslint-disable-next-line
    }, [accountId]);

    return (
        <main>
            {tournamentState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <Dialog open={Boolean(tournamentIdToDelete)} onClose={() => setTournamentIdToDelete(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Eliminar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea eliminar el siguiente registro?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTournamentIdToDelete(null)}>Cancelar</Button>
                    <Button onClick={() => confirmDelete()} variant="outlined" color="primary" autoFocus disabled={isDeleiting}>
                        {isDeleiting ? 'Eliminando...' : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper elevation={0}>
                <div className='relative max-w-screen-xl p-8 mx-auto'>
                    {account && <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                        <Link color="inherit" to="/accounts">
                            Cuentas ({account?.account?.name})
                        </Link>
                        <Typography color="textPrimary">Torneos</Typography>
                    </Breadcrumbs>}

                    <div className="flex items-center gap-2">
                        <IconButton aria-label="delete" onClick={() => navigate('/accounts')}>
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                            Torneos
                        </Typography>
                    </div>

                    <Fab
                        style={{transform: 'translateY(50%)'}}
                        className='absolute right-0 bottom-0 outline-none'
                        color='primary'
                        aria-label='add'
                        onClick={() => navigate(`/accounts/${accountId}/tournaments/new`)}
                    >
                        <AddIcon />
                    </Fab>
                </div>
                
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Acciones</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tournamentState.tournaments?.data?.map((row, i) => <Row key={i} row={row} onDeleteClick={handleDeleteClick} accountId={accountId} />)}
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[50]}
                                count={tournamentState.tournaments?.meta?.total || 0}
                                page={paginationParams.page-1}
                                onChangePage={handleChangePage}
                                rowsPerPage={tournamentState.tournaments?.meta?.per_page || 50}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </main>
    );
}

export default Tournaments;