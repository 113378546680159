import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, FormControl,
  InputLabel, Select,FormHelperText,Button,IconButton,Collapse,Box,Link
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const MatchesViewsList = () => {

  const { errors } = useForm({ submitFocusError: true });

  const [ setReturnVideoId] = useState(null);


  const { enqueueSnackbar } = useSnackbar();
  const auditionState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    list: state.video.list,
    sportPlaces: state.sportPlace.list,
    tournaments: state.sportPlace.tournaments,
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    tournament_id: '',
    v2_account_id: '',
  }); 

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };




  const handleSportPlaceChange = (event) => {
    event.persist();
    setPaginationParams((st) => ({...st, v2_account_id: event.target.value}));

    const sportPlaceId =event.target.value;
    dispatch({ type: 'sportPlace/activeTournaments2', payload: { sportPlaceId, enqueueSnackbar } });

  }

  const handleTournamentChange  = (event) => {
    event.persist();

    setPaginationParams((st) => ({...st, tournament_id: event.target.value}))
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/matchesViewList', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

    const handleReturnVideoClick = (id) => {
     setReturnVideoId(id);
    }

   

    const handleExcelClick = () => {
        const values = {params: paginationParams}
        dispatch({ type: 'video/excelViewMatch', payload: { values, enqueueSnackbar } });
    }


  return (
    <main>


      {auditionState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Vista Por Partido 
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 '>
       
        <FormControl error={errors.sport_place_id} fullWidth={true}>


          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {auditionState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl error={errors.tournament_id} fullWidth={true}>
            <InputLabel htmlFor='tournament_id'>Torneo</InputLabel>
            <Select 
              label='Torneo' 
              id='tournament_id' 
              native 
              onChange={handleTournamentChange}
              name='tournament_id'>
                <option aria-label='Selecciona torneo' value='' />
              {auditionState.tournaments?.data.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>
            <FormHelperText>{errors.tournament_id?.message}</FormHelperText>
        </FormControl>
       
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        

        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={auditionState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>IPS</StyledTableCell>
                <StyledTableCell>Videos</StyledTableCell>
                <StyledTableCell>Id Partido</StyledTableCell>
                <StyledTableCell>Fecha Partido</StyledTableCell>
                <StyledTableCell>Cuenta</StyledTableCell>
                <StyledTableCell>Torneo</StyledTableCell>
                <StyledTableCell>Equipo A</StyledTableCell>
                <StyledTableCell>Equipo B</StyledTableCell>
                <StyledTableCell>Unique viewers (unique ip/device)</StyledTableCell>
                <StyledTableCell>Total views de videos de partido.</StyledTableCell>
                
                
              </TableRow>
            </TableHead>
            <TableBody>
              {auditionState.list?.data?.map((row, i) => <Row key={i} handleReturnVideoClick={handleReturnVideoClick} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={auditionState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={auditionState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  const [open, setOpen] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow>

        <TableCell>
            {
              row.unique_ip &&
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            }
        </TableCell>

        <TableCell>
            {
              row.videos &&
              <IconButton aria-label="expand row" size="small" onClick={() => setOpenVideo(!openVideo)}>
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            }
        </TableCell>
        <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.date_mexico}</StyledTableCell>
        <StyledTableCell>{row.v2_tournament?.account?.name}</StyledTableCell>
        <StyledTableCell>{row.v2_tournament?.name}</StyledTableCell>
        <StyledTableCell>{row.team_a_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.team_b_v2?.name}</StyledTableCell>
        <StyledTableCell>{row.unique_viewers}</StyledTableCell>
        <StyledTableCell>{row.total_views}</StyledTableCell>
      </StyledTableRow>

      <StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" component="div">
                Ips
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>IP</TableCell>
                    <TableCell># Vistas</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.unique_ip.map((map) => (
                    <TableRow key={map}>
                      <TableCell component="th" scope="row">
                         {map[0].visitor}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {map.length}
                         
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </Box>
             
          </Collapse>
        </TableCell>
      </StyledTableRow>

      <StyledTableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openVideo} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h6" component="div">
                Videos
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Video</TableCell>
                    <TableCell>Url Video</TableCell>
                    <TableCell>Unique viewers (unique ip/device)</TableCell>
                    <TableCell>Total views de videos.</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.videos2.map((map) => (
                    <TableRow key={map}>
                      <TableCell component="th" scope="row">
                         {map.id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link href={map.url} target="_blank" rel="noopener">
                          Video {map.id}
                        </Link>
                         
                      </TableCell>
                      <TableCell component="th" scope="row">
                         {map.unique_viewers}
                      </TableCell>
                      <TableCell component="th" scope="row">
                         {map.total_views}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </Box>
             
          </Collapse>
        </TableCell>
      </StyledTableRow>
      
    </React.Fragment>
  );
}

export default MatchesViewsList;
