import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination,
  Link, Button, Select, InputLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

const url = process.env.REACT_APP_REPES_URL;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const VideosViews = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    videos: state.video.views,
    sportPlaces: state.sportPlace.list,
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
    sport_place_id: '',
    type_user: 'all',
  });

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/excelViews', payload: { values, enqueueSnackbar } });
  }

  const handleSportPlaceChange = (event) => {
    setPaginationParams((st) => ({...st, sport_place_id: event.target.value}));
  }

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/views', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Vistas por video
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {videoState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </Paper>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={videoState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
            <TableRow>
                <StyledTableCell colSpan={7}></StyledTableCell>
                <StyledTableCell colSpan={2}>Cuenta</StyledTableCell>
                <StyledTableCell colSpan={2}>Torneo</StyledTableCell>
                <StyledTableCell colSpan={4}>Usuario que subió</StyledTableCell>
                <StyledTableCell colSpan={2}>Equipo de la jugada</StyledTableCell>
                <StyledTableCell colSpan={2}>Partido</StyledTableCell>
                <StyledTableCell colSpan={9}></StyledTableCell>

              </TableRow>
              <TableRow>
                <StyledTableCell>VideoHighlight Id</StyledTableCell>
                <StyledTableCell>Video</StyledTableCell>
                <StyledTableCell>Total de vistas</StyledTableCell>
                <StyledTableCell>Vistas usuarios de la Cuenta</StyledTableCell>
                <StyledTableCell>Vistas usuarios fuera de la Cuenta</StyledTableCell>
                <StyledTableCell>Vistas anónimas</StyledTableCell>
                <StyledTableCell>Fecha de VH</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Teléfono</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Fecha</StyledTableCell>
                <StyledTableCell>Tipo Jugada</StyledTableCell>
                <StyledTableCell>Like</StyledTableCell>
                <StyledTableCell>Share</StyledTableCell>
                <StyledTableCell>Descargas</StyledTableCell>
                <StyledTableCell>Descargas Para ti</StyledTableCell>
                <StyledTableCell>Me diviertes</StyledTableCell>
                <StyledTableCell>Shares directamente monetizables</StyledTableCell>
                <StyledTableCell># de usuarios que compartieron video</StyledTableCell>
                <StyledTableCell>Tipo de Video</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {videoState.videos?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={videoState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow>
      <StyledTableCell>{row.vh_id}</StyledTableCell>
      <StyledTableCell>
        <Link href={url+row.video_id} target="_blank" rel="noopener">
          {url+row.video_id}
        </Link>
      </StyledTableCell>
      <StyledTableCell>{row.total_views}</StyledTableCell>
      <StyledTableCell>{row.user_sport_place}</StyledTableCell>
      <StyledTableCell>{row.user_no_sport_place}</StyledTableCell>
      <StyledTableCell>{row.anonymous}</StyledTableCell>
      <StyledTableCell>{row.date_vh ? format(new Date(row.date_vh), 'dd/MM/yyyy') : ''}</StyledTableCell>
      <StyledTableCell>{row.id_sport_place}</StyledTableCell>
      <StyledTableCell>{row.name_sport_place}</StyledTableCell>
      <StyledTableCell>{row.id_tournament}</StyledTableCell>
      <StyledTableCell>{row.name_tournament}</StyledTableCell>
      <StyledTableCell>{row.upload_user?.id}</StyledTableCell>
      <StyledTableCell>{row.upload_user?.email}</StyledTableCell>
      <StyledTableCell>{row.upload_user?.profile?.name}</StyledTableCell>
      <StyledTableCell>{row.upload_user?.cell_phone}</StyledTableCell>
      <StyledTableCell>{row.played_team?.id}</StyledTableCell>
      <StyledTableCell>{row.played_team?.name}</StyledTableCell>
      <StyledTableCell>{row.match_data?.id}</StyledTableCell>
      <StyledTableCell>{row.match_data?.date ? format(new Date(row.match_data?.date), 'dd/MM/yyyy H:mm') : ''}</StyledTableCell>
      <StyledTableCell>{row.played}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_like_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_shared_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_download_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_download_you_count}</StyledTableCell>
      <StyledTableCell>{row.actions_videos_enjoy_count}</StyledTableCell>
      <StyledTableCell>{row.shares_direct_monetizables_count}</StyledTableCell>
      <StyledTableCell>{row.shares_users_count}</StyledTableCell>
      <StyledTableCell>{row.status === 'release' ? 'Narrado' : 'No narrado'}</StyledTableCell>


    </StyledTableRow>
  );
}

export default VideosViews;
