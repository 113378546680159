import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Reports = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.commentator.status,
    error: state.commentator.error,
    videos: state.commentator.list,
    sportPlaces: state.sportPlace.list,
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
    sport_place_id: '',
  });
  const [videos, setVideos] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }

 

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'commentator/excelReport', payload: { values, enqueueSnackbar } });
  }


  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'commentator/report', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() =>  {
    if (videoState.videos) {
      if (videoState.videos.data.length) {
        let all = [];
        let item;
       

        

        videoState.videos.data.forEach((user) => {
          item = user

          all.push(item);
        });
        setVideos(all);
      } else {
        setVideos([]);
      }
    }
  // eslint-disable-next-line
  }, [videoState.videos])



  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Reporte de Narradores
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

      </div>

   

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={videoState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell >Id</StyledTableCell>
                <StyledTableCell >Nombre</StyledTableCell>
                <StyledTableCell >Apellido</StyledTableCell>
                <StyledTableCell >Email</StyledTableCell>

                <StyledTableCell >Videos Narrados Español</StyledTableCell>
                <StyledTableCell >Videos Narrados Ingles</StyledTableCell>
                <StyledTableCell >Videos rechazados Español</StyledTableCell>
                <StyledTableCell >Videos rechazados Ingles</StyledTableCell>
                <StyledTableCell >Videos Totales</StyledTableCell>
                
              </TableRow>
              
            </TableHead>
            <TableBody>
              {videos.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={videoState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
    
  return (
    <StyledTableRow key={row.id}>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.profile?.name}</StyledTableCell>
      <StyledTableCell>{row.profile?.lastname}</StyledTableCell>
      <StyledTableCell>{row.email}</StyledTableCell>
      <StyledTableCell>{row.sends_spanish}</StyledTableCell>
      <StyledTableCell>{row.sends_english}</StyledTableCell>
      <StyledTableCell>{row.rejected_spanish}</StyledTableCell>
      <StyledTableCell>{row.rejected_english}</StyledTableCell>

      

      <StyledTableCell>{parseInt(row.sends_spanish) + parseInt(row.sends_english) - parseInt(row.rejected_spanish) - parseInt(row.rejected_english)}</StyledTableCell>


    </StyledTableRow>
  );
}

export default Reports;
