import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, RadioGroup,
  FormControlLabel, Radio, Button,Dialog,DialogContent,DialogTitle,
  DialogActions,TextField, FormControl,FormGroup, Checkbox
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';


const styleTable = {
    green:{
      color:'green'
    },
    gray:{
      color:'gray'
    },
    red:{
      color:'red'
    },
    yellow:{
      color:'#FBE384'
    }
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const OrdersList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userState = useSelector((state) => ({
    status: state.cameraman.status,
    error: state.cameraman.error,
    list: state.cameraman.listOrders,
    cameramen: state.cameraman.list,
  }));
  const dispatch = useDispatch();
  const [regType, setRegType] = useState('all');
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    status: 'all',
  });
  const [open, setOpen] = useState(false);
  const [sendOrder, setSendOrder] = useState(0);
  const [type, setType] = useState(1);
  const [disableButton, setDisableButton] = useState(true);
  const [sendCheck, setSendCheck] = useState(false);
  const [cameramanId, setCameramanId] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  

  const handleChangeType = (event) => {
    const val = event.target.value;
    setRegType(val);
    setPaginationParams((st) => ({...st, status: val}));
  };

  const handleMap = (lat,lng) => {

    return "https://www.google.com/maps/search/?api=1&query="+lat+","+lng;
  }

  const handleOpen = (id,type=1) => {
    setSendOrder(id);
    setType(type);
    setDisableButton(true);
    setSendCheck(false);

    setOpen(true)
  };

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'cameraman/excelReport', payload: { values, enqueueSnackbar } });
  }

  const handleDeleteClick = () =>{
    let values = {
      id: sendOrder,
      status:'rejected'
    }
    dispatch({ type: 'cameraman/acceptedOrRejected', payload: { values, enqueueSnackbar,handleClose,setPaginationParams } });

  }

  const handleAcceptedClick = () =>{
    let values = {
      id: sendOrder,
      status:'accepted',
      check:sendCheck,
      cameraman_id:cameramanId
    }
    
    dispatch({ type: 'cameraman/acceptedOrRejected', payload: { values, enqueueSnackbar,handleClose,setPaginationParams } });

  }

  const statusColor = (status) =>{
      let color = '';
      switch (status) {
        case 1:
          color =  styleTable.red
        break;

        case 2:
        case 5:
          color =  styleTable.gray
        break;

        case 3:
          color=  styleTable.yellow
        break;

        case 6:
          color=  styleTable.green
        break;

        default:
        break
      
       
      }
    return color;
  }

  const Row = ({ row }) => {
    
  
    return (
      <StyledTableRow key={row.id} >

        <StyledTableCell>
           
           {buttonAccepted(row.id,row.status.id)}
           {buttonRejected(row.id,row.status.id)}
        </StyledTableCell>
        <StyledTableCell style={statusColor(row.status.id)}>{row.status.status}</StyledTableCell>
        <StyledTableCell>{row.cameraman ? row.cameraman.name+" "+row.cameraman.lastname : ''}</StyledTableCell>
        <StyledTableCell>{row.user?.email}</StyledTableCell>
        <StyledTableCell>{row.user?.profile?.name}</StyledTableCell>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.address}</StyledTableCell>
        <StyledTableCell>{row.city}</StyledTableCell>
        <StyledTableCell>{row.state}</StyledTableCell>
        <StyledTableCell>{row.country}</StyledTableCell>

        <StyledTableCell>
          <a href={handleMap(row.lat,row.lng)} target='__blank' style={{ color:'#81A8E6',textDecoration:'underline' }}>Mapa</a>
        </StyledTableCell>
        <StyledTableCell>{row.order_conekta}</StyledTableCell>

        <StyledTableCell>{row.id}</StyledTableCell>

        
        
      </StyledTableRow>
    );
  }

  const buttonRejected = (id,status) =>{

    if(parseInt(status) !== 1){

        return '';
    }

    return (<Button onClick={() => handleOpen(id)} variant='contained' size="small" style={{ background:"red",color:'white' }}   >
                Rechazar
            </Button>)
  }

  const buttonAccepted = (id,status) =>{

    let selectType = 2;
    if(parseInt(status) === 3){
      selectType = 3;
    }

    if(parseInt(status) !== 1 && parseInt(status) !== 3 ){

        return '';
    }
    return ( 
        <Button variant='contained' onClick={() => handleOpen(id,selectType)}  type='button' className='m-1' size="small" color="primary">
            Aceptar
        </Button>
    )
  }

  

  const handleClose = () => {
    setOpen(false);
  }

  const handleSelect = (id) => {
    setDisableButton(false);
    setCameramanId(id);
  }


  const handleCheck = (id) => {

    if(disableButton){
      setDisableButton(false);

    }else{
      setDisableButton(true);
    }
    setSendCheck(true);
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'cameraman/orders', payload: { values, enqueueSnackbar } });
    dispatch({ type: 'cameraman/allCameramen', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  const DialogTitleCustom = () => {
        if(parseInt(type) === 1){
            return "¿Desea rechazar el siguiente registro?";
        }else{
            return "Asigna camarografo al partido";
        }
  }

    const DialogContentCustom = () => {
        let visible = true;

        if(parseInt(type) === 3){
          visible = false;
        }

        if(parseInt(type) === 1){
            return "";
        }else{
            return (
            <div>
                  <Autocomplete
                    id="combo-box-demo"
                    options={userState.cameramen?.data}
                    getOptionLabel={(option) => option.name+' '+option.lastname}
                    onChange={(e,v) => handleSelect(v.id)}
                    renderInput={(params) => <TextField {...params} label="Selecciona camarografo" variant="outlined" />}
                  />
                { visible ? 
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={() => handleCheck()} />}
                      label="Asignaré camarógrafo más tarde"
                    />
                    
                  </FormGroup>
                </FormControl> : ''}
            </div>

            )
        }
    }

    const DialogButtontCustom = () => {
        if(parseInt(type) === 1){
            return (<Button onClick={() => handleDeleteClick()} variant="outlined" color="primary"  autoFocus>
            Rechazar
          </Button>);
        }else{
            return (<Button onClick={() => handleAcceptedClick()} variant="outlined" disabled={disableButton} color="primary" autoFocus>
            Aceptar
          </Button>)
        }
    }

  return (
    <main>
      {userState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {DialogTitleCustom()}
          </DialogTitle>
          <DialogContent>
                { DialogContentCustom()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            {DialogButtontCustom()}
          </DialogActions>
    </Dialog>
     

      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Solicitud de camarografos
          </Typography>
        </div>
      </Paper>

      

      <div className='max-w-screen-xl pl-8 mx-auto text-white'>
        <RadioGroup aria-label="status" row name="gender1" value={regType} onChange={handleChangeType}>
          <FormControlLabel value="all" control={<Radio />} label="Todos" />
          <FormControlLabel value="1" control={<Radio />} label="Pendiente" />
          <FormControlLabel value="2" control={<Radio />} label="Rechazado" />
          <FormControlLabel value="3" control={<Radio />} label="Aceptado" />
          <FormControlLabel value="5" control={<Radio />} label="Rembolsado" />
          <FormControlLabel value="6" control={<Radio />} label="Camarografo Asignado" />

        </RadioGroup>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={userState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Acciones</StyledTableCell>
                <StyledTableCell>Estatus</StyledTableCell>
                <StyledTableCell>Camarografo</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Cancha</StyledTableCell>
                <StyledTableCell>Direccion</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>Pais</StyledTableCell>
                <StyledTableCell>Mapa</StyledTableCell>
                <StyledTableCell>Order Conekta</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {userState.list?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={userState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={userState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}



export default OrdersList;
