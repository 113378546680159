import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DevTool } from '@hookform/devtools';

const UpdateCameramanDialog = ({
    cameraman,
    onCancel,
    onEditSuccess,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { accounts } = useSelector((state) => state.catalog);
    const [isSaving, setIsSaving] = useState(false);

    const onSubmit = (values) => {
        setIsSaving(true);
        const formatedValues = {
            accounts: values.accounts.split(',')
        }

        dispatch({ type: 'assignCameraman/update', payload: { id: cameraman.id, values: formatedValues, callback: onEditSuccess, enqueueSnackbar } })
    }

    useEffect(() => {
        dispatch({ type: 'catalog/accounts', payload: { enqueueSnackbar } })
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog open={Boolean(cameraman)} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Editar Asignacion de Camarografo ({accounts?.length ? cameraman?.email : 'Cargando cuentas...'})
            </DialogTitle>
            <DialogContent>
                <DevTool control={control} />
                {accounts?.length ? <form id="assign-cameraman-form" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            defaultValue={cameraman?.accounts?.map((item) => item.id)}
                            type="text"
                            name="accounts"
                            className="hidden"
                            ref={register({ required: 'El campo es requerido' })}  />

                        <Autocomplete
                            defaultValue={accounts?.filter((account) => cameraman?.accounts?.map((item) => item.id).includes(account.id))}
                            options={accounts}
                            fullWidth
                            multiple
                            getOptionLabel={(option) => option.name}
                            onChange={(_, selectedAccounts) => setValue('accounts', selectedAccounts?.map((account) => account.id), { shouldValidate: true, shouldDirty: true })}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Seleccione una o varias cuentas"
                                    variant="outlined"
                                    error={!!errors.accounts}
                                    helperText={errors.accounts && errors.accounts.message} />
                            }
                        />
                </form> : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button type="submit" form="assign-cameraman-form" variant="outlined" color="primary" autoFocus disabled={isSaving}>
                    {isSaving ? 'Guadando...' : 'Guardar'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

UpdateCameramanDialog.propTypes = {
    cameraman: PropTypes.shape(),
}

UpdateCameramanDialog.defaultProps = {
    cameraman: null
}

export default UpdateCameramanDialog;