import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination,
  Button, Select, InputLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const VideosByMatch = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    matches: state.video.matches,
    sportPlaces: state.sportPlace.list,
    accountCameramen: state.catalog.accountCameramen
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
    sport_place_id: '',
    cameraman_id: ''
  });

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleFromDateChange = (date) => {
    if (isValid(date)) {
      setSelectedFromDate(date);
      setPaginationParams((st) => ({...st, date_start: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleToDateChange = (date) => {
    if (isValid(date)) {
      setSelectedToDate(date);
      setPaginationParams((st) => ({...st, date_end: format(date, 'yyyy-MM-dd')}));
    }
  }

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/excelMatches', payload: { values, enqueueSnackbar } });
  }

  const handleSportPlaceChange = (event) => {
    setPaginationParams((st) => ({...st, sport_place_id: event.target.value}));
    searchCameramen(event.target.value)
  }

  const handleCameramanChange = (event) => {
    setPaginationParams((st) => ({...st, cameraman_id: event.target.value}));
  }

  const searchCameramen = (id) => {
    console.log(id);
    dispatch({ type: 'catalog/accountCameramen', payload: { accountId:id ,enqueueSnackbar } });
  }

  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/matches', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Videos por Partido
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <InputLabel htmlFor='sportPlace'>Cuentas</InputLabel>
          <Select 
            label='Cuentas' 
            id='sportPlace' 
            native 
            name='sportPlace'
            onChange={handleSportPlaceChange}>
            <option aria-label='Todos' value='' />
            {videoState.sportPlaces?.data.map((item) => (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <InputLabel htmlFor='sportPlace'>Camarografos</InputLabel>
          <Select 
            label='Cuentas' 
            id='cameraman' 
            native 
            name='cameraman'
            onChange={handleCameramanChange}>
            <option aria-label='Seleccione opcion' value='' />
            {videoState.accountCameramen?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.profile?.name}
              </option>
            ))}
          </Select>
        </Paper>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        

        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={videoState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell colSpan={2}></StyledTableCell>
                <StyledTableCell colSpan={2}>Team A</StyledTableCell>
                <StyledTableCell colSpan={2}>Team B</StyledTableCell>
                <StyledTableCell colSpan={2}>Torneo</StyledTableCell>
                <StyledTableCell colSpan={5}>Totales</StyledTableCell>
                <StyledTableCell>Usuario(s) que subió</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Fecha</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Videos</StyledTableCell>
                <StyledTableCell>Vistas</StyledTableCell>
                <StyledTableCell>Vistas usuarios de la cuenta</StyledTableCell>
                <StyledTableCell>Vistas usuarios fuera de la cuenta</StyledTableCell>
                <StyledTableCell>Vistas anónimas</StyledTableCell>
                <StyledTableCell>
                  <StyledTableRow>
                    <StyledTableCell>Nombre</StyledTableCell>
                    <StyledTableCell># Videos</StyledTableCell>
                  </StyledTableRow>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {videoState.matches?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  rowsPerPageOptions={[50]}
                  count={videoState.matches?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.matches?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <>
    <StyledTableRow>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.date ? format(new Date(row.date), 'dd/MM/yyyy H:mm') : ''}</StyledTableCell>
      <StyledTableCell>{row.team_a?.id}</StyledTableCell>
      <StyledTableCell>{row.team_a?.name}</StyledTableCell>
      <StyledTableCell>{row.team_b?.id}</StyledTableCell>
      <StyledTableCell>{row.team_b?.name}</StyledTableCell>
      <StyledTableCell>{row.tournament?.id}</StyledTableCell>
      <StyledTableCell>{row.tournament?.name}</StyledTableCell>
      <StyledTableCell>{row.videos}</StyledTableCell>
      <StyledTableCell>{row.total_views}</StyledTableCell>
      <StyledTableCell>{row.sport_places}</StyledTableCell>
      <StyledTableCell>{row.no_sport_places}</StyledTableCell>
      <StyledTableCell>{row.anonymous}</StyledTableCell>
      <StyledTableCell>
        {row.user_upload?.map((user) => 
          <TableRow sx={{ height: '100%' }}>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.videos}</TableCell>
          </TableRow>
        )}
      </StyledTableCell>
    </StyledTableRow>
    </>
  );
}

export default VideosByMatch;
