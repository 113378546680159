import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { add } from 'date-fns';
import SearchBar from '../SearchBar/SearchBar';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ListDashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    videos: state.video.views,
    sportPlaces: state.sportPlace.list,
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    date_start: add(new Date(), {months: -1}),
    date_end: new Date(),
    sport_place_id: '',
    type_user: 'all',
  });

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };




  useEffect(() => {
    dispatch({ type: 'sportPlace/list', payload: { enqueueSnackbar } });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'video/dashboardPending', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  return (
    <main>
      {videoState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Dashboard Videos Pendientes
          </Typography>
        </div>
      </Paper>

    

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        
        <div className='max-w-screen-xl flex space-x-4'>
          <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
        </div>
      </div>


      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Id rastreo</StyledTableCell>
                <StyledTableCell>Id Partido</StyledTableCell>
                <StyledTableCell>Equipo A</StyledTableCell>
                <StyledTableCell>Equipo B</StyledTableCell>
                <StyledTableCell>Estatus</StyledTableCell>
                <StyledTableCell>Total Videos narrados</StyledTableCell>
                <StyledTableCell>Videos Pendientes por narrar</StyledTableCell>
                <StyledTableCell>Idiomas pendientes</StyledTableCell>
          
              </TableRow>
            </TableHead>
            <TableBody>
              {videoState.videos?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={videoState.videos?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={videoState.videos?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>
        {row.id}
      </StyledTableCell>
      <StyledTableCell>{row.match_id}</StyledTableCell>
      <StyledTableCell>{row.match_data?.team_a_v2?.name}</StyledTableCell>
      <StyledTableCell>{row.match_data?.team_b_v2?.name}</StyledTableCell>
      <StyledTableCell>{row.status_real}</StyledTableCell>
      <StyledTableCell>{row.total_narration}</StyledTableCell>
      <StyledTableCell>{row.total_pending}</StyledTableCell>
      <StyledTableCell>{row.languages}</StyledTableCell>

    </StyledTableRow>
  );
}

export default ListDashboard;
