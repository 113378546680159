import ax from '../../axios';

const sportPlace = {
  state: {
    list: null,
    tournaments: null,
    error: null,
    status: '',
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Active tournaments
    activeTournaments: (state) => ({ ...state, status: 'waiting', error: null }),
    tournamentsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      tournaments: res,
    }),
    tournamentsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    // Assign cameraman
    assignCameraman: (state) => ({ ...state, status: 'waiting', error: null }),
    assignCameramanSuccess: (state, res) => ({
      ...state,
      status: 'assigned',
      message: res.message,
    }),
    assignCameramanFailed: (state, error) => ({ ...state, status: 'failed', error }),
  },
  effects: (dispatch) => ({
    list(payload) {
      const { enqueueSnackbar } = payload;

      ax.get('/reports/catalogs/sport-places').then((res) => {
        dispatch.sportPlace.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    activeTournaments(payload) {
      const { sportPlaceId, enqueueSnackbar } = payload;

      if (sportPlaceId === '') {
        dispatch.sportPlace.tournamentsSuccess([]);
        return;
      }

      ax.get(`/reports/catalogs/tournaments/sport-place/${sportPlaceId}`).then((res) => {
        dispatch.sportPlace.tournamentsSuccess(res.data);
        enqueueSnackbar('Camarógrafo asignado', { variant: 'success' });
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.tournamentsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    activeTournaments2(payload) {
      const { sportPlaceId, enqueueSnackbar } = payload;

      
      ax.get(`/reports/catalogs/tournaments/sport-place/${sportPlaceId}`).then((res) => {
        dispatch.sportPlace.tournamentsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.tournamentsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    assignCameraman(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.post('/reports/insert-cameraman', values).then((res) => {
        dispatch.sportPlace.assignCameramanSuccess(res.data);
        enqueueSnackbar('Camarógrafo asignado', { variant: 'success' });
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.sportPlace.assignCameramanFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
  }),
};

export default sportPlace;
