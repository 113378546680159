import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';

const audition = {
  state: {
    list: null,
    error: null,
    status: '',
  },
  reducers: {
    // Pending auditions
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    excelReport: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
  },
  effects: (dispatch) => ({
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/auditions/lists', values).then((res) => {
        dispatch.audition.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.audition.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelReport(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/auditions', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.audition.excelSuccess(url);
        downloadExcel(url, 'auditions.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.audition.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
  }),
};

export default audition;
