import React, { useState, useEffect } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress, IconButton
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { DialogsConfirm } from '..';
import {
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';

function Form() {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        formState,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.catalog);

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }
    
    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate('/cameraman');
    }

    const onSubmit = (values) => {
        setIsSaving(true);
        dispatch({ type: 'newcameraman/create', payload: { values, enqueueSnackbar ,setIsSaving} });
    }

    useEffect(() => {
        if (users?.length) {
            setFirstLoadDone(true);
        }
    }, [users])

    useEffect(() => {
        dispatch({ type: 'catalog/users', payload: { enqueueSnackbar } })
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(formState.status === 'waiting' || !firstLoadDone || isSaving) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <div className="flex items-center gap-2">
                <IconButton aria-label="delete" onClick={() => navigate('/cameraman')}>
                    <ArrowBackIcon />
                </IconButton>

                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                    Nuevo Camarografo
                </Typography>
            </div>

            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={'¿Deseas cancelar y volver a la lista?'}
                confirmText={'Aceptar'}
                cancelText={'Cancelar'} />
            
            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                        <TextField
                            label='Nombre'
                            name='name'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                            }}
                            error={!!errors.name}
                            helperText={errors.name && errors.name.message}
                        />
                        
                        <TextField
                            label='Email'
                            name='email'
                            type="email"
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                            }}
                            error={!!errors.email}
                            helperText={errors.email && errors.email.message}
                        />

                        <TextField
                            label='Contraseña'
                            name='password'
                            variant='outlined'
                            type="password"
                            fullWidth
                            inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                            }}
                            error={!!errors.password}
                            helperText={errors.password && errors.password.message}
                        />


                        
                    </div>
                </Paper>
                <footer className='p-8 mt-8 text-right'>
                    <Button 
                        type='button' 
                        className='mr-8'
                        onClick={() => setCancelDialogIsOpen(true)} >
                        Cancelar
                    </Button>

                    <Button type='submit' variant='contained' color='primary' disabled={!firstLoadDone || isSaving}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;