import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button,Dialog,DialogActions,DialogContent, DialogContentText, DialogTitle,
  Radio,FormControlLabel,RadioGroup,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const BankTransfers = () => {
  const [open, setOpen] = useState(false);

  const userTransfersState = useSelector((state) => ({
    status: state.userTransfer.status,
    error: state.userTransfer.error,
    users: state.userTransfer.list,

  }));
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [regType, setRegType] = useState('progress');

  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    type:'progress'

  });
  const [videos, setVideos] = useState([]);
  const [sendUser, setSendUser] = useState(0);

  const handleChangeType = (event) => {
    const val = event.target.value;
    setRegType(val);
    setPaginationParams((st) => ({...st, type: val}));
  };



  const handleAprovedOrRejectedClick = (status) =>{
    let values = {
      user: sendUser,
      status : status
    }
    dispatch({ type: 'userTransfer/updateStatusAccount', payload: { values, enqueueSnackbar,handleClose,setPaginationParams } });

  }

  

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };


  const handleExcelClick = () => {
    const values = {params: paginationParams}
    setOpen(false)

    dispatch({ type: 'userTransfer/excel', payload: { values, enqueueSnackbar } }).then(() => {
      setPaginationParams((st) => ({...st, page: 1}));
    });

    setPaginationParams((st) => ({...st, page: 1}));

  }

  const handleOpen = (id) => {
    setSendUser(id);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const buttonStatus = (status,id) => {


    if(status === 'progress'){
       
      return (<Button variant='contained' color='primary' onClick={() => handleOpen(id)} type='button'  >Validar transferencia</Button>)
    }

    return '';


  }

  const Row = ({row}) => {
    return (
      <StyledTableRow key={row.id}>
        <StyledTableCell>{row.user?.id}</StyledTableCell>
        <StyledTableCell>{row.user?.profile?.name}</StyledTableCell>
        <StyledTableCell>{row.user?.email}</StyledTableCell>
        <StyledTableCell>{row.bank}</StyledTableCell>
        <StyledTableCell>{row.name+' '+ row.first_lastname+ ' '+ row.second_lastname}</StyledTableCell>
        <StyledTableCell>{row.interbank_key}</StyledTableCell>
        <StyledTableCell>{TableStatus(row.status)}</StyledTableCell>
        <StyledTableCell>{row.total}</StyledTableCell>
        <StyledTableCell>{buttonStatus(row.status,row.id) }</StyledTableCell>
        
      </StyledTableRow>
    );
  }


  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'userTransfer/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  useEffect(() =>  {
    if (userTransfersState.users) {

      if (userTransfersState.users.data.length) {
        let all = [];
        let item;
        
       

        userTransfersState.users.data.forEach((user) => {
          item = user;
          all.push(item);
        });
        setVideos(all);
      } else {
        setVideos([]);
      }
    }
  // eslint-disable-next-line
  }, [userTransfersState.users])



  return (
    <main>
      {userTransfersState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Transferencias
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl pl-8 mx-auto text-white'>
        <RadioGroup aria-label="types" row name="gender1" value={regType} onChange={handleChangeType}>
          <FormControlLabel value="all" control={<Radio />} label="Todos" />
          <FormControlLabel value="progress" control={<Radio />} label="Pendiente de Transferir" />
          <FormControlLabel value="transferred" control={<Radio />} label="Transferido" />
          <FormControlLabel value="rejected" control={<Radio />} label="Rechazado" />
        </RadioGroup>
      </div>


      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={userTransfersState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell >Id</StyledTableCell>
                <StyledTableCell >Nombre</StyledTableCell>
                <StyledTableCell >Correo</StyledTableCell>
                <StyledTableCell >Banco</StyledTableCell>
                <StyledTableCell >Titular cuenta bancaria</StyledTableCell>
                <StyledTableCell >Clabe</StyledTableCell>
                <StyledTableCell >Estatus</StyledTableCell>
                <StyledTableCell >Total</StyledTableCell>
                <StyledTableCell >Acciones</StyledTableCell>

              </TableRow>
            
            </TableHead>
            <TableBody>
              {videos.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={4}
                  rowsPerPageOptions={[50]}
                  count={userTransfersState.users?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={userTransfersState.users?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Estatus de cuenta bancaria"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              ¿Desea Cambiar el estatus de la transferencia?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => handleAprovedOrRejectedClick('rejected')} variant="outlined" color="secondary">Rechazar</Button>
            <Button onClick={() => handleAprovedOrRejectedClick('transferred')} variant="outlined" color="primary" autoFocus>
              Transferido
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  )
}

const TableStatus = (status) => {
    let statusSpanish = 'Pendiente';
    switch (status) {
      case 'transferred':
        statusSpanish = 'Transferido';
        
      break;
    
      case 'rejected':
        statusSpanish = 'Rechazada';
        
      break;

      default:
      break;
    }

    return statusSpanish;

}





export default BankTransfers;
