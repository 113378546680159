import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import SearchBar from '../SearchBar/SearchBar';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TeamsSportPlaceCounts = () => {
  const { enqueueSnackbar } = useSnackbar();
  const teamState = useSelector((state) => ({
    status: state.team.status,
    error: state.team.error,
    teams: state.team.list,
    excel: state.team.excelUrl,
  }));
  const dispatch = useDispatch();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    search: '',
  });

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'team/excel', payload: { values, enqueueSnackbar } });
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'team/withSportPlace', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


  return (
    <main>
      {teamState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Equipos con Cuenta y conteo de follower
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={teamState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={2}>Cuenta</StyledTableCell>
                <StyledTableCell colSpan={2}>Equipo</StyledTableCell>
                <StyledTableCell colSpan={2}>Torneo</StyledTableCell>
                <StyledTableCell rowSpan={2}>Followers</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamState.teams?.data?.map((row, i) => <Row key={i} row={row} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={teamState.teams?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={teamState.teams?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row}) => {
  return (
    <StyledTableRow>
      <StyledTableCell>{row.account.id}</StyledTableCell>
      <StyledTableCell>{row.account.name}</StyledTableCell>
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.tournament.id}</StyledTableCell>
      <StyledTableCell>{row.tournament.name}</StyledTableCell>
      <StyledTableCell align='right'>{row.follower}</StyledTableCell>
    </StyledTableRow>
  );
}

export default TeamsSportPlaceCounts;
