import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
// import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import PeopleIcon from '@material-ui/icons/People';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import {
    LocationOn,
    ContactMail,
    VideoCall,
    LocalAtm,
} from '@material-ui/icons';
import logo from '../assets/img/logo.png';

const AppDrawer = (props) => {
  const { open, toggleDrawer } = props;

  function handleUrlChage(url) {
    navigate(url);
    toggleDrawer();
  }

  return (
    <Drawer anchor='left' open={open} onClose={toggleDrawer}>
      <div className='w-64' role='presentation'>
        <header className='px-4 py-6'>
          <img className='w-20' src={logo} alt='PITZ' />
        </header>
        <Divider className='mb-4' />

        <List>
        <ListItem>
            <ListItemText className='opacity-75' primary='Sistema Pitz' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/locations')}>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>

            <ListItemText primary='Locaciones' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/accounts')}>
            <ListItemIcon>
              <ContactMail />
            </ListItemIcon>

            <ListItemText primary='Cuentas' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/sponsors')}>
            <ListItemIcon>
              <LocalAtm />
            </ListItemIcon>

            <ListItemText primary='Patrocinadores' />
          </ListItem>

          

          <ListItem button onClick={() => handleUrlChage('/orders')}>
            <ListItemIcon>
              <VideoCall />
            </ListItemIcon>

            <ListItemText primary='Ordenes de compra' />
          </ListItem>


          <ListItem button onClick={() => handleUrlChage('/update-video')}>
            <ListItemIcon>
              <VideoCall />
            </ListItemIcon>

            <ListItemText primary='Subir animaciones videos' />
          </ListItem>

          <ListItem>
            <ListItemText className='opacity-75' primary='Usuarios' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/users')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Usuarios' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/users-sport-place')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Usuarios con Cuentas' />
          </ListItem>

          
          <ListItem button onClick={() => handleUrlChage('/users-views')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Vistas por Usuario' />
          </ListItem>

          <Divider className='mb-4' />

          <ListItem>
            <ListItemText className='opacity-75' primary='Videos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/videos/views')}>
            <ListItemIcon>
              <OndemandVideoIcon />
            </ListItemIcon>

            <ListItemText primary='Vistas por video' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/videos/pending-narrations')}>
            <ListItemIcon>
              <PauseCircleFilledIcon />
            </ListItemIcon>

            <ListItemText primary='Videos pendientes de narración' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/videos/to-assign')}>
            <ListItemIcon>
              <OndemandVideoIcon />
            </ListItemIcon>

            <ListItemText primary='Asignar video' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/videos/to-assign-animation')}>
            <ListItemIcon>
              <OndemandVideoIcon />
            </ListItemIcon>

            <ListItemText primary='Asignar video animados' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/videos/by-match')}>
            <ListItemIcon>
              <PlaylistPlayIcon />
            </ListItemIcon>

            <ListItemText primary='Videos por partido' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/videos/filmed')}>
            <ListItemIcon>
              <OndemandVideoIcon />
            </ListItemIcon>

            <ListItemText primary='Videos Filmados' />
          </ListItem>

          <Divider className='mb-4' />

          

          <ListItem>
            <ListItemText className='opacity-75' primary='Equipos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/teams/with-sport-place')}>
            <ListItemIcon>
              <GroupWorkIcon />
            </ListItemIcon>

            <ListItemText primary='Equipos con cuentas y conteo de follower' />
          </ListItem>



          <ListItem button onClick={() => handleUrlChage('/teams/activity')}>
            <ListItemIcon>
              <GroupWorkIcon />
            </ListItemIcon>

            <ListItemText primary='Actividad de equipo' />
          </ListItem>

          <Divider className='mb-4' />

          <ListItem>
            <ListItemText className='opacity-75' primary='Narradores' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/auditions')}>
            <ListItemIcon>
              <AllInboxIcon />
            </ListItemIcon>

            <ListItemText primary='Audiciones' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/commentators/no-professionals')}>
            <ListItemIcon>
              <RecordVoiceOverIcon />
            </ListItemIcon>

            <ListItemText primary='Narradores' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/commentators/professionals')}>
            <ListItemIcon>
              <RecordVoiceOverIcon />
            </ListItemIcon>

            <ListItemText primary='Narradores Famosos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/commentators/report')}>
            <ListItemIcon>
              <RecordVoiceOverIcon />
            </ListItemIcon>

            <ListItemText primary='Reporte de Narradores' />
          </ListItem>


          <ListItem>
            <ListItemText className='opacity-75' primary='Camarografos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/cameraman')}>
            <ListItemIcon>
              <VideoCall />
            </ListItemIcon>

            <ListItemText primary='Camarografos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/assign-cameraman')}>
            <ListItemIcon>
              <VideoCall />
            </ListItemIcon>

            <ListItemText primary='Asignacion de camarografos' />
          </ListItem>

          

          <ListItem button onClick={() => handleUrlChage('/cameramen/lists')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Camarografo' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/cameramen/orders')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Solicitud Camarografo' />
          </ListItem>

          <ListItem>
            <ListItemText className='opacity-75' primary='QA' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/matches-banner')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Asignacion de banner por partido' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/matches-views')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Vistas por partido' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/qa')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Videos Narrados' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/qa/not-narration')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Videos No Narrados' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/qa/hidden-videos')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Liberar videos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/qa/videos-delete')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Videos borrados' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/qa/dashboard-pending')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Dashboard Videos Pedientes' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/qa/list-matches')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Listado de partidos' />
          </ListItem>

          <ListItem>
            <ListItemText className='opacity-75' primary='Capacitacion' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/training/users')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Usuarios' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/training/videos')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Videos' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/training/report')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Reporte' />
          </ListItem>

          
          <ListItem>
            <ListItemText className='opacity-75' primary='Editores de video' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/edits/users')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Usuarios' />
          </ListItem>


          <ListItem button onClick={() => handleUrlChage('/edits/report')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Reporte' />
          </ListItem>


          <ListItem>
            <ListItemText className='opacity-75' primary='Extractores' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/extractors/users')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Usuarios' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/assign-extractor')}>
            <ListItemIcon>
              <VideoCall />
            </ListItemIcon>

            <ListItemText primary='Asignacion de extractores' />
          </ListItem>


          <ListItem button onClick={() => handleUrlChage('/extractors/report')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Reporte' />
          </ListItem>

          <ListItem button onClick={() => handleUrlChage('/extractors/asignation-watermark')}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>

            <ListItemText primary='Asignacion Marca Agua' />
          </ListItem>
          
        </List>
      </div>
    </Drawer>
  );
};

AppDrawer.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

AppDrawer.defaultProps = {
  open: false,
  toggleDrawer: () => null,
};

export default AppDrawer;
