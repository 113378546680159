import React, { useState, useEffect } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress, Breadcrumbs, IconButton
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useParams, Link } from '@reach/router';
import { DialogsConfirm } from '..';
import {
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';

function Form() {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { selectedCourt } = useSelector((state) => state.court);
    const { accountId, courtId } = useParams();
    const { selectedAccount: account, status } = useSelector((state) => state.account);

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }
    
    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate(`/accounts/${accountId}/courts`);
    }

    const onSubmit = (values) => {
        setIsSaving(true);
        const formattedValues = { ...values, v2_account_id: accountId };
        selectedCourt
            ? dispatch({ type: 'court/update', payload: { values: formattedValues, id: selectedCourt.id, enqueueSnackbar } })
            : dispatch({ type: 'court/create', payload: { values: formattedValues, enqueueSnackbar } });
    }

    useEffect(() => {
        if (selectedCourt) {
            setShowForm(true);
            setValue('name', selectedCourt.name);
        }
        // eslint-disable-next-line
    }, [selectedCourt])

    useEffect(() => {
        if (courtId) {
            dispatch({ type: 'court/get', payload: { id: courtId, enqueueSnackbar } });
        } else {
            setShowForm(true);
        }
        // eslint-disable-next-line
    }, [courtId])

    useEffect(() => {
        if (accountId) {
            dispatch({ type: 'account/get', payload: { id: accountId, enqueueSnackbar } });
        }
        // eslint-disable-next-line
    }, [accountId])

    useEffect(() => {
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(status === 'waiting' || isSaving) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            {account && <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                <Link color="inherit" to="/accounts">
                    Cuentas ({account?.account?.name})
                </Link>
                <Link color="inherit" to={`/accounts/${accountId}/courts`}>
                    Canchas
                </Link>
                <Typography color="textPrimary">
                    {selectedCourt ? 'Editar' : 'Nueva'} cancha
                </Typography>
            </Breadcrumbs>}
            
            <div className="flex items-center gap-2">
                <IconButton aria-label="delete" onClick={() => navigate(`/accounts/${accountId}/courts`)}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                    {selectedCourt ? 'Editar' : 'Nueva'} Cancha
                </Typography>
            </div>


            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={'¿Deseas cancelar y volver a la lista?'}
                confirmText={'Aceptar'}
                cancelText={'Cancelar'} />
            
            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    {showForm && <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                            <TextField
                                defaultValue={selectedCourt?.name}
                                label='Nombre'
                                name='name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.name}
                                helperText={errors.name && errors.name.message}
                            />
                        </div>
                    </Paper>}
                </fieldset>
                <footer className='p-8 mt-8 text-right'>
                    <Button 
                        type='button' 
                        className='mr-8'
                        onClick={() => setCancelDialogIsOpen(true)} >
                        Cancelar
                    </Button>

                    <Button type='submit' variant='contained' color='primary' disabled={isSaving || status === 'waiting'}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;