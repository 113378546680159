import axios from '../../axios';

const updateVideo = {
    state: {
        error: null,
        status: '',
        message: '',
        selectedSponsor: null,
    },
    reducers: {
        
        update: (state) => ({ ...state, status: 'waiting', error: null }),
        updateSuccess: (state, res) => ({
            ...state,
            status: 'received',
            message: res.message,
        }),
        updateFailed: (state, error) => ({ ...state, status: 'failed', error }),
        
    },
    effects: (dispatch) => ({
      
        update(payload) {
            const { id, values, enqueueSnackbar,setIsSaving } = payload;
            console.log(id);
      
            axios.post(`/v2/update-videos/animations/${id}`, values).then((res) => {
                enqueueSnackbar('Video Editado Correctamente', { variant: 'success' });
                dispatch.updateVideo.updateSuccess(res);

                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.updateVideo.updateFailed(error.response);
                setIsSaving(false);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
    })
}

export default updateVideo;