import React, { useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText,
  DialogActions, Button, TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

const UsersCoinsDialog = ({
  user, open, handleClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm({ submitFocusError: true });
  const dispatch = useDispatch();
  const userState = useSelector((st) => ({ status: st.user.status, error: st.user.error }));

  const onSubmitHandler = (values) => {
    dispatch({ type: 'user/addCoins', payload: { values, enqueueSnackbar } });
  }

  useEffect(() => {
    if (userState.status === 'received') {
      handleClose();
    }
  // eslint-disable-next-line
  }, [userState.status]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogTitle id='form-dialog-title'>Dar monedas a usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Agrega monedas al usuario: {user?.email}
          </DialogContentText>
          <input
            ref={register}
            name='user_id'
            type='hidden'
            defaultValue={user?.id}
          />
          <TextField
            label='Monedas'
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.videos}
            name='videos'
            helperText={errors.videos && errors.videos.message}
            inputProps={{
              ref: register({
                required: 'El campo es requerido.',
                min: 1,
                max: 100,
              }),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancelar
          </Button>
          <Button
            color='primary'
            disabled={userState.status === 'waiting'}
            type='submit'
          >
            Agregar Monedas
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

UsersCoinsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.objectOf(PropTypes.any),
  handleClose: PropTypes.func.isRequired,
};

export default UsersCoinsDialog;
