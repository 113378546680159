import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Fab,Button,
  Radio,RadioGroup,FormControlLabel,Dialog,DialogContent,
  DialogTitle,DialogContentText,DialogActions
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  Create as CreateIcon
} from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const EditsUserList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const commentatorState = useSelector((state) => ({
    status: state.animation.status,
    error: state.animation.error,
    list: state.animation.list
  }));
  const dispatch = useDispatch();
  const [regType, setRegType] = useState('1');
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    type: '1',

  }); 

  const [userIdDelete, setUserIdDelete] = useState(null);
  const [userIdDeleteActive, setUserIdDeleteActive] = useState(1);
  const [isDeleiting, setIsDeleiting] = useState(false);


  const handleDeleteClick = (id,active) => {
    setUserIdDelete(id);
    setUserIdDeleteActive(active)
  }

  const confirmDelete = () => {
    setIsDeleiting(true);
    if(userIdDeleteActive){
      dispatch({ type: 'animation/delete', payload: {
          id: userIdDelete,
          enqueueSnackbar,
          handleClose: () => {
              setUserIdDelete(null)
              setUserIdDeleteActive(1)
              setIsDeleiting(false);
          },
          setPaginationParams
      }});
    }else{
      dispatch({ type: 'animation/active', payload: {
          id: userIdDelete,
          enqueueSnackbar,
          handleClose: () => {
              setUserIdDelete(null)
              setUserIdDeleteActive(1)
              setIsDeleiting(false);
          },
          setPaginationParams
      }});
    }
}

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };


  const handleChangeType = (event) => {
    const val = event.target.value;
    setRegType(val);
    setPaginationParams((st) => ({...st, type: val}));
  };

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'animation/user', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);


  return (
    <main>
      {commentatorState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

      <Dialog open={Boolean(userIdDelete)} onClose={() => setUserIdDelete(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
              {userIdDeleteActive ? 'Inactivar' : 'Activar'}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {userIdDeleteActive ? '¿Desea inactivar el siguiente registro?' : '¿Desea activar el siguiente registro?'}

                 
              </DialogContentText>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => setUserIdDelete(null)}>No</Button>
              <Button onClick={() => confirmDelete()} variant="outlined" color="primary" autoFocus disabled={isDeleiting}>
                  {isDeleiting ? 'Procesando...' : 'Si'}
              </Button>
          </DialogActions>
      </Dialog>

      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Usuarios Editores
          </Typography>

          <Fab
            className='absolute right-0 outline-none'
            color='primary'
            aria-label='add'
            onClick={() => navigate('/animation/user/new')}
          >
            <AddIcon />
          </Fab>
        </div>
      </Paper>

      <div className='max-w-screen-xl pl-8 mx-auto text-white'>
        <RadioGroup aria-label="types" row name="gender1" value={regType} onChange={handleChangeType}>
          <FormControlLabel value="all" control={<Radio />} label="Todos" />
          <FormControlLabel value="1" control={<Radio />} label="Activos" />
          <FormControlLabel value="0" control={<Radio />} label="Inactivos" />
        </RadioGroup>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Acciones</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commentatorState.list?.data?.map((row, i) => <Row key={i} row={row} handleDeleteClick={handleDeleteClick} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={commentatorState.list?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={commentatorState.list?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({row,handleDeleteClick}) => {
  return (
    <React.Fragment>
      <StyledTableRow>
      <StyledTableCell>{row.id}</StyledTableCell>
        <StyledTableCell>{row.name} </StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>
        { row.is_active ? <div>
            <Button variant='contained'  type='button' className='mr-1' onClick={() => navigate('/animation/user/edit/'+row.id)}  size="small" style={{ background:"#F9CA2D" }}>
                <CreateIcon className='text-white' />
            </Button>

            <Button onClick={() => handleDeleteClick(row.id,row.is_active)} variant='contained' size="small" style={{ background:"red" }}   >
                <DeleteIcon className="text-white"  />
            </Button>
        </div> : (
          <Button onClick={() => handleDeleteClick(row.id,row.is_active)} variant='contained' size="small" style={{ background:"#4caf50",color:"white" }}   >
                Activar
          </Button>
        )}
        
        </StyledTableCell>

        
      </StyledTableRow>
    </React.Fragment>
  );
}

export default EditsUserList;
