import React, { useState, useEffect } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress, IconButton
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { DialogsConfirm } from '..';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';

function Form() {
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { accounts,currency } = useSelector((state) => state.catalog);

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }
    
    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate('/orders');
    }

    const onChangeAccount = (_, account) => {
        setValue('v2_account_id', account?.id, { shouldValidate: true, shouldDirty: true })
        dispatch({ type: 'catalog/currency', payload: { accountId: account.id ,enqueueSnackbar } })

    }

    const onSubmit = (values) => {
        setIsSaving(true);
        dispatch({ type: 'order/create', payload: { values, enqueueSnackbar,setIsSaving } });
    }

    useEffect(() => {
        if (accounts?.length) {
            setFirstLoadDone(true);
        }
    }, [accounts])

    useEffect(() => {
        dispatch({ type: 'catalog/accounts', payload: { enqueueSnackbar } })
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(formState.status === 'waiting' || !firstLoadDone || isSaving) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <div className="flex items-center gap-2">
                <IconButton aria-label="delete" onClick={() => navigate('/orders')}>
                    <ArrowBackIcon />
                </IconButton>

                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                    Nueva orden de compra
                </Typography>
            </div>

            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={'¿Deseas cancelar y volver a la lista?'}
                confirmText={'Aceptar'}
                cancelText={'Cancelar'} />
            
            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        
                        <input
                            type="text"
                            name="v2_account_id"
                            className="hidden"

                            ref={register({ required: 'El campo es requerido' })}  />

                        <Autocomplete
                            options={accounts}
                            fullWidth
                            getOptionLabel={(option) => option.name}
                            onChange={onChangeAccount}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Selecciona cuenta"
                                    variant="outlined"
                                    error={!!errors.v2_account_id}
                                    helperText={errors.v2_account_id && errors.v2_account_id.message} />
                            }
                        />
        



                
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <TextField
                                label='# Partidos'
                                name='quantity'
                                type="number"
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.quantity}
                                helperText={errors.quantity && errors.quantity.message}  />

                        <TextField
                                label={currency ? 'Precio por partido en '+ currency.currency : 'Precio por partido en SIN MONEDA'}
                                name='price'
                                type="number"
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.price}
                                helperText={errors.price && errors.price.message}  />

                        <TextField
                                label='Agrega aquí cualquier monto adicional (inscripción, etc)'
                                name='additional'
                                type="number"
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register(),
                                }}
                         />

                        <TextField
                                label='Describe aqui el concepto del monto adicional'
                                name='description_additional'
                                type="text"
                                variant='outlined'
                                fullWidth
                                multiline
                                inputProps={{
                                    ref: register(),
                                }}
                         />


                    </div>

                </Paper>
                <footer className='p-8 mt-8 text-right'>
                    <Button 
                        type='button' 
                        className='mr-8'
                        onClick={() => setCancelDialogIsOpen(true)} >
                        Cancelar
                    </Button>

                    <Button type='submit' variant='contained' color='primary' disabled={!firstLoadDone || isSaving}>
                        {isSaving ? 'Guardando...' : 'Guardar y enviar orden de compra'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;