import React from 'react';
import { Controller } from 'react-hook-form';
import {
  TextField, MenuItem, ListItemText, Checkbox,
} from '@material-ui/core';


const SelectMultiple = (props) => {
  const { control, errors, items, name, label } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={{ 
        validate: {
          selectOne: (value) => value.length > 0 || 'El campo es requerido.'
        }
      }}
      render={({ onChange, value }) => {
        return (
          <TextField
            select
            variant="outlined"
            label={label}
            error={!!errors[name]}
            helperText={errors[name] && errors[name].message}
            SelectProps={{
              multiple: true,
              value: value,
              renderValue: (selected) => selected.map((s) => s.name).join(", "),
              onChange: onChange,
            }}
          >
            {items.map((n) => (
              <MenuItem key={n.id} value={n}>
                <Checkbox checked={value.includes(n)} />
                <ListItemText primary={n.name} />
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    />
  )
}

export default SelectMultiple;
