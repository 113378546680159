import React, { useEffect } from 'react';
import {
  Typography, Divider, Paper, Button,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { SponsorshipsGeneral, SponsorshipsTitle, SponsorshipsSections } from './FormSections';

const defaultValues = {
  usage_limit: 1500,
  sex: 'female',
  type: 'team',
  automatic_habilitation: true,
  enabled: true,
  date_from: new Date(),
  date_to: new Date(),
  image_es: null,
  image_en: null,
  title_es: 'title es',
  name_es: 'name es',
  description_es: 'description es',
  title_en: 'title en',
  name_en: 'name en',
  description_en: 'description en',
  sections: [
    {
      title_es: 'title es',
      title_en: 'title en',
      contents_es: [],
      contents_en: [],
    },
    {
      title_es: 'title es',
      title_en: 'title en',
      contents_es: [
        {
          lang: 'ES',
          type: 'media',
          text: '',
          media: 'http://via.placeholder.com/300',
        },
      ],
      contents_en: [
        {
          lang: 'EN',
          type: 'text',
          text: '<p>lorem <strong>ipsum</strong></p>',
          media: null,
        },
      ],
    },
  ],
};

const SponsorshipsForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    unregister,
    setValue,
    watch,
  } = useForm({
    submitFocusError: true,
    defaultValues,
  });

  const setFieldValue = (name, value) => {
    setValue(name, value);
  };

  const onSubmit = (data) => console.log('data', data);

  // register custom fields
  useEffect(() => {
    register({ name: 'image_es' });
    register({ name: 'image_en' });
    return () => {
      unregister({ name: 'image_es' });
      unregister({ name: 'image_en' });
    };
  }, [register, unregister]);

  return (
    <div className='max-w-screen-xl p-8 mx-auto'>
      <DevTool control={control} />
      <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
        Nuevo patrocinio
      </Typography>

      <form className='w-full' onSubmit={handleSubmit(onSubmit)}>
        <SponsorshipsGeneral register={register} control={control} errors={errors} />

        <fieldset className='mb-8'>
          <Typography className='mt-0 mb-2 text-white' variant='h6' component='h2'>
            Título
          </Typography>
          <Divider />

          <div className='flex mt-4'>
            <Paper className='w-full p-8 mr-8'>
              <SponsorshipsTitle
                register={register}
                errors={errors}
                setVal={setFieldValue}
                lang='es'
                title='Español'
                control={control}
              />
            </Paper>

            <Paper className='w-full p-8'>
              <SponsorshipsTitle
                register={register}
                errors={errors}
                setVal={setFieldValue}
                lang='en'
                title='Inglés'
                control={control}
              />
            </Paper>
          </div>
        </fieldset>

        <SponsorshipsSections
          {...{
            control,
            register,
            watch,
            setFieldValue,
          }}
        />

        <footer className='p-8 mt-8 text-right'>
          <Button type='button' className='mr-8'>
            Cancelar
          </Button>

          <Button type='submit' variant='contained' color='primary'>
            Guardar
          </Button>
        </footer>
      </form>
    </div>
  );
};

export default SponsorshipsForm;
