import ax from '../../axios';
import { navigate } from '@reach/router';
import downloadExcel from '../helpers/downloadExcel';

const commentator = {
  state: {
    list: null,
    catalogs: null,
    error: null,
    status: '',
    selectedCommentator:null,
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    catalogs: (state) => ({ ...state, status: 'waiting', error: null }),
    catalogsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      catalogs: res,
    }),
    catalogsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    create: (state) => ({ ...state, status: 'waiting', error: null }),
    createSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    createFailed: (state, error) => ({ ...state, status: 'failed', error }),
    report: (state) => ({ ...state, status: 'waiting', error: null }),
    excelReport: (state) => ({
      ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
      ...state,
      status: 'received',
      excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    get: (state) => ({
      ...state,
      status: 'waiting',
      error: null,
      selectedCommentator: null,
  }),
  resetSelectedCommentator: (state) => ({
    ...state,
    selectedCommentator: null
  }),
  getSuccess: (state, res) => ({
      ...state,
      status: 'received',
      selectedCommentator: res,
  }),
  getFailed: (state, error) => ({ ...state, status: 'failed', error }),
  update: (state) => ({ ...state, status: 'waiting', error: null }),
  updateSuccess: (state, res) => ({
    ...state,
    status: 'received',
    message: res.message,
    selectedTeam: null,
  }),

  },
  effects: (dispatch) => ({
    report(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/users-commentator/narrations', values).then((res) => {
        dispatch.commentator.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.commentator.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    excelReport(payload) {
      const { values, enqueueSnackbar } = payload;
      values.responseType = 'blob';

      ax.get('/reports/excel/users-commentator/narrations', values).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        dispatch.commentator.excelSuccess(url);
        downloadExcel(url, 'reporte.xlsx');
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.commentator.excelFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/comentators', values).then((res) => {
        dispatch.commentator.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.commentator.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    catalogs(payload) {
      const { enqueueSnackbar } = payload;

      ax.get('/comentators/catalogs').then((res) => {
        dispatch.commentator.catalogsSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.commentator.catalogsFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    create(payload) {
      const { values, enqueueSnackbar,lists } = payload;

      ax.post('/comentators', values).then((res) => {
        enqueueSnackbar('Narrador Creado', { variant: 'success' });
        dispatch.commentator.createSuccess(res);
        if(lists === 1){
          navigate('/commentators/no-professionals');

        }else{
          navigate('/commentators/professionals');

        }
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.commentator.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    get({ id, enqueueSnackbar }) {
      ax.get(`/comentators/show/${id}`).then((res) => {
          dispatch.commentator.getSuccess(res.data.data);
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.commentator.getFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
    update(payload) {
      const { values, id, enqueueSnackbar } = payload;


      ax.post(`/comentators/update/${id}`, values).then((res) => {
          enqueueSnackbar('Narrador Editado', { variant: 'success' });
          dispatch.commentator.updateSuccess(res);
          navigate('/commentators/no-professionals');

      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.commentator.failed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },
  }),
};

export default commentator;
