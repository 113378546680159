import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Dialog,DialogActions,DialogContent,
  DialogContentText, DialogTitle, Button, Fab
} from '@material-ui/core';
import {
    Create as CreateIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from '@material-ui/icons';
import UpdateCameramanDialog from './UpdateCameramanDialog';
import SearchBar from '../SearchBar/SearchBar';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row, onDeleteClick, onEditClick }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.id}</StyledTableCell>
            <StyledTableCell>{row.email}</StyledTableCell>
            <StyledTableCell>{row.profile?.name}</StyledTableCell>
            <StyledTableCell>{row.accounts_string}</StyledTableCell>
            <StyledTableCell>
            <Button variant='contained'  type='button' className='mr-1' onClick={() => onEditClick(row)}  size="small" style={{ background:"#F9CA2D" }}>
              <CreateIcon className='text-white' />
            </Button>
            <Button onClick={() => onDeleteClick(row.id)} variant='contained' size="small" style={{ background:"red" }}   >
                <DeleteIcon className="text-white"  />
            </Button>
          </StyledTableCell>
        </StyledTableRow>
    );
}

function CameramanList() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
        type:'cameraman'
    });
    const [cameramanIdToDelete, setCameramanIdToDelete] = useState(null);
    const [isDeleiting, setIsDeleiting] = useState(null);
    const [cameramanToEdit, setCameramanToEdit] = useState(null);
    const assignCameramanState = useSelector((state) => ({
        status: state.assignCameraman.status,
        error: state.assignCameraman.error,
        list: state.assignCameraman.list,
    }));

    const handleChangePage = (_, newPage) => {
        setPaginationParams((st) => ({...st, page: newPage+1}));
    };

    const handleDeleteClick = (id) => {
        setCameramanIdToDelete(id);
    }
    
    const confirmDelete = () => {
        setIsDeleiting(true);
        dispatch({ type: 'assignCameraman/delete', payload: {
            id: cameramanIdToDelete,
            enqueueSnackbar,
            handleClose: () => {
                setCameramanIdToDelete(null);
                setIsDeleiting(false);
            },
            setPaginationParams
        }});
    }

    const onEditClick = (cameraman) => {
        setCameramanToEdit(cameraman);
    }

    useEffect(() => {
        const values = {params: paginationParams}
        dispatch({ type: 'assignCameraman/list', payload: { values, enqueueSnackbar } });
        // eslint-disable-next-line
    }, [paginationParams]);

    return (
        <main>
            {assignCameramanState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <Dialog open={Boolean(cameramanIdToDelete)} onClose={() => setCameramanIdToDelete(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Eliminar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea eliminar el siguiente registro?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCameramanIdToDelete(null)}>Cancelar</Button>
                    <Button onClick={() => confirmDelete()} variant="outlined" color="primary" autoFocus disabled={isDeleiting}>
                        {isDeleiting ? 'Eliminando...' : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>

            {cameramanToEdit && <UpdateCameramanDialog
                cameraman={cameramanToEdit}
                onCancel={() => setCameramanToEdit(null)}
                onEditSuccess={() => {
                    setCameramanToEdit(null)
                    dispatch({ type: 'assignCameraman/list', payload: { values: { params: paginationParams }, enqueueSnackbar } });
                }} />}

            <Paper elevation={0}>
                <div className='relative max-w-screen-xl p-8 mx-auto'>
                    <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                        Asignaciones de Camarografos
                    </Typography>
                    
                </div>

                <div className='max-w-screen-xl p-8 mx-auto flex space-x-4 relative'>
                    <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />
                    <Fab
                        style={{transform: 'translateY(50%)'}}
                        className='absolute right-0 bottom-0 outline-none'
                        color='primary'
                        aria-label='add'
                        onClick={() => navigate('/assign-cameraman/new')}
                    >
                        <AddIcon />
                    </Fab>
                    
                </div>
                
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Cuentas</StyledTableCell>
                                
                                <StyledTableCell>Acciones</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assignCameramanState.list?.data?.map((row, i) => <Row key={i} row={row} onEditClick={onEditClick} onDeleteClick={handleDeleteClick} />)}
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[50]}
                                count={assignCameramanState.list?.meta?.total || 0}
                                page={paginationParams.page-1}
                                onChangePage={handleChangePage}
                                rowsPerPage={assignCameramanState.list?.meta?.per_page || 50}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </main>
    );
}

export default CameramanList;