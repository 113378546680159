import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { navigate, useParams, Link } from '@reach/router';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, Dialog,DialogActions,DialogContent,
  DialogContentText, DialogTitle, Button, Fab, IconButton, Breadcrumbs
} from '@material-ui/core';
import {
    Create as CreateIcon,
    Delete as DeleteIcon,
    Add as AddIcon,
    ArrowBack as ArrowBackIcon
} from '@material-ui/icons';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

function Row({ row, onDeleteClick, accountId, tournamentId }) {
    return (
        <StyledTableRow key={row.id}>
            <StyledTableCell>{row.id}</StyledTableCell>
            <StyledTableCell>{row.name}</StyledTableCell>
            <StyledTableCell>{row.ping}</StyledTableCell>
            <StyledTableCell>
            <Button
                variant='contained'
                type='button'
                className='mr-1'
                onClick={() => navigate(`/accounts/${accountId}/tournaments/${tournamentId}/teams/edit/${row.id}`)}
                size="small"
                style={{ background:"#F9CA2D" }}>
              <CreateIcon className='text-white' />
            </Button>
            <Button onClick={() => onDeleteClick(row.id)} variant='contained' size="small" style={{ background:"red" }}   >
                <DeleteIcon className="text-white"  />
            </Button>
          </StyledTableCell>
        </StyledTableRow>
    );
}

function Teams() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [paginationParams, setPaginationParams] = useState({
        page: 1,
        tournament_id: null,
    });
    const [teamIdToDelete, setTeamIdToDelete] = useState(null);
    const [isDeleiting, setIsDeleiting] = useState(false);
    const teamsState = useSelector((state) => ({
        status: state.team.status,
        error: state.team.error,
        teams: state.team.teamsList,
    }));
    const { accountId, tournamentId } = useParams();
    const account = useSelector((state) => state.account.selectedAccount);
    const tournament = useSelector((state) => state.tournament.selectedTournament);

    const handleChangePage = (_, newPage) => {
        setPaginationParams((st) => ({...st, page: newPage+1}));
    };

    const handleDeleteClick = (id) => {
        setTeamIdToDelete(id);
    }
    
    const confirmDelete = () => {
        setIsDeleiting(true);
        dispatch({ type: 'team/deleteTeam', payload: {
            id: teamIdToDelete,
            enqueueSnackbar,
            handleClose: () => {
                setTeamIdToDelete(null)
                setIsDeleiting(false);
            },
            setPaginationParams
        }});
    }

    useEffect(() => {
        if (paginationParams?.tournament_id) {
            const values = { params: paginationParams }
            dispatch({ type: 'team/teamsList', payload: { values, enqueueSnackbar } });
        }
        // eslint-disable-next-line
    }, [paginationParams]);

    useEffect(() => {
        if (accountId && tournamentId) {
            setPaginationParams((currentState) => ({
                ...currentState,
                tournament_id: tournamentId
            }))
            dispatch({ type: 'account/get', payload: { id: accountId }});
            dispatch({ type: 'tournament/get', payload: { id: tournamentId }});
        }
        // eslint-disable-next-line
    }, [accountId, tournamentId]);

    return (
        <main>
            {teamsState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <Dialog open={Boolean(teamIdToDelete)} onClose={() => setTeamIdToDelete(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Eliminar
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Desea eliminar el siguiente registro?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTeamIdToDelete(null)}>Cancelar</Button>
                    <Button onClick={() => confirmDelete()} variant="outlined" color="primary" autoFocus disabled={isDeleiting}>
                        {isDeleiting ? 'Eliminando...' : 'Eliminar'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Paper elevation={0}>
                <div className='relative max-w-screen-xl p-8 mx-auto'>

                    {account && tournament && <Breadcrumbs aria-label="breadcrumb" className="mb-2">
                        <Link color="inherit" to="/accounts">
                            Cuentas ({account?.account?.name})
                        </Link>
                        <Link color="inherit" to={`/accounts/${accountId}/tournaments`}>
                            Torneos ({tournament?.name})
                        </Link>
                        <Typography color="textPrimary">Equipos</Typography>
                    </Breadcrumbs>}

                    <div className="flex items-center gap-2">
                        <IconButton aria-label="delete" onClick={() => navigate(`/accounts/${accountId}/tournaments`)}>
                            <ArrowBackIcon />
                        </IconButton>

                        <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                            Equipos
                        </Typography>
                    </div>

                    <Fab
                        style={{transform: 'translateY(50%)'}}
                        className='absolute right-0 bottom-0 outline-none'
                        color='primary'
                        aria-label='add'
                        onClick={() => navigate(`/accounts/${accountId}/tournaments/${tournamentId}/teams/new`)}
                    >
                        <AddIcon />
                    </Fab>
                </div>                
            </Paper>

            <div className='max-w-screen-xl p-8 mx-auto'>
                <TableContainer component={Paper}>
                    <Table className='w-full'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Id</StyledTableCell>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell>Código de acceso</StyledTableCell>
                                <StyledTableCell>Acciones</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamsState.teams?.data?.map((row, i) => <Row key={i} row={row} onDeleteClick={handleDeleteClick} accountId={accountId} tournamentId={tournamentId} />)}
                        </TableBody>
                        <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={2}
                                rowsPerPageOptions={[50]}
                                count={teamsState.teams?.meta?.total || 0}
                                page={paginationParams.page-1}
                                onChangePage={handleChangePage}
                                rowsPerPage={teamsState.teams?.meta?.per_page || 50}
                            />
                        </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </main>
    );
}

export default Teams;