import React, { useEffect,useState} from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { RESOURCE_TYPES } from '../Sponsors/ExistentResSelector';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NewOrExistentResSwitch from '../UpdateVideo/NewOrExistentResSwitch';


function Form() {
    const [showForm] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [firstLoadDone] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState,
    } = useForm({
        submitFocusError: true,
    });
    const dispatch = useDispatch();
    const { selectedSponsor, status: sponsorRequestStatus } = useSelector((state) => state.sponsor);
    const { status } = useSelector((state) => state.account);
    const { sponsorAssets } = useSelector((state) => state.catalog);
    const [formFiles, setFormFiles] = useState({
        videoAdvertising: null,
    });

    const { videoLanguages } = useSelector((state) => state.catalog);


    const onSubmit = (values) => {
        setIsSaving(true);
        const formatedValues = {
            ...values,
        }
        const formData = getFormData(formatedValues);
        if (formFiles.videoAdvertising) formData.append(typeof formFiles.videoAdvertising === 'number' ? 'video_advertising_id' : 'video_advertising', formFiles.videoAdvertising);
         
        dispatch({ type: 'training/createVideo', payload: {setIsSaving:setIsSaving, values: formData, enqueueSnackbar } })
    }

    const getFormData = (data) => {
        const formData = new FormData();

        for(let [key, value] of Object.entries(data)) {
            if (Array.isArray(value)) {
                value.forEach((item, i) => {
                    formData.append(`${key}[${i}]`, item);
                })
            } else {
                formData.append(key, value);
            }
        }

        return formData;
    }

    useEffect(() => {
        dispatch({ type: 'catalog/videoLanguages', payload: { enqueueSnackbar } })
        // eslint-disable-next-line
      }, []);





    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(status === 'waiting' || !firstLoadDone || isSaving) && <LinearProgress className='absolute top-0 left-0 w-full' />}

            <div className="flex items-center gap-2">
                <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                     Nuevo Video para capacitacion
                </Typography>
            </div>



            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    {showForm && <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            
                            <TextField
                                label='Liga'
                                name='liga'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.liga}
                                helperText={errors.liga && errors.liga.message}
                            />

                            <TextField
                                label='Equipo A'
                                name='team_a_name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.team_a_name}
                                helperText={errors.team_a_name && errors.team_a_name.message}
                            />

                            <TextField
                                label='Equipo B'
                                name='team_b_name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.team_b_name}
                                helperText={errors.team_b_name && errors.team_b_name.message}
                            />


                            <TextField
                                label='Tipo de jugada'
                                name='type_play'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.type_play}
                                helperText={errors.type_play && errors.type_play.message}
                            />



                            <TextField
                                label='Jugada al equipo '
                                name='team_played'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.team_played}
                                helperText={errors.team_played && errors.team_played.message}
                            />


                            <TextField
                                label='Jugador '
                                name='player_name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.player_name}
                                helperText={errors.player_name && errors.player_name.message}
                            />


                            <TextField
                                label='Numero de Camiseta '
                                name='number'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.number}
                                helperText={errors.number && errors.number.message}
                            />


                            <input
                            className="hidden"
                            type="text"
                            name="videos"
                            ref={register({ required: 'El campo es requerido.' })} />

                            <Autocomplete
                                options={videoLanguages}
                                fullWidth
                            
                                //ref={tournamentsRef}
                                getOptionLabel={(option) => option.name}
                                onChange={(_, selectedTournaments) => setValue('videos', selectedTournaments?.id, { shouldValidate: true, shouldDirty: true })}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione idioma de del video"
                                        variant="outlined"
                                        error={!!errors.videos}
                                        helperText={errors.videos && errors.videos.message} />
                                }
                            />

                                <TextField
                                    label='Descripcion '
                                    name='description'
                                    variant='outlined'
                                    multiline
                                    rows={4}
                                    fullWidth
                                    inputProps={{
                                    ref: register(),
                                    }}
                                    error={!!errors.description}
                                    helperText={errors.description && errors.description.message}
                                />

                                <NewOrExistentResSwitch
                                    id="video-advertising"
                                    label="Video  Nuevo"
                                    accept="video/*"
                                    existentItems={sponsorAssets.advertisingVideo}
                                    error={!selectedSponsor && formState.isSubmitted && !formFiles.videoAdvertising ? 'El campo es requerido.' : ''}
                                    fileChange={(_, file) => setFormFiles((current) => ({...current, videoAdvertising: file})) }
                                    type={RESOURCE_TYPES.VIDEO}
                                />
                
                        </div>

            


            
                    </Paper>}
                </fieldset>
                <footer className='p-8 mt-8 text-right'>

                    <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={isSaving || sponsorRequestStatus === 'waiting'}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;