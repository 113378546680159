import axios from '../../axios';
import { navigate } from '@reach/router';

const order = {
  state: {
    list: null,
    error: null,
    status: '',
    message: ''
  },
  reducers: {
    list: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    create: (state) => ({ ...state, status: 'waiting', error: null }),
    createSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message
    }),
    createFailed: (state, error) => ({ ...state, status: 'failed', error }),

  },
  effects: (dispatch) => ({
    list(payload) {
      const { values, enqueueSnackbar } = payload;

      axios.get('/v2/orders', values)
        .then((res) => {
          dispatch.order.listSuccess(res.data);
        })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.order.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    create(payload) {
      const { values, enqueueSnackbar,setIsSaving } = payload;
      axios
        .post('/v2/orders', values)
        .then((res) => {
          enqueueSnackbar('Orden Creada', { variant: 'success' });
          dispatch.order.createSuccess(res);
          navigate('/orders');
        })
        .catch((error) => {
          setIsSaving(false);
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.order.createFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },
   
  })
};

export default order;
