import axios from '../../axios';
import { navigate } from '@reach/router';

const court = {
    state: {
        list: null,
        error: null,
        status: '',
        message: '',
        selectedCourt: null,
    },
    reducers: {
        list: (state) => ({
            ...state,
            list: null,
            status: 'waiting',
            error: null,
            selectedCourt: null,
        }),
        listSuccess: (state, res) => ({
            ...state,
            status: 'received',
            list: res,
        }),
        listFailed: (state, error) => ({
            ...state,
            status: 'failed',
            error,
            selectedCourt: null,
        }),
        create: (state) => ({ ...state, status: 'waiting', error: null }),
        createSuccess: (state, res) => ({
            ...state,
            status: 'received',
            message: res.message,
        }),
        createFailed: (state, error) => ({ ...state, status: 'failed', error }),
        update: (state) => ({ ...state, status: 'waiting', error: null }),
        updateSuccess: (state, res) => ({
            ...state,
            status: 'received',
            message: res.message,
        }),
        updateFailed: (state, error) => ({ ...state, status: 'failed', error }),
        get: (state) => ({
            ...state,
            status: 'waiting',
            error: null,
            selectedCourt: null,
        }),
        getSuccess: (state, res) => ({
            ...state,
            status: 'received',
            selectedCourt: res,
        }),
        getFailed: (state, error) => ({ ...state, status: 'failed', error }),
        delete: (state) => ({
            ...state,
            status: 'waiting',
            error: null,
            selectedCourt: null,
        }),
        deleteSuccess: (state, res) => ({
            ...state,
            status: 'received',
            selectedCourt: res,
        }),
        deleteFailed: (state, error) => ({ ...state, status: 'failed', error }),
    },
    effects: (dispatch) => ({
        list(payload) {
            const { values, enqueueSnackbar } = payload;
            axios.get('/v2/courts', values).then((res) => {
                dispatch.court.listSuccess(res.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.court.listFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        create(payload) {
            const { values, enqueueSnackbar } = payload;
      
            axios.post('/v2/courts', values).then((res) => {
                enqueueSnackbar('Cancha Creada', { variant: 'success' });
                dispatch.court.createSuccess(res);
                navigate('./');
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.court.createFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        update(payload) {
            const { values, id, enqueueSnackbar } = payload;
      
            axios.put(`/v2/courts/${id}`, values).then((res) => {
                enqueueSnackbar('Cancha Editada', { variant: 'success' });
                dispatch.court.updateSuccess(res);
                navigate('../');
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.court.updateFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        get({ id, enqueueSnackbar }) {
            axios.get(`/v2/courts/${id}`).then((res) => {
                dispatch.court.getSuccess(res.data.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.court.getFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        delete(payload) {
            const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      
            axios.delete(`/v2/courts/${id}`).then((res) => {
                dispatch.court.deleteSuccess(res.data);
                enqueueSnackbar('Cancha eliminada correctamente', { variant: 'success' });
                handleClose();
                setPaginationParams((st) => ({...st, page: 1}));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.court.deleteFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
    })
}

export default court;