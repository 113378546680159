import React, { useEffect,useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  FormControl,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { RESOURCE_TYPES } from '../Sponsors/ExistentResSelector';
import NewOrExistentResSwitch from '../UpdateVideo/NewOrExistentResSwitch';

const VideosAssignWatermark = ({ video, open, handleClose, setRefresh }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, formState } = useForm({ submitFocusError: true });
  const dispatch = useDispatch();
  const [formFiles, setFormFiles] = useState({
    videoAdvertising: null
  });
  const videoState = useSelector((state) => ({
    status: state.video.status,
    error: state.video.error,
    commentators: state.video.listAnimation
  }));

  const onSubmitHandler = (values) => {
    const formatedValues = {
        ...values
    }
    const formData = getFormData(formatedValues);
    if (formFiles.videoAdvertising) formData.append(typeof formFiles.videoAdvertising === 'number' ? 'video_advertising_id' : 'video_advertising', formFiles.videoAdvertising);

    dispatch({ type: 'video/createBanner', payload: { values:formData, enqueueSnackbar } });
  };

  const getFormData = (data) => {
    const formData = new FormData();

    for(let [key, value] of Object.entries(data)) {
        if (Array.isArray(value)) {
            value.forEach((item, i) => {
                formData.append(`${key}[${i}]`, item);
            })
        } else {
            formData.append(key, value);
        }
    }

    return formData;
}

  useEffect(() => {
    if (videoState.status === 'finish') {
      handleClose();
      setRefresh(true);
    }
    // eslint-disable-next-line
  }, [videoState.status]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogTitle id='form-dialog-title'>Asignar Banner</DialogTitle>
        <DialogContent>
          <DialogContentText>Partido Id: {video?.id}</DialogContentText>
          <input ref={register} name='video_id' type='hidden' defaultValue={video?.id} />
          <FormControl error={errors.commentator_id}>
            <TextField
              label='Link *'
              name='link'
              variant='outlined'
              style={{ marginBottom: "10px" }}
              fullWidth
              inputProps={{
                ref: register({
                  required: 'El campo es requerido.',
                  pattern: {
                    // eslint-disable-next-line
                    value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                    message: 'Ingresa una url válida'
                  }
                })
              }}
              error={!!errors.link}
              helperText={errors.link && errors.link.message}
            />

            <NewOrExistentResSwitch
              id='video-advertising'
              label='Asignar Imagen  (2272 × 757)'
              error={
                formState.isSubmitted && !formFiles.videoAdvertising ? 'El campo es requerido.' : ''
              }
              fileChange={(_, file) =>
                setFormFiles((current) => ({ ...current, videoAdvertising: file }))
              }
              type={RESOURCE_TYPES.IMAGE}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancelar
          </Button>
          <Button color='primary' disabled={videoState.status === 'waiting'} type='submit'>
            Asignar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

VideosAssignWatermark.propTypes = {
  open: PropTypes.bool.isRequired,
  video: PropTypes.objectOf(PropTypes.any),
  handleClose: PropTypes.func.isRequired
};

export default VideosAssignWatermark;
