/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import { Router, Redirect } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  SignIn, Error, Layout, UsersList, ZeroCoins,
  VideosPendingNarrations, VideosByWeek,
  TeamsSportPlaceCounts, VideosViews,
  AuditionsList, VideosToAssign,
  SportPlacesAssignCameraMan,
  CommentatorsList, SponsorshipsForm,
  CommentatorsForm, UsersListWithSportPlace,
  VideosByMatch, UsersViews,Wallet,VideosByWeekAprovedNarration,Activity,VideosFilmed,Reports,BankAccounts,BankTransfers,
  CameramanList,CameramanForm,CameramanFormEdit,OrdersList, LocationsList, LocationsForm, AccountsList, AccountsForm, CourtsList,
  CourtsForm, TournamentsList, TournamentsForm, TeamsList, TeamsForm, SponsorsList, SponsorsForm, AssignCameramanList, AssignCameramanForm, NewCameramanList,
   NewCameramanForm, OrderList,OrderForm, SurveyList,UpdateVideo,NarratorList,NarratorForm, QAList,QAListNotNarration,HiddenVideosList,ListUserTraining,
   FormUserTraining,ListVideoTraining,FormVideoTraining,FormVideoEditTraining,ListReportTraining,ListInvalidVideo,EditsUserList,UserFormNewAnimation,
   UserFormEditAnimation,ReportAnimation,MatchesViewsList,VideosToAssignAnimation,ListDashboard,NewMatchesList,ExtratorsUserList,UserFormNewExtractor,UserFormEditExtractor,
   AsignationExtractor,FormAsignationExtractor,ReportExtractor,ReporteAsignationWatermark,ReporteAsignationBanner
} from './components';

const App = () => {
  const ls = localStorage.getItem('session');
  const session = useMemo(() => JSON.parse(ls), [ls]);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const mainRoutes = (
    <>
      <Redirect from='/' to='accounts' noThrow />
      <Layout path='/'>
        <UsersList path='users' />
        <UsersViews path='users-views' />
        <UsersListWithSportPlace path='users-sport-place' />
        <ZeroCoins path='zero_coins' />
        <VideosPendingNarrations path='videos/pending-narrations' />
        <VideosByWeek path='videos/by-week' />
        <TeamsSportPlaceCounts path='teams/with-sport-place' />
        <Wallet path='teams/wallet' />
        <VideosViews path='videos/views' />
        <AuditionsList path='auditions' />
        <VideosToAssign path='videos/to-assign' />
        <VideosToAssignAnimation path='videos/to-assign-animation' />

        <SportPlacesAssignCameraMan path='sport-places/assign-cameraman' />
        <CommentatorsList path='commentators/professionals' />
        <NarratorList path='commentators/no-professionals' />

        <CommentatorsForm path='commentators/new' />
        <SponsorshipsForm path='sponsorships/new' />
        <VideosByMatch path='videos/by-match' />
        <VideosByWeekAprovedNarration path='videos/by-week/aproved/narration' />
        <Activity path='teams/activity' />
        <VideosFilmed path='videos/filmed' />
        <Reports path='commentators/report' />

        <BankAccounts path='/users/bank-accounts'/>
        <BankTransfers path='/banks/transfers' />
        <BankTransfers path='/banks/transfers' />

        <CameramanList path='/cameramen/lists' />
        <CameramanForm path='cameramam/new' />
        <CameramanFormEdit path='/cameramam/edit/:id' />
        <OrdersList path="/cameramen/orders" />
        <LocationsList path="/locations" />
        <LocationsForm path="/locations/new" />
        <LocationsForm path="/locations/edit/:id" />

        <AccountsList path="/accounts" />
        <AccountsForm path="/accounts/new" />
        <AccountsForm path="/accounts/edit/:id" />

        <CourtsList path="/accounts/:accountId/courts" />
        <CourtsForm path="/accounts/:accountId/courts/new" />
        <CourtsForm path="/accounts/:accountId/courts/edit/:courtId" />
        
        <TournamentsList path="/accounts/:accountId/tournaments" />
        <TournamentsForm path="/accounts/:accountId/tournaments/new" />
        <TournamentsForm path="/accounts/:accountId/tournaments/edit/:tournamentId" />

        <TeamsList path="/accounts/:accountId/tournaments/:tournamentId/teams" />
        <TeamsForm path="/accounts/:accountId/tournaments/:tournamentId/teams/new" />
        <TeamsForm path="/accounts/:accountId/tournaments/:tournamentId/teams/edit/:teamId" />

        <SponsorsList path="/sponsors" />
        <SponsorsForm path="/sponsors/new" />
        <SponsorsForm path="/sponsors/edit/:sponsorId" />

        <AssignCameramanList path="/assign-cameraman" />
        <AssignCameramanForm path="/assign-cameraman/new" />

        <NewCameramanList path="/cameraman" />
        <NewCameramanForm path="/cameraman/new" />


        <OrderList path="/orders" />
        <OrderForm path="/orders/new" />
        <SurveyList path="surveys" />
        <UpdateVideo path="update-video" />

        <NarratorForm path='commentators/no-professional/new' />
        <NarratorForm path="/commentators/no-professional/edit/:id" />
        <QAList path="/qa" />
        <QAListNotNarration path="/qa/not-narration" />
        <HiddenVideosList path="/qa/hidden-videos" />
        <ListDashboard path="qa/dashboard-pending" />

        <ListUserTraining path="/training/users" />
        <FormUserTraining path="/training/users/new" />
        <ListVideoTraining  path="/training/videos"/>
        <FormVideoTraining  path="/training/videos/new" />

        <FormVideoEditTraining path="/training/video/edit/:videoId" />
        <ListReportTraining path="/training/report"/>
        <ListInvalidVideo path="qa/videos-delete"/>
        <EditsUserList path="edits/users"/>

        <UserFormNewAnimation path="/animation/user/new" />
        <UserFormEditAnimation path="/animation/user/edit/:id" />
        <ReportAnimation path="edits/report" />
        <MatchesViewsList path="/matches-views" />

        <NewMatchesList path="/qa/list-matches" />

        <ExtratorsUserList path="/extractors/users" />
        <UserFormNewExtractor path= "/extractors/user/new" />
        <UserFormEditExtractor path = "/extractors/user/edit/:id" />
        <AsignationExtractor path="/assign-extractor" />
        <FormAsignationExtractor path="/assign-extractor/new" />
        <ReportExtractor path="/extractors/report"/>
        <ReporteAsignationWatermark path="/extractors/asignation-watermark" />

        <ReporteAsignationBanner path="/matches-banner" />
        <Error default />
      </Layout>
    </>
  );

  React.useEffect(() => {
    if (session) {
      // recovered session, saved on redux store
      dispatch({ type: 'auth/recoverUserSession', payload: session });
    }
  }, [dispatch, session]);

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          {(session && session.token) || token
            ? mainRoutes
            : <SignIn path='/' />}

          <Error default />
        </Router>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  );
};

export default App;
