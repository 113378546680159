import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, LinearProgress, Table, TableHead,
  TableRow, TableCell, TableBody, TableContainer,
  withStyles, TableFooter, TablePagination, RadioGroup,
  FormControlLabel, Radio, Button,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { format, add, isValid } from 'date-fns';
import { PlayerPositionsEnum, GendersEnum } from '../../enums';
import SearchBar from '../SearchBar/SearchBar';
import { KeyboardDatePicker } from '@material-ui/pickers';
import UsersCoinsDialog from './CoinsDialog';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const UsersList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userState = useSelector((state) => ({
    status: state.user.status,
    error: state.user.error,
    users: state.user.list,
    excel: state.user.excelUrl,
  }));
  const dispatch = useDispatch();
  const [selectedFromDate, setSelectedFromDate] = useState(add(new Date(), {months: -1}));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [regType, setRegType] = useState('all');
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    search: '',
    type: 'all',
    date_start: add(new Date(), {months: -1}),
    date_end: new Date()
  });
  const [open, setOpen] = useState(false);
  const [coinsUser, setCoinsUser] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPaginationParams((st) => ({...st, page: newPage+1}));
  };

  const handleFromDateChange = (date) => {
    let dateFormatted = date;

    if (isValid(date)) {
      dateFormatted = format(date, 'yyyy-MM-dd');
    }

    setSelectedFromDate(dateFormatted);
    setPaginationParams((st) => ({...st, date_start: dateFormatted}));
  }

  const handleToDateChange = (date) => {
    let dateFormatted = date;

    if (isValid(date)) {
      dateFormatted = format(date, 'yyyy-MM-dd');
    }

    setSelectedToDate(dateFormatted);
    setPaginationParams((st) => ({...st, date_end: dateFormatted}));
  }

  const handleChangeType = (event) => {
    const val = event.target.value;
    setRegType(val);
    setPaginationParams((st) => ({...st, type: val}));
  };

  const handleExcelClick = () => {
    const values = {params: paginationParams}
    dispatch({ type: 'user/excel', payload: { values, enqueueSnackbar } });
  }

  const handleClose = () => {
    setOpen(false);
    setCoinsUser(null);
  }

  useEffect(() => {
    const values = {params: paginationParams}
    dispatch({ type: 'user/list', payload: { values, enqueueSnackbar } });
    // eslint-disable-next-line
  }, [paginationParams]);

  return (
    <main>
      {userState.status === 'waiting' && <LinearProgress className='absolute top-0 left-0 w-full' />}
      <UsersCoinsDialog
        open={open}
        handleClose={handleClose}
        user={coinsUser} />

      <Paper elevation={0}>
        <div className='relative max-w-screen-xl p-8 mx-auto'>
          <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
            Usuarios
          </Typography>
        </div>
      </Paper>

      <div className='max-w-screen-xl p-8 mx-auto flex space-x-4'>
        <SearchBar onSearch={(value) => { setPaginationParams((st) => ({...st, search: value})) }} />

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedFromDate}
            placeholder="Desde: dd/mm/aaaa"
            onChange={date => handleFromDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>

        <Paper className="mb-3 px-4 pt-2">
          <KeyboardDatePicker
            clearable
            value={selectedToDate}
            placeholder="Hasta: dd/mm/aaaa"
            onChange={date => handleToDateChange(date)}
            format="dd/MM/yyyy"
          />
        </Paper>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white'>
        <RadioGroup aria-label="types" row name="gender1" value={regType} onChange={handleChangeType}>
          <FormControlLabel value="all" control={<Radio />} label="Todos" />
          <FormControlLabel value="email" control={<Radio />} label="Email" />
          <FormControlLabel value="facebook" control={<Radio />} label="Facebook" />
          <FormControlLabel value="apple" control={<Radio />} label="Apple" />
        </RadioGroup>
      </div>

      <div className='max-w-screen-xl pl-8 mx-auto text-white mt-3'>
        <Button 
          variant='contained'
          color='primary'
          type='submit'
          disabled={userState.status === 'waiting'}
          onClick={handleExcelClick}>Exportar a Excel</Button>
      </div>

      <div className='max-w-screen-xl p-8 mx-auto'>
        <TableContainer component={Paper}>
          <Table className='w-full'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Id</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>Nombre</StyledTableCell>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>Teléfono</StyledTableCell>
                <StyledTableCell>Ciudad</StyledTableCell>
                <StyledTableCell>Fecha de creación</StyledTableCell>
                <StyledTableCell>Verificado</StyledTableCell>
                <StyledTableCell>Posición</StyledTableCell>
                <StyledTableCell>Fecha de nacimiento</StyledTableCell>
                <StyledTableCell>Género</StyledTableCell>
                <StyledTableCell>Facebook</StyledTableCell>
                <StyledTableCell>Apple</StyledTableCell>
                <StyledTableCell>Dispositivo</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userState.users?.data?.map((row, i) => <Row key={i} row={row} setOpen={setOpen} setCoinsUser={setCoinsUser} />)}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={2}
                  rowsPerPageOptions={[50]}
                  count={userState.users?.meta?.total || 0}
                  page={paginationParams.page-1}
                  onChangePage={handleChangePage}
                  rowsPerPage={userState.users?.meta?.per_page || 50}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </main>
  )
}

const Row = ({ row, setOpen, setCoinsUser }) => {
  

  return (
    <StyledTableRow key={row.id}>
      
      <StyledTableCell>{row.id}</StyledTableCell>
      <StyledTableCell>{row.email}</StyledTableCell>
      <StyledTableCell>{row.profile?.name}</StyledTableCell>
      <StyledTableCell>{row.profile?.username}</StyledTableCell>
      <StyledTableCell>{row.phone}</StyledTableCell>
      <StyledTableCell>{row.place}</StyledTableCell>
      <StyledTableCell>{format(new Date(row.created_at), 'dd/MM/yyyy')}</StyledTableCell>
      <StyledTableCell align='center'>{row.email_verification && <CheckCircle />}</StyledTableCell>
      <StyledTableCell>{PlayerPositionsEnum[row.profile?.playertype]}</StyledTableCell>
      <StyledTableCell>{row.profile?.birthdate?.split('-').reverse().join('/')}</StyledTableCell>
      <StyledTableCell>{GendersEnum[row.profile?.gender]}</StyledTableCell>
      <StyledTableCell align='center'>{row.fb_id && <CheckCircle />}</StyledTableCell>
      <StyledTableCell align='center'>{row.apple_id && <CheckCircle />}</StyledTableCell>
      <StyledTableCell>{row.devices}</StyledTableCell>
    </StyledTableRow>
  );
}

export default UsersList;
