import ax from '../../axios';
import downloadExcel from '../helpers/downloadExcel';
import { navigate } from '@reach/router';

const extractor = {
  state: {
    list: null,
    error: null,
    status: '',
    excelUrl:'',
    selectedUser:null,
  },
  reducers: {
    // Pending auditions
    user: (state) => ({ ...state, status: 'waiting', error: null }),
    listSuccess: (state, res) => ({
      ...state,
      status: 'received',
      list: res,
    }),
    listFailed: (state, error) => ({ ...state, status: 'failed', error }),
    excel: (state) => ({
        ...state, excelUrl: null, status: 'waiting', error: null,
    }),
    excelSuccess: (state, res) => ({
        ...state,
        status: 'received',
        excelUrl: res,
    }),
    excelFailed: (state, error) => ({ ...state, status: 'failed', error }),
    createUser: (state) => ({ ...state, status: 'waiting', error: null }),
    createUserSuccess: (state, res) => ({
      ...state,
      status: 'received',
      message: res.message,
    }),
    createUserFailed: (state, error) => ({ ...state, status: 'failed', error }),
    getUser: (state) => ({
        ...state,
        status: 'waiting',
        error: null,
        selectedUser: null,
    }),
    getUserSuccess: (state, res) => ({
        ...state,
        status: 'received',
        selectedUser: res,
    }),
    getUserFailed: (state, error) => ({ ...state, status: 'failed', error }),

    updateUser: (state) => ({ ...state, status: 'waiting', error: null }),
    updateUserSuccess: (state, res) => ({
        ...state,
        status: 'received',
        message: res.message,
    }),

    resetSelectedUser: (state) => ({
        ...state,
        selectedUser: null
      }),
    
    reportAnimation: (state) => ({ ...state, status: 'waiting', error: null }),
    delete: (state) => ({
      ...state,
      status: 'waiting',
      error: null,
      selectedUser: null,
    }),
    activeOrInactivateSuccess: (state, res) => ({
        ...state,
        status: 'received',
        selectedUser: res,
    }),
    activeOrInactivateFailed: (state, error) => ({ ...state, status: 'failed', error }),
  },
  effects: (dispatch) => ({

    
    user(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/v2/extrators/users', values).then((res) => {
        dispatch.extractor.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.extractor.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    report(payload) {
      const { values, enqueueSnackbar } = payload;

      ax.get('/reports/report-extractors', values).then((res) => {
        dispatch.extractor.listSuccess(res.data);
      })
        .catch((error) => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }
          dispatch.extractor.listFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    getUser({ id, enqueueSnackbar }) {
        ax.get(`/v2/extrators/users/${id}`).then((res) => {
            dispatch.extractor.getUserSuccess(res.data.data);
        })
        .catch((error) => {
            let msg = error.message;
            if (error.response) {
                msg = error.response.data.message;
            }
            dispatch.extractor.getUserFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
        });
    },
    createUser(payload) {
        const { values, enqueueSnackbar } = payload;
  
        ax.post('/v2/extrators/users', values).then((res) => {
          enqueueSnackbar('Extractor Creado', { variant: 'success' });
          dispatch.extractor.createUserSuccess(res);
          navigate('/extractors/users');
        })
          .catch((error) => {
            let msg = error.message;
            if (error.response) {
              msg = error.response.data.message;
            }
            dispatch.extractor.createUserFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
          });
    },

    updateUser(payload) {
        const { values, id, enqueueSnackbar } = payload;
  
  
        ax.post(`/v2/extrators/users/${id}`, values).then((res) => {
            enqueueSnackbar('Editor Editado', { variant: 'success' });
            dispatch.extractor.updateUserSuccess(res);
            navigate('/extractors/users');
  
        })
        .catch((error) => {
            let msg = error.message;
            if (error.response) {
                msg = error.response.data.message;
            }
            dispatch.extractor.getUserFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
        });
      },
    excel(payload) {
        const { values, enqueueSnackbar } = payload;
        values.responseType = 'blob';
  
        ax.get('/reports/excel/report-extractors', values).then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          dispatch.extractor.excelSuccess(url);
          downloadExcel(url, 'report_extractores.xlsx');
        })
          .catch((error) => {
            let msg = error.message;
            if (error.response) {
              msg = error.response.data.message;
            }
            dispatch.extractor.excelFailed(error.response);
            enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    delete(payload) {
      const { id, enqueueSnackbar, handleClose,setPaginationParams } = payload;

      ax.delete(`/v2/extrators/users/${id}`).then((res) => {
          dispatch.extractor.activeOrInactivateSuccess(res.data);
          enqueueSnackbar('Usuario inactivado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.extractor.activeOrInactivateFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },

    active(payload) {
      const { id, enqueueSnackbar, handleClose,setPaginationParams } = payload;

      ax.post(`/v2/extrators/users/activate/${id}`).then((res) => {
          dispatch.extractor.activeOrInactivateSuccess(res.data);
          enqueueSnackbar('Usuario activado correctamente', { variant: 'success' });
          handleClose();
          setPaginationParams((st) => ({...st, page: 1}));
      })
      .catch((error) => {
          let msg = error.message;
          if (error.response) {
              msg = error.response.data.message;
          }
          dispatch.extractor.activeOrInactivateFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
      });
    },


  }),
};

export default extractor;
