import React, { useState, useEffect, useRef } from 'react';
import {
    Typography, Paper, Button, TextField,
    LinearProgress
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useParams } from '@reach/router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DialogsConfirm, Map } from '..';

function preventDefaultOnHitEnter(e) {
    if (e.key === 'Enter') e.preventDefault();
}

function Form() {
    const { enqueueSnackbar } = useSnackbar();
    const {
        register,
        handleSubmit,
        errors,
        control,
        setValue,
        formState,
        reset,
    } = useForm({
        submitFocusError: true,
    });
    const [showForm, setShowForm] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [firstLoadDone, setFirstLoadDone] = useState(false);
    const dispatch = useDispatch();
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState(false);
    const { selectedLocation } = useSelector((state) => state.location);
    const { countries, states, cities } = useSelector((state) => state.catalog);
    const stateRef = useRef(null);
    const cityRef = useRef(null);
    const { id: locationId } = useParams();

    const onSubmit = (values) => {
        setIsSaving(true);
        selectedLocation
            ? dispatch({ type: 'location/update', payload: { values, id: selectedLocation.id, enqueueSnackbar } })
            : dispatch({ type: 'location/create', payload: { values, enqueueSnackbar } });
    }

    const handleCancel = () => {
        setCancelDialogIsOpen(false);
    }
    
    const handleConfirm = () => {
        setCancelDialogIsOpen(false);
        navigate('/locations');
    }

    const handleClickCancel = () => {
        setCancelDialogIsOpen(true);
    }

    const onMapMarkerChange = ({ lat, lng }) => {
        if (lat && lng) {
            setValue('lat', lat);
            setValue('lng', lng);
        }
    }

    const onCityChange = (_, city) => {
        setValue('city', city?.name);
    }

    const onStateChange = (_, state) => {
        cityRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        setValue('state', state?.name);
        setValue('city', '');
        if (state) {
            dispatch({ type: 'catalog/cities', payload: { stateId: state.id, enqueueSnackbar } });
        }
    }
    
    const onCountryChange = (_, country) => {
        stateRef.current.querySelector('button.MuiAutocomplete-clearIndicator').click();
        setValue('country', country?.name, { shouldValidate: true, shouldDirty: true })
        setValue('state', '');
        setValue('city', '');
        if (country) {
            dispatch({ type: 'catalog/states', payload: { countryId: country?.id, enqueueSnackbar } });
        }
    }

    useEffect(() => {
        if (selectedLocation && countries?.length) {
            setValue('name', selectedLocation.name);
            const selectedCountry = countries.find((country) => country.name === selectedLocation.country);
            if (selectedCountry) {
                dispatch({ type: 'catalog/states', payload: { countryId: selectedCountry.id, enqueueSnackbar } });
            } else {
                setShowForm(true);
                setFirstLoadDone(true);
            }
        }
        // eslint-disable-next-line
    }, [selectedLocation, countries])
    
    useEffect(() => {
        if (selectedLocation && states?.length) {
            const selectedState = states.find((state) => state.name === selectedLocation.state);
            if (selectedState) {
                dispatch({ type: 'catalog/cities', payload: { stateId: selectedState.id, enqueueSnackbar } });
            } else {
                setShowForm(true);
                setFirstLoadDone(true);
            }
        }
        // eslint-disable-next-line
    }, [selectedLocation, states])

    useEffect(() => {
        if (selectedLocation && cities?.length) {
            setShowForm(true);
            setFirstLoadDone(true);
        }
        // eslint-disable-next-line
    }, [selectedLocation, cities])

    useEffect(() => {
        dispatch({ type: 'catalog/countries', payload: { enqueueSnackbar } });
        if (locationId) {
            dispatch({ type: 'location/get', payload: { id: locationId, enqueueSnackbar } });
        } else {
            setFirstLoadDone(true);
            setShowForm(true);
        }
        // eslint-disable-next-line
    }, [locationId])

    useEffect(() => {
        return () => {
            reset();
        };
        // eslint-disable-next-line
    }, [])

    return (
        <div className='max-w-screen-xl p-8 mx-auto'>
            <DevTool control={control} />
            {(isSaving || formState.status === 'waiting' || !firstLoadDone) && <LinearProgress className='absolute top-0 left-0 w-full' />}
            <Typography className='m-0 text-white opacity-75' variant='h4' component='h1'>
                {locationId ? 'Editar' : 'Nueva'} Locación
            </Typography>

            <DialogsConfirm
                open={cancelDialogIsOpen}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                title={'Cancelar'}
                message={'¿Deseas cancelar y volver a la lista?'}
                confirmText={'Aceptar'}
                cancelText={'Cancelar'} />

            <form noValidate className='w-full' onSubmit={handleSubmit(onSubmit)} onKeyDown={preventDefaultOnHitEnter}>
                <fieldset>
                    {showForm && <Paper className='p-8 my-8 grid grid-cols-1 gap-6'>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <input
                                defaultValue={selectedLocation?.lat}
                                type='hidden'
                                name='lat'
                                ref={register({ required: true })}
                            />
                            
                            <input
                                defaultValue={selectedLocation?.lng}
                                type='hidden'
                                name='lng'
                                ref={register({ required: true })}
                            />

                            <input
                                defaultValue={selectedLocation?.country}
                                type='text'
                                name='country'
                                className="hidden"
                                ref={register({ required: 'El campo es requerido.' })}
                            />

                            <input
                                defaultValue={selectedLocation?.state}
                                type='text'
                                name='state'
                                className="hidden"
                                ref={register({ required: 'El campo es requerido.' })}
                            />

                            <input
                                defaultValue={selectedLocation?.city}
                                type='text'
                                name='city'
                                className="hidden"
                                ref={register({ required: 'El campo es requerido.' })}
                            />

                            <TextField
                                defaultValue={selectedLocation?.name}
                                label='Nombre'
                                name='name'
                                variant='outlined'
                                fullWidth
                                inputProps={{
                                    ref: register({ required: 'El campo es requerido.' }),
                                }}
                                error={!!errors.name}
                                helperText={errors.name && errors.name.message}
                            />

                            <Autocomplete
                                defaultValue={selectedLocation?.country && countries.find((country) => country.name === selectedLocation.country)}
                                options={countries}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onCountryChange}
                                clearOnBlur
                                autoComplete={false}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione un país"
                                        variant="outlined"
                                        error={!!errors.country}
                                        helperText={errors.country && errors.country.message} />
                                }
                            />

                            <Autocomplete
                                defaultValue={selectedLocation?.state && states.find((state) => state.name === selectedLocation.state)}
                                options={states}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onStateChange}
                                autoComplete={false}
                                clearOnBlur
                                ref={stateRef}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione un estado"
                                        variant="outlined"
                                        error={!!errors.state}
                                        helperText={errors.state && errors.state.message} />
                                }
                            />
                            
                            <Autocomplete
                                defaultValue={selectedLocation?.city && cities.find((city) => city.name === selectedLocation.city)}
                                options={cities}
                                fullWidth
                                getOptionLabel={(option) => option.name}
                                onChange={onCityChange}
                                clearOnBlur
                                ref={cityRef}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label="Seleccione una ciudad"
                                        variant="outlined"
                                        error={!!errors.city}
                                        helperText={errors.city && errors.city.message} />
                                }
                            />
                        </div>

                        <TextField
                            defaultValue={selectedLocation?.address}
                            label='Dirección'
                            name='address'
                            variant='outlined'
                            fullWidth
                            inputProps={{
                                ref: register({ required: 'El campo es requerido.' }),
                            }}
                            error={!!errors.address}
                            helperText={errors.address && errors.address.message}
                        />

                        <Map
                            defaultMarker={selectedLocation ? { lat: Number(selectedLocation.lat), lng: Number(selectedLocation.lng) } : null}
                            onMapMarkerChange={onMapMarkerChange}
                            label="Selecciona una ubicación (Haz doble click para colocar un marcador):"
                            error={(errors?.lat || errors.lng) && 'Debes seleccionar una ubicación'} />
                    </Paper>}
                </fieldset>
                <footer className='p-8 mt-8 text-right'>
                    <Button 
                        type='button' 
                        className='mr-8'
                        onClick={handleClickCancel} >
                        Cancelar
                    </Button>

                    <Button type='submit' variant='contained' color='primary' disabled={isSaving || formState.status === 'waiting'}>
                        {isSaving ? 'Guardando...' : 'Guardar'}
                    </Button>
                </footer>
            </form>
        </div>
    )
}

export default Form;