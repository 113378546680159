import axios from '../../axios';
import { navigate } from '@reach/router';

const newcameraman = {
    state: {
        list: null,
        error: null,
        status: '',
        message: '',
    },
    reducers: {
        list: (state) => ({
            ...state,
            list: null,
            status: 'waiting',
            error: null,
        }),
        listSuccess: (state, res) => ({
            ...state,
            status: 'received',
            list: res,
        }),
        listFailed: (state, error) => ({
            ...state,
            status: 'failed',
            error,
        }),
        create: (state) => ({ ...state, status: 'waiting', error: null }),
        createSuccess: (state, res) => ({
            ...state,
            status: 'received',
            message: res.message,
        }),
        createFailed: (state, error) => ({ ...state, status: 'failed', error }),
        delete: (state) => ({
            ...state,
            status: 'waiting',
            error: null,
        }),
        deleteSuccess: (state, res) => ({
            ...state,
            status: 'received',
        }),
        deleteFailed: (state, error) => ({ ...state, status: 'failed', error }),
    },
    effects: (dispatch) => ({
        list(payload) {
            const { values, enqueueSnackbar } = payload;
            axios.get('/v2/cameramen', values).then((res) => {
                dispatch.newcameraman.listSuccess(res.data);
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.newcameraman.listFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        create(payload) {
            const { values, enqueueSnackbar, setIsSaving } = payload;
      
            axios.post('/v2/cameramen', values).then((res) => {
                enqueueSnackbar('Camarografo Creado', { variant: 'success' });
                dispatch.newcameraman.createSuccess(res);
                navigate('/cameraman');
            })
            .catch((error) => {
                setIsSaving(false);
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.newcameraman.createFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
        delete(payload) {
            const { id, enqueueSnackbar, handleClose, setPaginationParams } = payload;
      
            axios.delete(`/v2/cameramen/${id}`).then((res) => {
                dispatch.newcameraman.deleteSuccess(res.data);
                enqueueSnackbar('Camarografo eliminado correctamente', { variant: 'success' });
                handleClose();
                setPaginationParams((st) => ({...st, page: 1}));
            })
            .catch((error) => {
                let msg = error.message;
                if (error.response) {
                    msg = error.response.data.message;
                }
                dispatch.newcameraman.deleteFailed(error.response);
                enqueueSnackbar(msg, { variant: 'error' });
            });
        },
    })
}

export default newcameraman;